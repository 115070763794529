import React from "react";
import {
  ChartBarIcon,
  CurrencyDollarIcon,
  EyeIcon,
} from "@heroicons/react/outline";
import { XCircleIcon } from "@heroicons/react/solid";
import { format } from "date-fns";
import uniq from "lodash/uniq";
import dynamic from "next/dynamic";

import { Data } from "plotly.js";
import { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import Modal from "components/core/Modal";
import SvgSpinner from "components/svg/Spinner";
import {
  useBillingPlan,
  useCurrentBillingPeriod,
  useLatestResourceUsages,
  useOrg,
  useResources,
  useResourceUsagesByDay,
} from "lib/api/hooks";
import { BillingPlan, Organization } from "lib/api/types";
import { Resource } from "lib/platforms";
import { numberOfDaysLeftInTrial } from "lib/utils/org";

const Plot = dynamic(() => import("react-plotly.js"), { ssr: false });

export const Usage = () => {
  const navigate = useNavigate();
  const [org] = useOrg();
  const [period] = useCurrentBillingPeriod();
  const [billingPlan] = useBillingPlan();

  const currentMAR = useMemo(() => {
    if (!org) {
      return "Loading...";
    }
    return org.usages?.monthlyActiveRows
      ? `${org.usages?.monthlyActiveRows} rows`
      : "Unused";
  }, [org]);

  const handleClose = () => navigate("/");

  return (
    <Modal
      onClose={handleClose}
      containerClassName="w-full custom-container py-32 lg:px-16 flex flex-row justify-center"
      cardClassName="transform bg-white rounded shadow-xl max-w-xl"
    >
      <div className="flex flex-col gap-8 p-8">
        <header className="flex justify-between">
          <Modal.Title className="text-2xl font-bold">Usage</Modal.Title>
          <button onClick={handleClose}>
            <XCircleIcon className="h-5 w-5" aria-hidden="true" />
            <span className="sr-only">Close Resource Usage</span>
          </button>
        </header>
        <section className="flex flex-col gap-2">
          <div className="flex items-center gap-1.5">
            <CurrencyDollarIcon className="h-6" />
            <h4 className="text-xl font-bold text-gray-900">Current plan</h4>
          </div>
          <BillingPlanDisplay billingPlan={billingPlan} org={org} />
        </section>
        <section className="flex flex-col gap-2">
          <div className="flex items-center gap-1.5">
            <EyeIcon className="h-6" />
            <h4 className="text-xl font-bold text-gray-900">Overview</h4>
          </div>
          <div className="flex gap-7">
            <div className="flex flex-col">
              <h4 className="text-md font-bold">Current MAR</h4>
              {org ? (
                <span>{currentMAR}</span>
              ) : (
                <SvgSpinner className="h-3 w-3 animate-spin" />
              )}
            </div>
            <div className="flex flex-col">
              <h4 className="text-md font-bold">Billing Period</h4>
              {period ? (
                <span>
                  {format(Date.parse(period.start), "MMM dd")} -{" "}
                  {format(Date.parse(period.end), "MMM dd")}
                </span>
              ) : (
                <SvgSpinner className="h-3 w-3 animate-spin" />
              )}
            </div>
          </div>
        </section>
        <section className="flex flex-col gap-2">
          <div className="flex items-center gap-1.5">
            <ChartBarIcon className="h-6" />
            <h4 className="text-xl font-bold text-gray-900">Sync usage</h4>
          </div>
          <p>
            This table shows the Monthly Active Rows (MAR) for each of your
            syncs in the current billing period:
          </p>
          <UsageList billingPlan={billingPlan} />
        </section>
      </div>
    </Modal>
  );
};

const BillingPlanDisplay = ({
  billingPlan,
  org,
}: {
  billingPlan: BillingPlan;
  org: Organization;
}) => {
  if (billingPlan === undefined) {
    return (
      <div className="flex-1 flex flex-col justify-center">
        <SvgSpinner className="h-3 w-3 animate-spin" />
      </div>
    );
  }

  if (billingPlan === null) {
    const planText =
      org.subscriptionStatus === "trial"
        ? `You have ${numberOfDaysLeftInTrial(org)} days left in your trial
                period.`
        : "No plan selected";
    return (
      <div className="flex-1 flex flex-col justify-center">
        <h4 className="text-md font-bold mb-1">{planText}</h4>
        <p className="text-xs">
          <a
            href="https://sequin.io/pricing"
            target="_blank"
            className="link"
            rel="noreferrer"
          >
            View our plans
          </a>
        </p>
      </div>
    );
  }

  let formattedMar = "...";

  if (billingPlan?.mar) {
    if (billingPlan.mar === -1) {
      formattedMar = "a custom";
    } else {
      formattedMar = new Intl.NumberFormat().format(billingPlan?.mar);
    }
  }

  return (
    <div className="flex-1 flex flex-col justify-center">
      <h4 className="text-md font-bold">
        <a
          className="flex gap-1 align-center max-w-fit link"
          href="https://www.sequin.io/pricing"
          target="_blank"
          rel="noreferrer"
        >
          {billingPlan?.name}
        </a>
      </h4>

      <p className="text-xs">
        {`Includes ${formattedMar} `}
        <a
          href="https://docs.sequin.io/pricing"
          target="_blank"
          className="link"
          rel="noreferrer"
        >
          MAR
        </a>
      </p>
    </div>
  );
};

const UsageList = ({ billingPlan }: { billingPlan: BillingPlan }) => {
  const [resources] = useResources();
  const [resourceUsages] = useLatestResourceUsages();

  if (!resources || !resourceUsages) {
    return (
      <div className="flex flex-row items-center justify-center">
        <SvgSpinner className="w-6- h-6 animate-spin" />
      </div>
    );
  }

  const filteredResources = resourceUsages.map((v) => ({
    resource: resources.find((x) => x.id === v.resourceId),
    usage: v,
  }));

  if (resourceUsages.length === 0) {
    return (
      <p className="p-4 bg-gray-200 rounded">
        Your billing period just started today, check back tomorrow for new
        usage.
      </p>
    );
  }

  return (
    <>
      <table className="w-full border-collapse rounded">
        <thead>
          <tr>
            <th className="border border-gray-200 text-gray-500 py-3 px-4 w-1/2">
              Resource
            </th>
            <th className="border border-gray-200 text-gray-500 py-3 px-4 w-1/2">
              Usage
            </th>
          </tr>
        </thead>
        <tbody>
          {filteredResources.map((res) => {
            return (
              <tr key={res.usage.resourceId}>
                <td className="border border-gray-200 text-black py-3 px-4 w-1/3">
                  {res.resource ? res.resource.name : "Deleted resource"}
                </td>
                <td className="border border-gray-200 text-black py-3 px-4 w-1/3">
                  {res.usage.monthlyActiveRows.toLocaleString()} rows
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      <UsageGraph resources={resources} billingPlan={billingPlan} />
    </>
  );
};

const UsageGraph = ({
  billingPlan,
  resources,
}: {
  billingPlan: BillingPlan;
  resources: Resource[];
}) => {
  const [usageByDay] = useResourceUsagesByDay();

  if (!usageByDay) {
    return (
      <div className="flex flex-row items-center justify-center">
        <SvgSpinner className="w-6- h-6 animate-spin" />
      </div>
    );
  }

  const days = Object.keys(usageByDay);
  const daysLabel = days.map((day) => format(new Date(day), "MMM dd"));
  const resourceIdsInUsage = uniq(
    days.flatMap((day) => usageByDay[day].map((r) => r.resourceId))
  );

  const data: Data[] = resourceIdsInUsage.map((resId) => {
    const y = days
      .map((day) => usageByDay[day].find((u) => u.resourceId === resId))
      .map((d) => (d ? d.monthlyActiveRows : null));
    const res = resources.find((r) => r.id === resId);

    return {
      type: "bar",
      x: daysLabel,
      y,
      name: res ? res.name : "Deleted resource",
    };
  });

  return (
    <>
      <h2 className="mt-4 text-base font-bold">Usage over time</h2>
      <p className="mt-2">
        This graph shows the MAR for all your syncs in the current billing
        period. The grey line indicates the MAR included in your plan. If you
        exceed the MAR included in your plan, you'll be billed based on our
        volume pricing.
      </p>
      <Plot
        data={[
          {
            x: daysLabel,
            y: billingPlan?.mar ? [billingPlan?.mar] : [],
            text: [`Included MAR - ${billingPlan?.name}`],
            mode: "text",
            textposition: "top right",
            textfont: {
              color: "rgb(80, 80, 80)",
            },
          },
          ...data,
        ]}
        layout={{
          width: 512,
          height: 400,
          showlegend: false,
          margin: {
            pad: 0,
            l: 32,
            r: 0,
            b: 52,
            t: 32,
          },
          xaxis: {
            type: "category",
          },
          barmode: "stack",
          colorway: [
            "rgba(0, 0, 0, 1)",
            "rgba(0, 0, 0, 0.9)",
            "rgba(0, 0, 0, 0.8)",
            "rgba(0, 0, 0, 0.7)",
            "rgba(0, 0, 0, 0.6)",
            "rgba(0, 0, 0, 0.5)",
            "rgba(0, 0, 0, 0.4)",
            "rgba(0, 0, 0, 0.3)",
          ],
          shapes: billingPlan?.mar
            ? [
                {
                  type: "line",
                  xref: "paper",
                  x0: 0,
                  y0: billingPlan?.mar,
                  x1: 1,
                  y1: billingPlan?.mar,
                  line: {
                    color: "rgb(80, 80, 80)",
                    width: 2,
                  },
                },
              ]
            : [],
        }}
        config={{
          displayModeBar: false,
          displaylogo: false,
        }}
      />
    </>
  );
};
