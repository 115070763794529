import Head from "next/head";
import React, { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import { confirmPasswordReset } from "../../lib/api";
import { useResetPasswordProfile } from "../../lib/api/hooks";
import { Button } from "../../lib/lucidez";
import { FormUtils } from "../../lib/utils";
import SvgSpinner from "../svg/Spinner";

const { bindEnterKeyPress } = FormUtils;

enum WorkingState {
  none,
  error,
  errorPasswordMatch,
  working,
  done,
}

export default function ConfirmReset() {
  const navigate = useNavigate();
  const [query] = useSearchParams();

  const token = query.get("token");

  const [workingState, setWorkingState] = useState(WorkingState.none);
  const working = workingState === WorkingState.working;

  const [password, setPassword] = useState("");
  const [passwordConfirmation, setPasswordConfirmation] = useState("");

  const [userResponse] = useResetPasswordProfile(token?.toString());
  const user = userResponse && userResponse.user;

  const handleReset = async () => {
    if (password !== passwordConfirmation) {
      toast.error("Passwords don't match", { toastId: "passwords-dont-match" });
      setWorkingState(WorkingState.errorPasswordMatch);
      return;
    }

    setWorkingState(WorkingState.working);

    const result = await confirmPasswordReset(token!.toString(), password);

    if (result.isOk()) {
      toast("Password changed successfully 🎉");
      setWorkingState(WorkingState.done);
      navigate("/login");
    } else {
      toast.error("Something went wrong", { toastId: "reset-something-wrong" });
      setWorkingState(WorkingState.error);
    }
  };

  return (
    <>
      <Head>
        <title>Reset Password - Sequin</title>
      </Head>
      <div>
        <h2 className="text-2xl font-bold">Reset Password</h2>
        <p className="mt-2 mb-4">Set your new password:</p>
        {!userResponse || userResponse.ok ? (
          !user ? (
            <SvgSpinner className="h-4 animate-spin" />
          ) : (
            <>
              <input
                placeholder="Email"
                className="auth-input disabled:opacity-50 disabled:cursor-not-allowed"
                disabled
                value={user.email}
              />
              <input
                placeholder="Password"
                className="auth-input mt-4"
                disabled={working}
                type="password"
                name="password"
                autoComplete="new-password"
                value={password}
                onChange={({ target: { value } }) => setPassword(value)}
                onKeyPress={bindEnterKeyPress(handleReset)}
              />
              <input
                placeholder="Confirm Password"
                className="auth-input mt-2"
                disabled={working}
                type="password"
                name="confirm-password"
                autoComplete="new-password"
                value={passwordConfirmation}
                onChange={({ target: { value } }) =>
                  setPasswordConfirmation(value)
                }
                onKeyPress={bindEnterKeyPress(handleReset)}
              />
              <Button
                variant="primary"
                size="xl"
                className="w-full mt-4 justify-center"
                isLoading={working}
                onClick={handleReset}
              >
                Continue
              </Button>
            </>
          )
        ) : (
          <div className="bg-red-500 w-full py-2 px-4 rounded text-white">
            A reset password link was sent to your email.
          </div>
        )}
      </div>
    </>
  );
}
