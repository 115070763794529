import React from "react";
import { Link } from "react-router-dom";
import { match } from "ts-pattern";
import { format } from "date-fns";

import {
  DatabaseIcon,
  LinkIcon,
  ExternalLinkIcon,
  CalendarIcon,
  PencilIcon,
  LightningBoltIcon,
} from "@heroicons/react/outline";
import { FriendlyDateTime } from "components/core/FriendlyDateTime";
import { SyncToggle } from "components/platforms/common/SyncToggle";
import { SyncPageResourceStatusBadge } from "components/platforms/common/card/ResourceStatusBadge";
import { EditableEnvironmentBadge } from "components/platforms/common/card/EnvironmentBadge";
import SequinIcon from "components/svg/SequinIcon";
import {
  useResourceBackfills,
  useResourceBySlug,
  useResourceStatuses,
} from "lib/api/hooks";
import { ResourceEnvironment, ResourceStatus } from "lib/api/types";
import { Resource, getPlatformForKind, SourceUrlHelper } from "lib/platforms";
import HoverPopover from "components/core/HoverPopover";
import MarkdownRender from "components/core/MarkdownRender";

// See Ghola.SyncHealth.Advisor
const syncLevelErrorMessages = [
  "Schema mismatch",
  "Migration error",
  "Unknown error",
];

interface SyncPageHeaderProps {
  permaslug: string;
}

export const SyncPageHeader = ({ permaslug }: SyncPageHeaderProps) => {
  const [resource, mutateResource] = useResourceBySlug(permaslug as string);

  const [statuses] = useResourceStatuses({
    refreshInterval: 5000,
  });
  const resourceStatus =
    resource && statuses?.find((status) => status.resourceId === resource.id);

  if (!resource || !resourceStatus) {
    return <SyncPageHeaderLoading />;
  }

  return (
    <SyncPageHeaderContent
      resource={resource}
      mutateResource={mutateResource}
      resourceStatus={resourceStatus}
    />
  );
};

interface SyncPageHeaderContentProps {
  resource: Resource;
  mutateResource: (resource: Resource) => any;
  resourceStatus: ResourceStatus;
}

const SyncPageHeaderContent = ({
  resource,
  mutateResource,
  resourceStatus,
}: SyncPageHeaderContentProps) => {
  const platform = getPlatformForKind(resource.kind);

  const isSequinHosted =
    resource.database.definition.type === "shared_postgres";

  const dbTypeLabel = match(resource.database.definition.type)
    .with("dedicated_postgres", () => "Self-hosted")
    .with("shared_postgres", () => "Sequin-hosted")
    .otherwise(() => "Unknown");

  const mutateActive = async (active: boolean) => {
    await mutateResource({ ...resource, active });
  };

  const mutateResourceEnvironment = async (
    resourceEnvironment: ResourceEnvironment
  ) => {
    await mutateResource({ ...resource, resourceEnvironment });
  };

  const [backfills] = useResourceBackfills(resource.id);
  const isBackfillRunning =
    backfills &&
    !!backfills.find((backfill) =>
      ["pending", "active"].includes(backfill.status)
    );
  const isFirstResourceSchema = resource.schema.idx === 1;

  const sourceHelper = platform.helpers.find(
    (helper) => helper.name === "SOURCE"
  );
  const sourceUrl =
    sourceHelper && (sourceHelper as SourceUrlHelper<any>).buildUrl(resource);

  return (
    <header className="bg-white pt-6 pb-8">
      <div className="custom-container">
        <div className="grid grid-rows-2 lg:grid-cols-11 lg:grid-rows-1">
          <section className="col-span-5 flex flex-col">
            <div className="text-xs font-bold">
              <Link
                to="/"
                className="text-cool-gray-400 cursor-pointer hover:text-black"
              >
                All syncs
              </Link>{" "}
              <span className="mx-1 text-cool-gray-400">—</span> {resource.name}
            </div>
            <div className="flex flex-row mt-6 items-center">
              <HoverPopover
                containerProps={{
                  placement: "top",
                }}
                content={resource.name}
              >
                <h2 className="text-[22px] font-bold md:max-w-[15rem] xl:max-w-xs w-fit h-full relative top-1.5 cursor-pointer whitespace-nowrap overflow-x-hidden overflow-ellipsis">
                  {resource.name}
                </h2>
              </HoverPopover>
              <EditableEnvironmentBadge
                className="ml-6"
                mutateResourceEnvironment={mutateResourceEnvironment}
                resource={resource}
                size="lg"
              />
            </div>
            <div className="mt-6 py-1 flex flex-row gap-5">
              <div className="flex items-center font-medium text-xxs text-cool-gray-500 gap-1.5">
                <div className="border h-5 w-5 flex flex-shrink-0 justify-center items-center rounded-full border-gray-200 hover:border-black dark:border-gray-600 dark:hover:border-white">
                  <platform.displayIcon className="h-2 text-cool-gray-700" />
                </div>
                {platform.displayName}
              </div>
              <div className="flex items-center font-medium text-xxs text-cool-gray-500 gap-1.5">
                <div className="border h-5 w-5 flex flex-shrink-0 justify-center items-center rounded-full border-gray-200">
                  {isSequinHosted ? (
                    <SequinIcon className="h-2 text-cool-gray-700" />
                  ) : (
                    <DatabaseIcon className="h-2 text-cool-gray-700" />
                  )}
                </div>
                {dbTypeLabel}
              </div>
              {sourceUrl && (
                <a
                  href={sourceUrl.url}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="flex items-center font-medium text-xxs text-cool-gray-500 gap-1.5"
                >
                  <div className="border h-5 w-5 flex flex-shrink-0 justify-center items-center rounded-full border-gray-200 hover:border-black dark:border-gray-600 dark:hover:border-white">
                    <LinkIcon className="h-2 text-cool-gray-700" />
                  </div>
                  {sourceUrl.displayUrl}
                </a>
              )}
            </div>
          </section>
          <section className="col-span-6 grid grid-cols-10">
            <section className="col-span-2 border-r pr-8">
              <h3 className="text-xxs font-bold text-cool-gray-500">Status</h3>
              <div className="mt-6 flex flex-col gap-3 items-start">
                <div className="flex px-3 py-2 border rounded-full">
                  <SyncToggle
                    resource={resource}
                    refreshResource={mutateActive}
                  />
                </div>
                <div className="flex px-3 py-2 border rounded-full">
                  <SyncPageResourceStatusBadge
                    isActive={resource.active}
                    isFirstBackfillRunning={
                      isBackfillRunning && isFirstResourceSchema
                    }
                    resourceStatus={resourceStatus}
                  />
                </div>
              </div>
            </section>
            <section className="col-span-4 border-r px-8">
              <h3 className="text-xxs font-bold text-cool-gray-500">
                Destination
              </h3>
              <div className="mt-6 flex flex-col items-start gap-2">
                <Link
                  to={`/databases/${resource.database.id}/edit`}
                  className="flex items-center px-3 py-2 font-bold font-mono text-[11px] leading-[14px] border rounded-full border-gray-200 hover:border-gray-500"
                >
                  <span className="text-light-blue-600">
                    {resource.database.name
                      ? resource.database.name
                      : "Demo Database"}
                  </span>
                  <ExternalLinkIcon className="h-3 ml-2 text-cool-gray-500" />
                </Link>
                <div className="flex items-center px-3 py-2 font-medium  text-xxs border rounded-full">
                  <span className="text-cool-gray-500">Database:</span>
                  <span className="text-accentContrast ml-1 font-bold">
                    {resource.database.definition.dbname}
                  </span>
                </div>
                <div className="flex items-center px-3 py-2 font-medium  text-xxs border rounded-full">
                  <span className="text-cool-gray-500">Schema:</span>
                  <span className="text-[#E1577E] ml-1 font-bold">
                    {resource.database.schema}
                  </span>
                </div>
              </div>
            </section>
            <section className="col-span-4 pl-8">
              <h3 className="text-xxs font-bold text-cool-gray-500">
                Activity
              </h3>
              <div className="mt-6 flex flex-col gap-2 items-start">
                <div className="flex items-center px-3 py-2 text-xxs border rounded-full text-cool-gray-500">
                  <CalendarIcon className="w-3 h-3 mr-2" /> Created{" "}
                  {format(Date.parse(resource.insertedAt), "PP")}
                </div>
                <div className="flex items-center px-3 py-2 text-xxs border rounded-full text-cool-gray-500">
                  <PencilIcon className="w-3 h-3 mr-2" /> Sync edited{" "}
                  {format(Date.parse(resource.updatedAt), "PP")}
                </div>
                {resourceStatus.lastActivityAt && (
                  <div className="flex items-center px-3 py-2 text-xxs border rounded-full text-cool-gray-500">
                    <LightningBoltIcon className="w-3 h-3 mr-2" /> Latest
                    activity{" "}
                    <span className="ml-0.5 w-[64px]">
                      <FriendlyDateTime
                        iso8601={resourceStatus.lastActivityAt}
                        mode="absolute"
                      />
                    </span>
                  </div>
                )}
              </div>
            </section>
          </section>
        </div>
        {syncLevelErrorMessages.includes(resourceStatus.message) && (
          <SyncLevelError resourceStatus={resourceStatus} />
        )}
      </div>
    </header>
  );
};

const SyncPageHeaderLoading = () => {
  return (
    <header className="bg-white pt-6 pb-8">
      <div className="custom-container">
        <div className="grid grid-cols-11">
          <section className="col-span-5 flex flex-col">
            <div className="w-32 h-5 bg-cool-gray-200 rounded animate-pulse" />
            <div className="flex flex-row w-full h-6 mt-6 items-center">
              <h2 className="w-48 h-6 bg-cool-gray-200 rounded animate-pulse" />
              <div className="ml-6 w-36 h-6 bg-cool-gray-200 rounded animate-pulse" />
            </div>
            <div className="mt-6 py-1 flex flex-row gap-5">
              <div className="bg-cool-gray-200 h-4 w-32 rounded animate-pulse" />
              <div className="bg-cool-gray-200 h-4 w-32 rounded animate-pulse" />
              <div className="bg-cool-gray-200 h-4 w-32 rounded animate-pulse" />
            </div>
          </section>
          <section className="col-span-6 grid grid-cols-10">
            <section className="col-span-2 border-r pr-8">
              <h3 className="text-xxs font-bold text-cool-gray-500">Status</h3>
              <div className="mt-6 flex flex-col gap-3 items-start">
                <div className="bg-cool-gray-200 h-7 w-16 rounded animate-pulse" />
                <div className="bg-cool-gray-200 h-6 w-20 rounded animate-pulse" />
              </div>
            </section>
            <section className="col-span-4 border-r px-8">
              <h3 className="text-xxs font-bold text-cool-gray-500">
                Destination
              </h3>
              <div className="mt-6 flex flex-col items-start gap-2">
                <div className="bg-cool-gray-200 h-8 w-40 rounded animate-pulse" />
                <div className="bg-cool-gray-200 h-8 w-36 rounded animate-pulse" />
                <div className="bg-cool-gray-200 h-8 w-40 rounded animate-pulse" />
              </div>
            </section>
            <section className="col-span-4 px-8">
              <h3 className="text-xxs font-bold text-cool-gray-500">
                Activity
              </h3>
              <div className="mt-6 flex flex-col gap-2 items-start">
                <div className="bg-cool-gray-200 h-8 w-32 rounded animate-pulse" />
                <div className="bg-cool-gray-200 h-8 w-40 rounded animate-pulse" />
                <div className="bg-cool-gray-200 h-8 w-36 rounded animate-pulse" />
              </div>
            </section>
          </section>
        </div>
      </div>
    </header>
  );
};

const SyncLevelError = ({
  resourceStatus,
}: {
  resourceStatus: ResourceStatus;
}) => {
  return (
    <section>
      <h4 className="mb-2">
        <span className="font-bold text-red-600">Error:</span>{" "}
        <span className="text-xs">{resourceStatus.message}</span>
      </h4>
      <div className="p-2 bg-[#a3008d0a] border border-accentContrast rounded-md text-xxs">
        <MarkdownRender>{resourceStatus.instructions}</MarkdownRender>
      </div>
    </section>
  );
};
