import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useStateMachine } from "little-state-machine";
import { useFormContext } from "react-hook-form";
import { toast } from "react-toastify";

import { createResource, updateSurvey } from "lib/api";
import { getPlatformForKind } from "lib/platforms";
import {
  OnboardFormProvider,
  OnboardLayout,
} from "components/onboard/OnboardLayout";
import { FullPageSelectTablesStep } from "components/platforms/common/forms/SelectTablesStep";
import SelectAssociationsTables from "components/platforms/hubspot/SelectAssociationsTables";
import { updateJobId } from "lib/globalState";

export const TableMapping = () => {
  return (
    <OnboardFormProvider>
      <UnprovidedTableMapping />
    </OnboardFormProvider>
  );
};

const UnprovidedTableMapping = () => {
  const { state, actions } = useStateMachine({ updateJobId });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { formState, getValues } = useFormContext();
  const navigate = useNavigate();

  const platformFromState = state["onboardState"].platformKind;
  const platform = platformFromState && getPlatformForKind(platformFromState);

  const handleNextClick = async (event) => {
    event.preventDefault();
    setIsSubmitting(true);

    if (window && window.analytics) {
      window.analytics.track("Onboarding Footer Nav Next Clicked");
    }

    const formValues = getValues();

    const response = await createResource(
      platform.prepareFieldsForCreate(formValues)
    );

    if (response.isOk()) {
      updateSurvey({ used_as_stopgap: true });
      actions.updateJobId(response.value.job_id);

      if (window && window.analytics) {
        window.analytics.track("Onboarding Sync Started", {
          rateLimit: formValues.rateLimit.allowedRequests,
          tables: Object.values(formValues.tables).filter(
            (table) => (table as any).enabled === true
          ).length,
        });
      }
      navigate("/onboard/5");
    } else if (JSON.stringify(response).includes("is banned")) {
      toast.error(
        "This Airtable account has been banned from accessing Sequin. If you think this was an error, please contact us.",
        { toastId: "table-mapping-airtable-account-banned" }
      );
    } else if (response.error.error?.summary) {
      toast.error(response.error.error?.summary, {
        toastId: "table-mapping-error",
      });
    } else {
      // handle server errors not related to validation
      toast.error("An unknown error happened.", {
        toastId: "table-mapping-unknown-error",
      });
    }
    setIsSubmitting(false);
  };

  const nextButtonIsActive = formState.isValid && !isSubmitting;

  return (
    <OnboardLayout
      nextButtonIsActive={nextButtonIsActive}
      onNextClick={handleNextClick}
    >
      <h1 className="flex items-center w-full text-2xl font-bold mb-6">
        Select tables and columns
      </h1>
      <p className="text-sm mb-12">
        We've selected a few tables to get you started.{" "}
        <b>You can always change this later.</b>
      </p>

      <FullPageSelectTablesStep
        isCreate={true}
        isOnboard={true}
        platform={platform}
      >
        {(tables) => (
          <>
            {platform.kind === "hubspot" && (
              <div className="px-4 pb-4 border-t">
                <SelectAssociationsTables tables={tables} isCreate={true} />
              </div>
            )}
          </>
        )}
      </FullPageSelectTablesStep>
    </OnboardLayout>
  );
};
