import * as React from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}

function SvgComponent({
  title,
  titleId,
  ...props
}: React.SVGProps<SVGSVGElement> & SVGRProps) {
  return (
    <svg viewBox="0 0 24 24" fill="none" aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.954 12c0 6.603-5.352 11.955-11.954 11.955S.046 18.603.046 12.001 5.398.047 12 .047 23.954 5.399 23.954 12Zm-5.393.63a.665.665 0 1 1 0-1.33.665.665 0 0 1 0 1.33Zm-12.793-.664 2.04-2.045a.365.365 0 0 0-.52-.514L5.25 11.444l-2.047-2.04a.365.365 0 1 0-.514.52l2.04 2.039-2.04 2.043a.364.364 0 0 0 .256.627c.097 0 .189-.038.258-.107l2.044-2.042 2.036 2.034a.365.365 0 1 0 .524-.508l-2.039-2.044Zm12.792-1.924c-1.06 0-1.923.863-1.923 1.924 0 1.06.862 1.923 1.923 1.923 1.06 0 1.924-.863 1.924-1.923 0-1.061-.864-1.924-1.924-1.924Zm0 4.604a2.683 2.683 0 0 1-2.68-2.68 2.683 2.683 0 0 1 2.68-2.68 2.684 2.684 0 0 1 2.681 2.68 2.684 2.684 0 0 1-2.68 2.68ZM15.69 9.33h-.112a1.52 1.52 0 0 0-.936.316.366.366 0 0 0-.357-.288c-.2 0-.362.161-.362.363l.001 4.514a.366.366 0 0 0 .731 0V11.46c0-.925.085-1.299.877-1.398a1.25 1.25 0 0 1 .153-.007c.217-.008.371-.157.371-.358a.367.367 0 0 0-.366-.366Zm-7.018 2.171v.023h3.761a1.936 1.936 0 0 0-3.76-.03l-.001.007Zm3.322-1.798c.622.393 1.03 1.006 1.188 1.752.077.422-.204.791-.727.802H8.639l.005.057c.02.106.047.208.082.307.202.53.771 1.272 1.834 1.284.325-.002.63-.084.899-.227.21-.118.387-.27.528-.426.047-.056.09-.112.129-.167.178-.228.404-.186.54-.083.16.123.186.38.04.56l-.012.014c-.21.263-.426.483-.686.65-.25.16-.52.274-.8.342-.327.084-.642.103-.965.064a2.702 2.702 0 0 1-2.275-2.002 2.711 2.711 0 0 1 1.05-2.847 2.702 2.702 0 0 1 2.986-.08Z"
        fill="currentColor"
      />
    </svg>
  );
}

const SvgXeroFilled = React.memo(SvgComponent);
export default SvgXeroFilled;
