import React from "react";
import { useNavigate } from "react-router-dom";
import { useStateMachine } from "little-state-machine";
import { ArrowRightIcon } from "@heroicons/react/solid";

import { updateCurrentStep } from "lib/globalState";
import { OnboardingHeader } from "components/onboard/OnboardLayout";
import { Button } from "lib/lucidez/components/Button";

export const Overview = () => {
  const navigate = useNavigate();
  const { actions } = useStateMachine({ updateCurrentStep });

  const handleNextClick = () => {
    actions.updateCurrentStep(1);
    navigate("/onboard/1");
  };

  return (
    <div className="w-full min-h-screen flex flex-col">
      <OnboardingHeader />
      <main className="flex flex-col flex-1 relative">
        <div className="flex flex-col items-center min-h-full flex-1 px-4">
          <div className="flex flex-row flex-1 justify-center w-2/3">
            <div className="flex flex-col w-3/5 justify-center">
              <p className="text-md font-medium text-cool-gray-400">
                Est. time: 2 min.
              </p>
              <h1 className="w-full text-2xl font-bold mb-6">
                Set up your first sync
              </h1>
              <p className="mb-4">
                In less than five minutes, you'll have a Postgres database setup
                to read from and write to APIs.
              </p>
              <p className="mb-8">
                <b>You can change these settings at any time.</b>
              </p>
              <div className="flex">
                <Button
                  variant="primary"
                  size="lg"
                  onClick={handleNextClick}
                  iconRight={<ArrowRightIcon className="h-4 w-4 ml-3" />}
                >
                  Let's get started
                </Button>
              </div>
            </div>
            <div className="flex flex-col justify-center w-2/5 ml-12">
              <div className="flex pb-6 border-l border-dashed border-gray-300">
                <div className="relative mr-6">
                  <StepCircle step={1} />
                </div>
                <div>
                  <h2 className="flex font-bold text-md">Source</h2>
                  <p className="mt-1">
                    Authenticate Sequin with an API like Airtable or Salesforce.
                  </p>
                </div>
              </div>
              <div className="flex py-6 border-l border-dashed border-gray-300">
                <div className="relative mr-6">
                  <StepCircle step={2} />
                </div>
                <div>
                  <h2 className="flex font-bold text-md">Destination</h2>
                  <p className="mt-1">
                    Provision a pre-configured demo database hosted by Sequin or
                    enter the connection details for an existing database you
                    have access to.
                  </p>
                </div>
              </div>
              <div className="flex py-6 border-l border-dashed border-gray-300">
                <div className="relative mr-6">
                  <StepCircle step={3} />
                </div>
                <div>
                  <h2 className="flex font-bold text-md">Sync</h2>
                  <p className="mt-1">
                    Choose which objects from your source to sync to your
                    destination.
                  </p>
                </div>
              </div>
              <div className="flex pt-6 border-l border-dashed border-gray-300">
                <div className="relative mr-6">
                  <StepCircle step={4} />
                </div>
                <div>
                  <h2 className="flex font-bold text-md">
                    Connect + Query (optional)
                  </h2>
                  <p className="mt-1">
                    Learn how to query APIs via Postgres with Sequin.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      <footer className="w-full h-20 relative flex flex-row bg-cool-gray-50 border-t border-gray-200"></footer>
    </div>
  );
};

const StepCircle = ({ step }: { step: number }) => {
  return (
    <span className="flex items-center justify-center w-6 h-6 text-xs bg-white border border-black rounded-full absolute left-[-12px]">
      {step}
    </span>
  );
};
