import * as React from "react";
import { SVGProps, memo } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}

const SvgComponent = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    viewBox="0 0 122 153"
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    style={{
      fillRule: "evenodd",
      clipRule: "evenodd",
      strokeLinejoin: "round",
      strokeMiterlimit: 2,
    }}
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M128.3 87.8c-11.9 0-18.8 5.4-22.1 9-.4-3.2-2.5-7.3-10.7-7.3H77.6v18.6h7.3c1.2 0 1.6.4 1.6 1.6v85.1h21.2v-34.2c3.3 3 9.6 7.2 19.5 7.2 20.7 0 35.2-16.4 35.2-40 .1-23.9-13.7-40-34.1-40m-4.3 61.5c-11.4 0-16.6-10.9-16.6-21.1 0-16 8.7-21.7 16.9-21.7 10 0 16.8 8.6 16.8 21.5-.1 14.8-8.7 21.3-17.1 21.3"
      style={{
        fill: "currentColor",
        fillRule: "nonzero",
      }}
      transform="translate(-110.898 -125.475) scale(1.4291)"
    />
  </svg>
);

const SvgPipedriveFilled = memo(SvgComponent);
export default SvgPipedriveFilled;
