import React from "react";

import { MAILTO_LINK } from "site-constants";

interface Props {
  children: React.ReactNode;
  className?: string;
}

export const ContactUs = ({ children, className = "" }: Props) => {
  const classes = className === "" ? "contact-us-trigger link" : className;

  return (
    <a className={classes} href={MAILTO_LINK}>
      {children}
    </a>
  );
};
