import React from "react";
import { CommonChrome, CommonLayout } from "components/Layouts";

export default function ForbiddenPage() {
  return (
    <CommonChrome>
      <CommonLayout>
        <main className="custom-container my-32">
          <h1 className="font-bold text-xl">Forbidden</h1>
          <div className="mt-8 font-mono">
            You are not allowed to access this page.
          </div>
        </main>
      </CommonLayout>
    </CommonChrome>
  );
}
