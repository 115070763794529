import React from "react";
import { CheckIcon } from "@heroicons/react/outline";
import { XIcon } from "@heroicons/react/solid";

import SvgExclamation from "components/svg/SvgExclamation";
import { ResourceStatusLevel } from "lib/api/types";
import { friendlyMilliseconds } from "lib/utils/formatting";
import HoverPopover from "components/core/HoverPopover";

type ExtendedResourceStatusLevel = ResourceStatusLevel | "disabled";

interface SyncLagTimeProps {
  lagTime: number; // in milliseconds
  level: ExtendedResourceStatusLevel;
}

export const SyncLagTime = ({ lagTime, level }: SyncLagTimeProps) => {
  const content = (
    <div className="w-64 left-0 top-7">
      {getLevelAndExplanationText(lagTime, level)}
    </div>
  );

  return (
    <HoverPopover
      content={content}
      containerProps={{
        placement: "bottom-start",
      }}
    >
      <button>
        <div className="border border-gray-300 cursor-pointer flex items-center justify-between px-2 py-0.5 rounded-full min-w-11 max-w-11 hover:bg-gray-100">
          <LagTimeIconRenderer level={level} />
          <CaptionRenderer level={level} lagTime={lagTime} />
        </div>
      </button>
    </HoverPopover>
  );
};

const CaptionRenderer = ({
  level,
  lagTime,
}: SyncLagTimeProps): React.ReactElement => {
  switch (level) {
    case "disabled":
      return <span className="text-xs text-cool-gray-400">Disabled</span>;

    case "customer_intervention":
    case "sequin_intervention":
      return <span className="text-xs text-red-600">Error</span>;

    default:
      return <span className="text-xs">{friendlyMilliseconds(lagTime)}</span>;
  }
};

interface LagTimeIconRendererProps {
  level: ExtendedResourceStatusLevel;
}

const LagTimeIconRenderer = ({
  level,
}: LagTimeIconRendererProps): React.ReactElement => {
  switch (level) {
    case "disabled": {
      return (
        <div className="border border-cool-gray-300 bg-cool-gray-200 rounded-full mr-1 h-2.5 w-2.5" />
      );
    }
    case "degraded": {
      return (
        <div className="border border-amber-600 bg-amber-200 rounded-full mr-1">
          <SvgExclamation className="h-2.5 w-2.5 text-amber-600" />
        </div>
      );
    }
    case "sequin_intervention":
    case "customer_intervention": {
      return (
        <div className="border border-red-600 bg-red-200 rounded-full mr-1">
          <XIcon className="h-2.5 w-2.5 text-red-600" />
        </div>
      );
    }
    case "healthy":
    default: {
      return (
        <div className="border border-green-600 bg-green-200 rounded-full mr-1">
          <CheckIcon className="h-2.5 w-2.5 text-green-600" />
        </div>
      );
    }
  }
};

const getLevelAndExplanationText = (
  lagTime: number,
  level: ExtendedResourceStatusLevel
): string => {
  if (level === "healthy") {
    return `This collection is taking about ${friendlyMilliseconds(
      lagTime,
      "detailed"
    )} to sync. You can think of this duration as the "maximum staleness" of a given row.`;
  }

  if (level === "degraded") {
    return `Your collection is taking longer to sync than usual, about ${friendlyMilliseconds(
      lagTime,
      "detailed"
    )}.`;
  }

  if (level === "disabled") {
    return "Your sync is disabled.";
  }

  if (["customer_intervention", "sequin_intervention"].includes(level)) {
    return "This collection is erroring.";
  }
};
