import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useStateMachine } from "little-state-machine";
import { CheckIcon } from "@heroicons/react/outline";
import classNames from "classnames";

import {
  useCreateResourceStatus,
  useResource,
  useResourceCollectionStatuses,
  useResourceMetadata,
} from "lib/api/hooks";
import {
  OnboardFormProvider,
  OnboardLayout,
} from "components/onboard/OnboardLayout";
import SvgSpinner from "components/svg/Spinner";

export const SyncInterstitial = () => {
  return (
    <OnboardFormProvider>
      <UnprovidedSyncInterstitial />
    </OnboardFormProvider>
  );
};

const UnprovidedSyncInterstitial = () => {
  const { state } = useStateMachine();
  const navigate = useNavigate();

  const createResourceJobId = state["onboardState"].jobId;

  const [createResourceStatus] = useCreateResourceStatus(createResourceJobId, {
    refreshInterval: 2500,
  });

  const [createdResource] = useResource(
    createResourceStatus?.resource_id ?? null,
    {
      waitUntilLoaded: true,
    },
    { refreshInterval: 2500 }
  );

  const [metadata] = useResourceMetadata(
    createdResource?.id ?? null,
    { waitUntilLoaded: true },
    { refreshInterval: 5000 }
  );

  const [collectionStatuses] = useResourceCollectionStatuses(
    createdResource?.id ?? null
  );

  const stepIsCompleted =
    Boolean(createdResource?.id) &&
    Boolean(metadata?.resourceId) &&
    Boolean(collectionStatuses?.length);

  const navigateToBackfills = (event) => {
    event.preventDefault();

    if (createdResource) {
      if (window && window.analytics) {
        window.analytics.track("Onboarding Footer Nav Next Clicked");
      }
      navigate(`/syncs/${createdResource.permaslug}/backfills`, {
        state: { shouldShowOnboardingModal: true },
      });
    }
  };

  useEffect(() => {
    if (!createResourceJobId) {
      navigate("/onboard/4");
    }
  }, []);

  return (
    <OnboardLayout
      nextButtonIsActive={stepIsCompleted}
      onNextClick={navigateToBackfills}
    >
      <h1 className="flex items-center w-full text-2xl font-bold mb-12">
        Creating your sync
      </h1>
      <ul>
        <li
          className={classNames("flex items-center font-bold mb-4", {
            "text-gray-500": Boolean(createdResource),
          })}
        >
          {createdResource ? (
            <CheckIcon className="w-4 h-4 mr-4" />
          ) : (
            <SvgSpinner className="w-4 h-4 mr-4 animate-spin" />
          )}
          Setting up the schema{" "}
          {createdResource && (
            <p className="ml-2 text-xs font-normal font-mono text-purple-800">
              [{createdResource.database.schema}]
            </p>
          )}
        </li>
        <li
          className={classNames("flex items-center font-bold mb-4", {
            "text-gray-500": Boolean(metadata),
          })}
        >
          {metadata ? (
            <CheckIcon className="w-4 h-4 mr-4" />
          ) : (
            <>
              {createdResource ? (
                <SvgSpinner className="w-4 h-4 mr-4 animate-spin" />
              ) : (
                <div className="mr-8" />
              )}
            </>
          )}
          Creating your tables
        </li>
        <li
          className={classNames("flex items-center font-bold", {
            "text-gray-500": Array.isArray(collectionStatuses),
          })}
        >
          {Array.isArray(collectionStatuses) ? (
            <CheckIcon className="w-4 h-4 mr-4" />
          ) : (
            <>
              {metadata && createdResource ? (
                <SvgSpinner className="w-4 h-4 mr-4 animate-spin" />
              ) : (
                <div className="mr-8" />
              )}
            </>
          )}
          Kicking off backfills
        </li>
      </ul>
    </OnboardLayout>
  );
};
