import React, { useEffect } from "react";
import ErrorPage from "./ErrorPage";
import { ForbiddenError, NotAuthorizedError } from "../lib/api";
import ForbiddenPage from "./ForbiddenPage";
import { useNavigate, useRouteError } from "react-router-dom";
import * as Sentry from "@sentry/nextjs";

export function ErrorBoundary() {
  const error = useRouteError();
  const navigate = useNavigate();

  useEffect(() => {
    if (error instanceof NotAuthorizedError) {
      navigate("/login", { replace: true });
      return;
    } else if (error instanceof ForbiddenError) {
      // Display forbidden page below
      return;
    }

    // Ensure exception is captured
    Sentry.captureException(error);
  }, [error]);

  if (error && error instanceof ForbiddenError) {
    return <ForbiddenPage />;
  }

  return <ErrorPage msg={(error as Error)?.message} />;
}
