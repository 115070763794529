import React, { useCallback } from "react";
import { useParams } from "react-router-dom";

import { useResourceBySlug, useSchemaCreationQueries } from "lib/api/hooks";
import { PageLoadingSpinner } from "components/core/PageLoadingSpinner";
import { CopyTextToClipboardButton } from "components/core/CopyTextToClipboardButton";
import { Button } from "lib/lucidez";

import { Light as SyntaxHighlighter } from "react-syntax-highlighter";
import sql from "react-syntax-highlighter/dist/cjs/languages/hljs/sql";
import stackoverflowLight from "react-syntax-highlighter/dist/cjs/styles/hljs/stackoverflow-light";

SyntaxHighlighter.registerLanguage("sql", sql);

const downloadTextFile = (text, filename) => {
  const file = new Blob([text], { type: "text/plain" });

  const downloadElement = document.createElement("a");
  downloadElement.setAttribute("href", URL.createObjectURL(file));
  downloadElement.setAttribute("download", filename);

  downloadElement.click();
};

export const SyncPageSchema = () => {
  const { permaslug } = useParams();
  const [resource] = useResourceBySlug(permaslug as string, {
    waitUntilLoaded: true,
  });

  const [queries] = useSchemaCreationQueries(resource?.id);

  const joinQueries = useCallback(() => {
    return queries.join("\n\n");
  }, [queries]);

  if (!resource || !queries?.length) {
    return <PageLoadingSpinner />;
  }

  return (
    <section className="py-12 flex-1">
      <div className="custom-container">
        <h2 className="flex text-2xl font-bold mb-8 relative w-auto items-center">
          Schema Creation Scripts
          <span className="ml-4 flex">
            <CopyTextToClipboardButton
              textToCopy={joinQueries()}
              className={"h-6"}
            />
            <span className="text-sm text-gray-500 relative top-0.5">
              (Copy all)
            </span>
          </span>
        </h2>
        <Button
          variant="tertiary"
          size="md"
          onClick={() => {
            downloadTextFile(joinQueries(), "sequin_schema.ddl");
          }}
        >
          Download All
        </Button>

        {queries.map((query) => {
          return (
            <div className="mt-6 relative max-w-[80%]" key={query}>
              <SyntaxHighlighter
                codeTagProps={{ className: "undecorated" }}
                language="sql"
                showLineNumbers={true}
                style={stackoverflowLight}
                className="rounded-md border border-gray-200"
              >
                {query}
              </SyntaxHighlighter>
              <CopyTextToClipboardButton
                textToCopy={query}
                className={"absolute right-1 top-1.5 h-6"}
              />
            </div>
          );
        })}
      </div>
    </section>
  );
};
