import React, { useRef, useState } from "react";
import { Popover } from "@headlessui/react";
import { UserAddIcon, UserIcon, TrashIcon } from "@heroicons/react/outline";
import { PencilIcon } from "@heroicons/react/solid";
import Head from "next/head";
import { Link } from "react-router-dom";
import { mutate } from "swr";

import { PageLoadingSpinner } from "components/core/PageLoadingSpinner";
import SvgSpinner from "components/svg/Spinner";
import {
  deletePendingUser,
  deleteUser,
  updatePendingUser,
  updateUser,
} from "lib/api";
import { useUsers, useProfile } from "lib/api/hooks";
import { PendingUser, User } from "lib/api/types";
import { Button } from "lib/lucidez";
import { FormUtils, useId, useWorkable } from "lib/utils";

const { bindEnterKeyPress } = FormUtils;

export default function Users({ children }: React.PropsWithChildren<{}>) {
  const [users] = useUsers();
  const [currentUser] = useProfile();
  const sortedUsers = users
    ? users.sort((a, b) => (a.email || a.id).localeCompare(b.email || b.id))
    : [];

  return (
    <>
      <Head>
        <title>Users - Sequin</title>
      </Head>
      <main className="pb-12">
        <PageHeader />
        <div className="custom-container mt-12">
          <div className="bg-white px-8 shadow rounded">
            {users ? (
              <table className="w-full border-collapse">
                <tbody>
                  {sortedUsers.map((u, idx) => (
                    <UserRow
                      user={u}
                      isFirst={idx === 0}
                      isMe={currentUser && currentUser.id === u.id}
                      key={u.id + idx}
                    />
                  ))}
                </tbody>
              </table>
            ) : (
              <PageLoadingSpinner />
            )}
          </div>
        </div>
        {children}
      </main>
    </>
  );
}

const PageHeader = () => {
  return (
    <div className="w-full pt-8">
      <div className="custom-container">
        <div className="flex flex-row items-center justify-between gap-24">
          <h1 className=" font-bold text-2xl ml-2">Users</h1>
          <Link
            to="/users/invite"
            onClick={() => {
              if (window && window.analytics) {
                window.analytics.track("Invite User Started");
              }
            }}
          >
            <Button
              variant="primary"
              iconLeft={<UserAddIcon className="h-5 mr-2 w-5" />}
              as="span"
            >
              Invite User
            </Button>
          </Link>
        </div>
      </div>
    </div>
  );
};

function UserRow({
  user,
  isFirst,
  isMe,
}: {
  user: User | PendingUser;
  isFirst?: boolean;
  isMe?: boolean;
}) {
  const [ísHovering, setIsHovering] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [editingNameValue, setEditingNameValue] = useState(
    user.name || user.email
  );
  const [isUpdatingName, applyIsUpdatingName] = useWorkable();
  const [isDeleting, applyIsDeleting] = useWorkable();

  const inputId = useId("name-input-");
  const nameInputRef = useRef<HTMLInputElement>(null);
  const deleteBtnRef = useRef<any>();

  const isActive = user.status === "active";
  const displayName =
    isEditing || isUpdatingName ? editingNameValue : user.name || user.email;

  const handleMouseEnter = () => setIsHovering(true);
  const handleMouseLeave = () => setIsHovering(false);

  const handleInputFocus = (ev) => {
    ev.target.select();
    setEditingNameValue(user.name || user.email);
    setIsEditing(true);
  };

  const handleInputBlur = () => {
    setIsEditing(false);
    applyIsUpdatingName(updateName);
  };

  const updateName = async () => {
    if (editingNameValue === (user.name || user.email)) {
      return;
    } // consider not updated

    await (isActive
      ? updateUser(user.id, { name: editingNameValue })
      : updatePendingUser(user.id, { name: editingNameValue }));

    await mutate("/api/users");
  };

  const handleEnterKeyPress = () => {
    nameInputRef.current?.blur();
  };

  const handleDelete = async () => {
    closeDeletePopover();

    if (isActive) {
      await applyIsDeleting(() => deleteUser(user.id));
    } else {
      await applyIsDeleting(() => deletePendingUser(user.id));
    }

    await mutate("/api/users");
  };

  const closeDeletePopover = () => {
    deleteBtnRef.current?.click();
  };

  return (
    <tr
      className={`flex flex-row w-full py-8 ${
        isFirst ? "" : "border-t border-gray-100"
      }`}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <td>
        <div
          className={`${
            isActive ? "bg-gray-100" : "border border-dashed border-gray-200"
          } rounded-full w-14 h-14 flex items-center justify-center`}
        >
          <UserIcon className="h-6" />
        </div>
      </td>
      <td className="flex flex-col justify-center ml-5 flex-1">
        <div className="flex flex-row items-center">
          <div className="relative w-min">
            <label htmlFor={inputId} className="sr-only">
              Name
            </label>
            <input
              ref={nameInputRef}
              onFocus={handleInputFocus}
              onBlur={handleInputBlur}
              onKeyPress={bindEnterKeyPress(handleEnterKeyPress)}
              disabled={isUpdatingName}
              id={inputId}
              className={`font-bold absolute left-0 w-full cursor-pointer focus:cursor-text ${
                isUpdatingName ? "text-gray-400" : "text-black"
              }`}
              value={displayName}
              onChange={(ev) => setEditingNameValue(ev.target.value)}
            />
            {/* Helper span to resize the name textbox */}
            <span className="font-bold invisible whitespace-pre">
              {displayName}
            </span>
          </div>
          {isUpdatingName && (
            <SvgSpinner className="h-3 w-3 animate-spin ml-1" />
          )}
          {!isActive && (
            <span className="uppercase ml-2 text-xxs font-bold px-2 rounded-full bg-gray-100 flex flex-col justify-center text-gray-600">
              Pending
            </span>
          )}
          {isMe && (
            <span className="uppercase ml-2 text-xxs font-bold px-2 rounded-full bg-gray-100 flex flex-col justify-center text-gray-600">
              You
            </span>
          )}
          {ísHovering && !isUpdatingName && (
            <button
              className="ml-2"
              onClick={() => nameInputRef.current?.focus()}
            >
              <PencilIcon className="h-4 w-4" />
              <span className="sr-only">Edit name</span>
            </button>
          )}
        </div>
        {user.name && <span className="text-xs">{user.email}</span>}
      </td>
      <td className="flex flex-row justify-center items-center">
        {!isMe && (
          <Popover className="relative">
            <Popover.Button
              className="text-gray-600 hover:text-black focus:text-black"
              ref={deleteBtnRef}
              disabled={isDeleting}
            >
              {isDeleting ? (
                <SvgSpinner className="h-6 animate-spin" />
              ) : (
                <TrashIcon className="h-6" />
              )}
            </Popover.Button>
            <Popover.Panel className="absolute z-10 bottom-full border border-gray-200 bg-white p-4 shadow-lg rounded right-0 transform -translate-y-1 w-64">
              <p className="text-xs">
                Are you sure you want to delete this user?
              </p>
              <div className="flex flex-row mt-4">
                <div className="flex-1" />

                <Button
                  variant="outlined"
                  size="sm"
                  onClick={handleDelete}
                  isLoading={isDeleting}
                >
                  Yes
                </Button>
                <Button
                  variant="primary"
                  size="sm"
                  onClick={closeDeletePopover}
                  className="ml-2"
                >
                  No
                </Button>
              </div>
            </Popover.Panel>
          </Popover>
        )}
      </td>
    </tr>
  );
}
