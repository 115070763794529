import React from "react";
import { Link, useLocation } from "react-router-dom";
import { InlineWidget, useCalendlyEventListener } from "react-calendly";
import { ArrowLeftIcon } from "@heroicons/react/outline";

import { backendEvent } from "lib/api";
import { useProfile } from "lib/api/hooks";
import { PageLoadingSpinner } from "components/core/PageLoadingSpinner";
import { SvgAirtableColored } from "components/svg/AirtableFilled";
import SvgAuthZeroFilled from "components/svg/AuthZeroFilled";
import SvgGithubFilled from "components/svg/GitHubFilled";
import SvgHubspotFilled from "components/svg/Hubspot";
import { SvgLinearFilled } from "components/svg/LinearFilled";
import SvgMarketoFilled from "components/svg/MarketoFilled";
import SvgNetSuiteFilled from "components/svg/NetSuiteFilled";
import SvgQuickbooksFilled from "components/svg/QuickbooksFilled";
import { SvgSalesforceColored } from "components/svg/Salesforce";
import { SvgSendGridFilled } from "components/svg/SendGridFilled";
import { SvgServiceNowFilled } from "components/svg/ServiceNowFilled";
import SvgShopifyFilled from "components/svg/ShopifyFilled";
import SvgStripeFilled from "components/svg/StripeFilled";
import { SvgWorkdayColored } from "components/svg/WorkdayColored";
import SvgZendeskFilled from "components/svg/Zendesk";
import { ONBOARDING_CALENDLY_LINK } from "site-constants";

// Quick hack, this should go away when we add beta platforms as full platforms
const platforms = {
  airtable: {
    displayName: "Airtable",
    logo: <SvgAirtableColored className="h-10 mr-2" />,
  },
  auth0: {
    displayName: "Auth0",
    logo: <SvgAuthZeroFilled className="h-10 mr-2" color="#EB5323" />,
  },
  github: {
    displayName: "GitHub",
    logo: <SvgGithubFilled className="h-10 mr-2" />,
  },
  hubspot: {
    displayName: "HubSpot",
    logo: <SvgHubspotFilled className="h-10 mr-2" color="#F8761F" />,
  },
  linear: {
    displayName: "Linear",
    logo: <SvgLinearFilled className="h-10 mr-2" color="#5E6AD2" />,
  },
  marketo: {
    displayName: "Marketo",
    logo: <SvgMarketoFilled className="h-10 mr-2" color="#5C4C9F" />,
  },
  netsuite: {
    displayName: "NetSuite",
    logo: <SvgNetSuiteFilled className="h-10 mr-2" color="#0082CA" />,
  },
  quickbooks: {
    displayName: "Quickbooks",
    logo: <SvgQuickbooksFilled className="h-10 mr-2" color="#01A200" />,
  },
  salesforce: {
    displayName: "Salesforce",
    logo: (
      <SvgSalesforceColored
        className="h-10 mr-2"
        fillColor="#00A1E0"
        textColor="#FFFFFF"
      />
    ),
  },
  sendgrid: {
    displayName: "SendGrid",
    logo: <SvgSendGridFilled className="h-10 mr-2" color="#1A82E2" />,
  },
  servicenow: {
    displayName: "ServiceNow",
    logo: <SvgServiceNowFilled className="h-10 mr-2" color="#81b5a1" />,
  },
  shopify: {
    displayName: "Shopify",
    logo: <SvgShopifyFilled className="h-10 mr-2" color="#96BF47" />,
  },
  stripe: {
    displayName: "Stripe",
    logo: <SvgStripeFilled className="h-10 mr-2" color="#635aff" />,
  },
  workday: {
    displayName: "Workday",
    logo: <SvgWorkdayColored className="h-10 mr-2" />,
  },
  zendesk: {
    displayName: "ZenDesk",
    logo: <SvgZendeskFilled className="h-10 mr-2" color="#00343B" />,
  },
};

interface LocationState {
  state?: {
    kind?: string;
  };
}

export const BetaPlatform = () => {
  const { state }: LocationState = useLocation();
  const [user] = useProfile();

  if (!state || !state.kind) {
    window.location.pathname = "/onboard/2";
    return null;
  }

  return (
    <div className="w-full min-h-screen flex flex-col justify-center">
      <main className="flex flex-col relative">
        <header className="flex mt-6 items-center">
          <div className="flex-1" />
          <Link to="/onboard/2" className="flex-1">
            <ArrowLeftIcon className="w-6 h-6" />
          </Link>
          <h1 className="flex justify-center items-center text-3xl font-bold">
            {platforms[state.kind].logo}
            {platforms[state.kind].displayName}
          </h1>
          <div className="flex-1" />
          <div className="flex-1" />
        </header>
        {user?.email ? (
          <CalendlyWidget kind={state.kind} email={user.email} />
        ) : (
          <PageLoadingSpinner />
        )}
      </main>
    </div>
  );
};

const CalendlyWidget = ({ kind, email }: { kind: string; email: string }) => {
  useCalendlyEventListener({
    onEventScheduled: () => {
      backendEvent("Onboarding Beta Platform Meeting Scheduled", {
        email: email,
        source: kind,
      });
    },
  });

  const preFilledData = {
    email,
    customAnswers: {
      a1: platforms[kind].displayName,
    },
  };

  const pageSettings = {
    hideGdprBanner: true,
  };

  return (
    <>
      <p className="mx-auto max-w-3xl mt-8 text-lg">
        <div
          className="p-4 mb-4 text-blue-800 rounded-lg bg-blue-50 dark:bg-gray-800 dark:text-blue-400"
          role="alert"
        >
          <p className="mb-4">
            <b>Get onboarded to Sequin.</b>
          </p>

          <p>
            To provide the best experience, our team is walking every new
            customer through setting up Sequin. Select a time below that works
            for you:
          </p>
        </div>
      </p>
      <InlineWidget
        pageSettings={pageSettings}
        prefill={preFilledData}
        url={ONBOARDING_CALENDLY_LINK}
      />
    </>
  );
};
