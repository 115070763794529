import React, { useEffect, useState } from "react";
import Head from "next/head";
import { CheckCircleIcon } from "@heroicons/react/solid";
import { Button } from "lib/lucidez";
import { updateSurvey } from "lib/api";
import { PageLoadingSpinner } from "./core/PageLoadingSpinner";

export default function WritesBetaConfirmation() {
  const [done, setDone] = useState(false);

  useEffect(() => {
    const update = async () => {
      await updateSurvey({ sql_writes_beta: true });
      setDone(true);
    };
    update();
  }, []);

  return (
    <>
      <Head>
        <title>Sequin - You're on the waitlist</title>
      </Head>
      <main
        style={{ backgroundColor: "#0D1117", height: "100vh" }}
        className="h-full w-full"
      >
        {!done ? (
          <div className="py-32">
            <PageLoadingSpinner variant="white" />
          </div>
        ) : (
          <div style={{ ...svgStyle, height: "100vh" }} className="w-full">
            <div className="custom-container py-32">
              <div className="grid place-items-center text-purple-300 h-full">
                {/* TODO: Not exact color? */}
                <CheckCircleIcon className="w-16 h-16" />
                <p className="font-jakartaSans font-bold text-lg leading-8">
                  You’re on the waitlist!
                </p>
                <p className="font-jakartaSans text-sm leading-8 text-white">
                  We'll reach out when we're ready to onboard you to SQL writes.
                </p>
                <Button
                  as="a"
                  variant="outlined"
                  size="xl"
                  className="mt-4 bg-white"
                  href={"/"}
                >
                  Go home
                </Button>
              </div>
            </div>
          </div>
        )}
      </main>
    </>
  );
}

const svgDataUrl = `url("data:image/svg+xml,%3Csvg width='378' height='822' viewBox='0 0 378 822' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.25 -165.547C0.25 -165.547 370.645 -199.208 516.941 57.5521C663.237 314.312 302.817 475.57 473.717 717.457C644.618 959.344 780.939 858.475 939.87 1217.78C1098.8 1577.09 748.355 1836.2 901.301 2082' stroke='%23919CF9' stroke-opacity='0.2' stroke-width='1.68392'/%3E%3C/svg%3E")`;

const svgStyle = {
  backgroundImage: `${svgDataUrl}`,
  backgroundRepeat: "repeat",
  backgroundPosition: "10px 10px",
};
