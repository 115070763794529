import React from "react";
import { Outlet } from "react-router-dom";

import Footer from "components/Footer";
import Header from "components/Header";

export const CommonChrome = ({ children }: React.PropsWithChildren<{}>) => {
  return (
    <div className="min-h-screen flex flex-col">
      <Header />
      {children}
      <Outlet />
      <Footer />
    </div>
  );
};

export const CommonLayout = ({ children }: React.PropsWithChildren<{}>) => {
  return (
    <div className="flex flex-col flex-1 bg-gray-100 dark:bg-black">
      {children}
      <Outlet />
    </div>
  );
};

export const FullPageLayout = ({ children }: React.PropsWithChildren<{}>) => {
  return (
    <div className="flex flex-col flex-1 dark:bg-black">
      {children}
      <Outlet />
    </div>
  );
};
