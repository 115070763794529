import { Menu, Transition } from "@headlessui/react";
import React, { Fragment } from "react";

export default function Dropdown(props: ExtractProps<typeof Menu>) {
  return (
    <Menu
      as="div"
      className="relative inline-block text-left"
      {...props}
    ></Menu>
  );
}

const Button = (props: ExtractProps<typeof Menu.Button>) => (
  <div>
    <Menu.Button {...props} />
  </div>
);

const Items = (props: ExtractProps<typeof Menu.Items>) => (
  <Transition
    as={Fragment}
    enter="transition ease-out duration-100"
    enterFrom="transform opacity-0 scale-95"
    enterTo="transform opacity-100 scale-100"
    leave="transition ease-in duration-75"
    leaveFrom="transform opacity-100 scale-100"
    leaveTo="transform opacity-0 scale-95"
  >
    <Menu.Items {...props}>
      <div className="p-1">{props.children}</div>
    </Menu.Items>
  </Transition>
);

interface StyledButtonItemProps
  extends React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {}

const StyledButtonItem = (props: StyledButtonItemProps) => (
  <Dropdown.Item>
    {({ active }) => (
      <button
        {...props}
        className={
          `${
            active
              ? "bg-gray-100 dark:bg-gray-900 text-black dark:text-white"
              : "text-gray-700 dark:text-gray-300"
          } group flex rounded items-center w-full px-4 py-2 text-xs font-semibold` +
          (props.className || "")
        }
      />
    )}
  </Dropdown.Item>
);

type ExtractProps<TComponentOrTProps> =
  TComponentOrTProps extends React.ComponentType<infer TProps>
    ? TProps
    : TComponentOrTProps;

Dropdown.Button = Button;
Dropdown.Items = Items;
Dropdown.Item = Menu.Item;
Dropdown.StyledButtonItem = StyledButtonItem;
