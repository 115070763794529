import React from "react";

import { CopyTextToClipboardButton } from "components/core/CopyTextToClipboardButton";
import { CopyableCodeLine } from "components/core/CopyableCodeLine";
import { MessageQueue } from "lib/api/types";

import { Light as SyntaxHighlighter } from "react-syntax-highlighter";
import bash from "react-syntax-highlighter/dist/cjs/languages/hljs/bash";
import stackoverflowLight from "react-syntax-highlighter/dist/cjs/styles/hljs/stackoverflow-light";

SyntaxHighlighter.registerLanguage("bash", bash);

export const EventsConnectionDisplay = ({
  messageQueue,
}: {
  messageQueue: MessageQueue;
}) => {
  const restCommand = `curl "https://${messageQueue.definition.restEndpoint}/" \\
-H "Kafka-Auto-Offset-Reset: earliest" \\
-u "${messageQueue.credentials.username}:${messageQueue.credentials.password}"`;

  return (
    <div className="">
      <h4 className="mb-4 font-bold">Details</h4>
      <dl className="grid grid-cols-5 gap-x-4 gap-y-1 mb-6 border border-gray-200 rounded-md p-4">
        <dt className="col-span-1 font-bold">Region</dt>
        <dd className="col-span-4">
          <CopyableCodeLine code={messageQueue.definition.region} discrete />
        </dd>
        <dt className="col-span-1 font-bold">Endpoint</dt>
        <dd className="col-span-4">
          <CopyableCodeLine
            code={messageQueue.definition.tcpEndpoint}
            discrete
          />
        </dd>
        <dt className="col-span-1 font-bold">Username</dt>
        <dd className="col-span-4">
          <CopyableCodeLine code={messageQueue.credentials.username} discrete />
        </dd>
        <dt className="col-span-1 font-bold">Password</dt>
        <dd className="col-span-4">
          <CopyableCodeLine code={messageQueue.credentials.password} discrete />
        </dd>
      </dl>

      <h4 className="mb-4 font-bold">Connect via Kafka</h4>
      <section className="mb-6 relative">
        <dl className="grid grid-cols-5 gap-x-4 gap-y-1 mb-6 border border-gray-200 rounded-md p-4">
          <dt className="col-span-1 font-bold">Host</dt>
          <dd className="col-span-4">
            <CopyableCodeLine
              code={messageQueue.definition.tcpEndpoint}
              discrete
            />
          </dd>
          <dt className="col-span-1 font-bold">Protocol</dt>
          <dd className="col-span-4">
            <CopyableCodeLine code="SASL_SSL" discrete />
          </dd>
          <dt className="col-span-1 font-bold">Sasl Mechanism</dt>
          <dd className="col-span-4">
            <CopyableCodeLine code="SCRAM-SHA-256" discrete />
          </dd>
          <dt className="col-span-1 font-bold">Sasl Username</dt>
          <dd className="col-span-4">
            <CopyableCodeLine
              code={messageQueue.credentials.username}
              discrete
            />
          </dd>
          <dt className="col-span-1 font-bold">Sasl Password</dt>
          <dd className="col-span-4">
            <CopyableCodeLine
              code={messageQueue.credentials.password}
              discrete
            />
          </dd>
        </dl>
      </section>

      <h4 className="mb-4 font-bold">Connect via REST</h4>
      <section className="relative">
        <SyntaxHighlighter
          codeTagProps={{ className: "undecorated" }}
          language="sql"
          showLineNumbers={true}
          style={stackoverflowLight}
          className="rounded-md border border-gray-200"
        >
          {restCommand}
        </SyntaxHighlighter>
        <CopyTextToClipboardButton
          textToCopy={restCommand}
          className={"absolute right-1 top-1.5 h-6"}
        />
      </section>
    </div>
  );
};
