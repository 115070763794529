import * as React from "react";
import { SVGProps, memo } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}

const SvgComponent = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    viewBox="0 0 60 94"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M16.07 26.199c0 17.826 43.755 5.672 43.755 40.243 0 15.936-12.424 26.74-29.71 26.74C12.56 93.181 0 82.107 0 65.766h16.07c0 7.968 5.402 12.56 14.045 12.56 8.373 0 13.505-4.187 13.505-11.885C43.62 45.511.135 59.96.135 26.2.135 10.534 12.425 0 29.575 0 46.995 0 59.42 11.074 59.42 27.414H43.35c0-7.967-5.402-12.559-13.775-12.559-8.103 0-13.505 4.051-13.505 11.344Z"
      fill="currentColor"
    />
  </svg>
);

const SequinIcon = memo(SvgComponent);
export default SequinIcon;
