import React from "react";
import { Link, useLocation } from "react-router-dom";
import {
  LightningBoltIcon as LightningBoltIconOutline,
  UserGroupIcon as UserGroupIconOutline,
  DocumentTextIcon as DocumentTextIconOutline,
  DatabaseIcon as DatabaseIconOutline,
  KeyIcon as KeyIconOutline,
} from "@heroicons/react/outline";
import {
  LightningBoltIcon as LightningBoltIconSolid,
  UserGroupIcon as UserGroupIconSolid,
  DocumentTextIcon as DocumentTextIconSolid,
  DatabaseIcon as DatabaseIconSolid,
  KeyIcon as KeyIconSolid,
} from "@heroicons/react/solid";

import { DOCS_URL } from "site-constants";

export default function Navbar() {
  return (
    <>
      <NavLink
        href="/"
        additionalRoutes={["/resources", "/syncs"]}
        exact
        icon={<LightningBoltIconOutline className="h-5" />}
        activeIcon={<LightningBoltIconSolid className="h-5" />}
      >
        Syncs
      </NavLink>
      <NavLink
        href="/users"
        icon={<UserGroupIconOutline className="h-5" />}
        activeIcon={<UserGroupIconSolid className="h-5" />}
      >
        Users
      </NavLink>
      <NavLink
        href="/databases"
        icon={<DatabaseIconOutline className="h-5" />}
        activeIcon={<DatabaseIconSolid className="h-5" />}
      >
        Databases
      </NavLink>
      <NavLink
        href="/credentials"
        icon={<KeyIconOutline className="h-5" />}
        activeIcon={<KeyIconSolid className="h-5" />}
      >
        Credentials
      </NavLink>
      <NavLink
        href={DOCS_URL}
        icon={<DocumentTextIconOutline className="h-5" />}
        activeIcon={<DocumentTextIconSolid className="h-5" />}
        externalLink
      >
        Docs
      </NavLink>
    </>
  );
}

interface NavLinkProps {
  activeIcon?: JSX.Element;
  additionalRoutes?: string[];
  exact?: boolean;
  externalLink?: boolean;
  href: string;
  icon?: JSX.Element;
}

function NavLink({
  activeIcon,
  additionalRoutes,
  children,
  exact,
  externalLink,
  href,
  icon,
}: React.PropsWithChildren<NavLinkProps>) {
  const { pathname } = useLocation();
  let isActive = exact ? pathname === href : pathname.startsWith(href);

  if (Array.isArray(additionalRoutes) && !isActive) {
    isActive = additionalRoutes.some((route) => {
      return pathname.startsWith(route);
    });
  }

  const commonClasses =
    "h-full flex items-center justify-center border-b-2 gap-2 font-bold";

  const linkClasses = isActive
    ? `${commonClasses} border-black`
    : `${commonClasses} text-gray-500 hover:text-black border-transparent`;

  const linkIcon = isActive ? (activeIcon ? activeIcon : icon) : icon;

  if (externalLink) {
    return (
      <a
        href={href}
        className={linkClasses}
        target="_blank"
        rel="noreferrer noopener"
      >
        {linkIcon}
        {children}
      </a>
    );
  }

  return (
    <Link to={href} className={linkClasses}>
      {linkIcon}
      {children}
    </Link>
  );
}
