import * as React from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}

function SvgComponent({
  title,
  titleId,
  ...props
}: React.SVGProps<SVGSVGElement> & SVGRProps) {
  return (
    <svg viewBox="0 0 26 28" fill="none" aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.386 27H1.27l6.763-14.426 3.064 6.44L7.387 27Zm1.085-15.798a.49.49 0 0 0-.442-.282h-.002a.493.493 0 0 0-.441.285L.048 27.285a.507.507 0 0 0 .028.482c.09.145.247.233.415.233h7.206c.19 0 .363-.111.445-.286l3.944-8.489a.507.507 0 0 0-.002-.432l-3.613-7.591ZM25.018 27h-5.882L9.666 6.289A.491.491 0 0 0 9.223 6H5.37l.005-5h7.549l9.424 20.71c.08.177.256.29.446.29h2.224v5Zm.49-6h-2.4L13.684.29a.492.492 0 0 0-.446-.29H4.884a.496.496 0 0 0-.491.5l-.006 6c0 .132.052.259.144.354A.488.488 0 0 0 4.878 7H8.91l9.468 20.711c.08.176.254.289.445.289h6.686a.495.495 0 0 0 .491-.5v-6c0-.276-.219-.5-.491-.5Z"
        fill="currentColor"
      />
    </svg>
  );
}

const SvgAwsLambdaFilled = React.memo(SvgComponent);
export default SvgAwsLambdaFilled;
