import React, { useMemo, useRef, useState } from "react";
import { BellIcon, CheckIcon, SearchIcon } from "@heroicons/react/outline";
import { PuzzleIcon } from "@heroicons/react/solid";
import { partition } from "lodash";
import { Link } from "react-router-dom";

import { sendUpvote } from "lib/api";
import { HookError, useProfile, useSearchablePlatforms } from "lib/api/hooks";
import { Button } from "lib/lucidez";
import { ResourceKind } from "lib/platforms";
import { limitedFilter, useDebounce } from "lib/utils";
import { PageLoadingSpinner } from "components/core/PageLoadingSpinner";
import { AIRTABLE_PLATFORM } from "lib/platforms/airtable";
import { GITHUB_PLATFORM } from "lib/platforms/github";
import { HUBSPOT_PLATFORM } from "lib/platforms/hubspot";
import { SALESFORCE_PLATFORM } from "lib/platforms/salesforce";
import { STRIPE_PLATFORM } from "lib/platforms/stripe";

import SvgAirtableFilled, {
  SvgAirtableColored,
} from "components/svg/AirtableFilled";
import SvgAuthZeroFilled from "components/svg/AuthZeroFilled";
import SvgAwsLambdaFilled from "components/svg/LambaFilled";
import SvgBambooHRFilled from "components/svg/BambooHRFilled";
import SvgCloseFilled from "components/svg/CloseFilled";
import SvgFacebookFilled from "components/svg/SvgFacebookFilled";
import SvgGithubFilled from "components/svg/GitHubFilled";
import SvgGoogleAnalyticsFilled from "components/svg/GoogleAnalyticsFilled";
import SvgHubspotFilled from "components/svg/Hubspot";
import SvgJiraFilled from "components/svg/JiraFilled";
import { SvgLinearFilled } from "components/svg/LinearFilled";
import SvgMarketoFilled from "components/svg/MarketoFilled";
import SvgNetSuiteFilled from "components/svg/NetSuiteFilled";
import SvgNotionFilled from "components/svg/NotionFilled";
import SvgPagerDutyFilled from "components/svg/PagerDutyFilled";
import SvgPipedriveFilled from "components/svg/Pipedrive";
import SvgQuickbooksFilled from "components/svg/QuickbooksFilled";
import SvgSalesforceFilled, {
  SvgSalesforceColored,
} from "components/svg/Salesforce";
import { SvgSendGridFilled } from "components/svg/SendGridFilled";
import { SvgServiceNowFilled } from "components/svg/ServiceNowFilled";
import SvgShopifyFilled from "components/svg/ShopifyFilled";
import SvgSquareUpFilled from "components/svg/SquareUpFilled";
import SvgStripeFilled from "components/svg/StripeFilled";
import SvgTwilioFilled from "components/svg/TwilioFilled";
import SvgTypeFormFilled from "components/svg/SvgTypeFormFilled";
import { SvgWorkdayColored } from "components/svg/WorkdayColored";
import SvgXeroFilled from "components/svg/XeroFilled";
import SvgZendeskFilled from "components/svg/Zendesk";
import SvgZohoFilled from "components/svg/Zoho";

enum PlatformListItemType {
  Current, // Platforms current available on Sequin
  Upcoming, // Platforms we plan on supporting
  Suggested, // Platforms we still have no plans on supporting
}
interface PlatformListItem {
  icon?: React.ReactNode;
  name: string;
  slug: string;
  type: PlatformListItemType;
}

declare global {
  interface Window {
    Intercom: any;
  }
}

const CURRENT_PLATFORMS: PlatformListItem[] = [
  {
    icon: <SvgAirtableFilled className="h-6" />,
    name: "Airtable",
    slug: "airtable",
    type: PlatformListItemType.Current,
  },
  {
    icon: <SvgGithubFilled className="h-6" />,
    name: "GitHub",
    slug: "github",
    type: PlatformListItemType.Current,
  },
  {
    icon: <SvgHubspotFilled className="h-6" />,
    name: "Hubspot",
    slug: "hubspot",
    type: PlatformListItemType.Current,
  },
  {
    icon: <SvgSalesforceFilled className="h-6" />,
    name: "Salesforce",
    slug: "salesforce",
    type: PlatformListItemType.Current,
  },
  {
    icon: <SvgStripeFilled className="h-6" />,
    name: "Stripe",
    slug: "stripe",
    type: PlatformListItemType.Current,
  },
];

const UPCOMING_PLATFORMS: PlatformListItem[] = [
  {
    icon: <SvgCloseFilled className="h-6" />,
    name: "Close",
    slug: "close",
    type: PlatformListItemType.Upcoming,
  },
  {
    icon: <SvgGoogleAnalyticsFilled className="h-6" />,
    name: "Google Analytics",
    slug: "google_analytics",
    type: PlatformListItemType.Upcoming,
  },
  {
    icon: <SvgAuthZeroFilled className="h-6" />,
    name: "Auth0",
    slug: "auth0",
    type: PlatformListItemType.Upcoming,
  },
  {
    icon: <SvgBambooHRFilled className="h-6" />,
    name: "BambooHR",
    slug: "bamboo_hr",
    type: PlatformListItemType.Upcoming,
  },
  {
    icon: <SvgFacebookFilled className="h-6" />,
    name: "Facebook Ads",
    slug: "facebook",
    type: PlatformListItemType.Upcoming,
  },
  {
    icon: <SvgJiraFilled className="h-6" />,
    name: "Jira",
    slug: "jira",
    type: PlatformListItemType.Upcoming,
  },
  {
    icon: <SvgAwsLambdaFilled className="h-6" />,
    name: "AWS Lambda",
    slug: "lambda",
    type: PlatformListItemType.Upcoming,
  },
  {
    icon: <SvgMarketoFilled className="h-6" />,
    name: "Marketo",
    slug: "marketo",
    type: PlatformListItemType.Upcoming,
  },
  {
    icon: <SvgNetSuiteFilled className="h-6" />,
    name: "NetSuite",
    slug: "netsuite",
    type: PlatformListItemType.Upcoming,
  },
  {
    icon: <SvgNotionFilled className="h-6" />,
    name: "Notion",
    slug: "notion",
    type: PlatformListItemType.Upcoming,
  },
  {
    icon: <SvgPagerDutyFilled className="h-6" />,
    name: "PagerDuty",
    slug: "pagerduty",
    type: PlatformListItemType.Upcoming,
  },
  {
    icon: <SvgPipedriveFilled className="h-6" />,
    name: "Pipedrive",
    slug: "pipedrive",
    type: PlatformListItemType.Upcoming,
  },
  {
    icon: <SvgQuickbooksFilled className="h-6" />,
    name: "Quickbooks Online",
    slug: "quickbooks",
    type: PlatformListItemType.Upcoming,
  },
  {
    icon: <SvgShopifyFilled className="h-6" />,
    name: "Shopify",
    slug: "shopify",
    type: PlatformListItemType.Upcoming,
  },
  {
    icon: <SvgSquareUpFilled className="h-6" />,
    name: "Square",
    slug: "square",
    type: PlatformListItemType.Upcoming,
  },
  {
    icon: <SvgTwilioFilled className="h-6" />,
    name: "Twilio",
    slug: "twilio",
    type: PlatformListItemType.Upcoming,
  },
  {
    icon: <SvgTypeFormFilled className="h-6" />,
    name: "Typeform",
    slug: "typeform",
    type: PlatformListItemType.Upcoming,
  },
  {
    icon: <SvgXeroFilled className="h-6" />,
    name: "Xero",
    slug: "xero",
    type: PlatformListItemType.Upcoming,
  },
  {
    icon: <SvgZohoFilled className="h-6" />,
    name: "Zoho CRM",
    slug: "zoho",
    type: PlatformListItemType.Upcoming,
  },
  {
    icon: <SvgZendeskFilled className="h-6" />,
    name: "Zendesk",
    slug: "zendesk",
    type: PlatformListItemType.Upcoming,
  },
];

export const PlatformList = ({
  buildPlatformLink,
  siteLocation,
  doubleColumn,
}: {
  buildPlatformLink: (kind: string) => string;
  showUpcomingPlatforms?: boolean;
  siteLocation: string;
  doubleColumn?: boolean;
}) => {
  const [query, setQuery] = useState("");
  const debouncedQuery = useDebounce(query, 500);

  // Trick to always display an even number of platforms
  const displayLimit =
    2 * Math.ceil((CURRENT_PLATFORMS.length + UPCOMING_PLATFORMS.length) / 2) +
    2;

  const [platforms] = useCombinedFilteredPlatforms(
    debouncedQuery,
    displayLimit
  );

  const topSearchBar = useRef<HTMLInputElement>();

  return (
    <div className="w-full">
      <div className="flex flex-row bg-white border border-gray-200 focus-within:ring-2 rounded ring-gray-400 focus-within:border-black flex-1">
        <div className="w-12 h-10 flex items-center justify-center">
          <SearchIcon className="h-5 w-5" />
        </div>
        <input
          type="text"
          className="focus:outline-none rounded w-full flex-1"
          placeholder="Search integrations..."
          name="token"
          value={query}
          onChange={(ev) => setQuery(ev.target.value)}
          ref={topSearchBar}
        />
      </div>
      {platforms && query === debouncedQuery ? (
        <div className="w-full mt-6">
          <div className="w-full relative">
            <div
              className={`grid ${
                doubleColumn ? "grid-cols-2" : "grid-cols-1"
              } w-full gap-4`}
            >
              {platforms.map((p) => {
                if (p.type === PlatformListItemType.Current) {
                  return (
                    <PlatformAnchor
                      href={buildPlatformLink(p.slug)}
                      name={p.slug}
                      siteLocation={siteLocation}
                      key={p.slug}
                    >
                      <div className="h-8 w-8 flex justify-center items-center">
                        {p.icon}
                      </div>
                      <span className="ml-6 font-bold">{p.name}</span>
                    </PlatformAnchor>
                  );
                } else {
                  return (
                    <PlatformComing
                      icon={p.icon}
                      name={p.name}
                      slug={p.slug}
                      siteLocation={siteLocation}
                      isComingSoon={p.type === PlatformListItemType.Upcoming}
                      key={p.slug}
                    />
                  );
                }
              })}
            </div>
            {!query && (
              <div className="absolute h-24 bottom-0 w-full bg-gradient-to-b from-transparent to-white" />
            )}
          </div>
          {!query && (
            <div className="text-center px-4 my-6 flex flex-col items-center">
              <h3 className="text-xl font-bold">Don't see your integration?</h3>
              <div className="flex flex-row bg-white border border-gray-200 focus-within:ring-2 rounded ring-gray-400 focus-within:border-black mt-4 w-96">
                <div className="w-12 h-10 flex items-center justify-center">
                  <SearchIcon className="h-5 w-5" />
                </div>
                <input
                  type="text"
                  className="focus:outline-none rounded w-full flex-1"
                  placeholder="Search upcoming integrations..."
                  name="token"
                  onChange={(ev) => {
                    setQuery(ev.target.value);
                    topSearchBar.current.focus();
                    topSearchBar.current.scrollIntoView();
                  }}
                />
              </div>
            </div>
          )}
        </div>
      ) : (
        <PageLoadingSpinner />
      )}
    </div>
  );
};

const flaggedPlannedPlatforms = (platformFlags: object): PlatformListItem[] => {
  const [flaggedCurrent, upcoming] = partition(
    UPCOMING_PLATFORMS,
    (platform: PlatformListItem) => platformFlags[platform.slug]
  );
  const markedCurrent = flaggedCurrent.map((platform: PlatformListItem) => {
    return { ...platform, type: PlatformListItemType.Current };
  });

  return CURRENT_PLATFORMS.concat(markedCurrent, upcoming);
};

const useCombinedFilteredPlatforms = (query: string, limit: number) => {
  const [suggestedPlatforms, , error] = useSearchablePlatforms();

  const trimmedQuery = query.toLowerCase().replaceAll(" ", "");
  const passFilter = (x: string) =>
    x.toLowerCase().replaceAll(" ", "").includes(trimmedQuery);

  const platforms = useMemo(() => {
    if (!suggestedPlatforms) {
      return undefined;
    }

    const plannedPlatforms = flaggedPlannedPlatforms({});
    const filteredPlannedPlatforms = limitedFilter(
      plannedPlatforms,
      (p) => passFilter(p.name),
      limit
    );

    const filteredSuggestedPlatforms = limitedFilter(
      suggestedPlatforms,
      passFilter,
      limit - filteredPlannedPlatforms.length
    ).map((p) => ({
      name: p,
      slug: p,
      type: PlatformListItemType.Suggested,
    }));

    return filteredPlannedPlatforms.concat(filteredSuggestedPlatforms);
  }, [CURRENT_PLATFORMS, UPCOMING_PLATFORMS, suggestedPlatforms, query, limit]);

  return [platforms, error] as [PlatformListItem[] | undefined, HookError];
};

const PlatformAnchor = ({
  children,
  href,
  name,
  siteLocation,
}: React.PropsWithChildren<{
  href: string;
  name: string;
  siteLocation: string;
}>) => {
  const [user] = useProfile();

  return (
    <Link to={href}>
      <span
        className="transition-all flex flex-row h-24 px-8 border rounded w-full items-center border-black text-black hover:text-white hover:bg-black dark:border-white dark:text-white dark:hover:text-black dark:hover:bg-white"
        onClick={() => {
          if (window && window.analytics) {
            window.analytics.track("Source Selected", {
              name,
              siteLocation,
              email: user?.email,
            });

            putSourceInterestTrait(name);
          }
        }}
      >
        {children}
      </span>
    </Link>
  );
};

enum UpvoteState {
  None,
  Sending,
  Sent,
}

function PlatformComing({
  icon,
  name,
  slug,
  siteLocation,
  isComingSoon,
}: {
  icon?: React.ReactNode;
  name: string;
  slug: string;
  siteLocation: string;
  isComingSoon?: boolean;
}) {
  const [upvoteState, setUpvoteState] = useState<UpvoteState>(UpvoteState.None);
  const [user] = useProfile();

  const isSending = upvoteState === UpvoteState.Sending;
  const isSent = upvoteState === UpvoteState.Sent;

  const handleNotify = async () => {
    setUpvoteState(UpvoteState.Sending);
    await sendUpvote(user ? user.email : "", slug);
    setUpvoteState(UpvoteState.Sent);

    if (window && window.analytics) {
      window.analytics.track("Sources Upvote Clicked", {
        name,
        siteLocation,
      });

      putSourceInterestTrait(name);
    }
  };

  return (
    <div className="transition-all flex flex-row h-24 px-8 border rounded w-full items-center border-opacity-40 dark:border-opacity-40 border-black text-black text-opacity-40 dark:text-opacity-40 dark:border-white dark:text-white cursor-not-allowed">
      <div className="h-8 w-8 flex justify-center items-center">
        {icon || <PuzzleIcon className="h-6 w-6" />}
      </div>
      <span className="flex-1 flex flex-col">
        {isComingSoon && (
          <span className="ml-6 text-xxs text-left">Coming soon</span>
        )}
        <span className="ml-6 font-bold flex-1 text-left">{name}</span>
      </span>
      {isSent ? (
        <span className=" font-bold text-xs  flex flex-row items-center text-black dark:text-white">
          <CheckIcon className="h-5 mr-2" /> Requested
        </span>
      ) : (
        <Button
          onClick={handleNotify}
          isLoading={isSending}
          iconLeft={<BellIcon className="h-4" />}
          size="sm"
          variant="primary"
        >
          Request access
        </Button>
      )}
    </div>
  );
}

const putSourceInterestTrait = (source: string) => {
  window.analytics.ready(() => {
    const traits = window.analytics.user().traits() as any;

    const newTraits = {
      ...traits,
      sourceInterests: traits.sourceInterests
        ? [...traits.sourceInterests, source]
        : [source],
    };

    window.analytics.user().traits(newTraits);
  });
};

// Todo: move this further back in the stack so we don't need  to manage this on the frontend
const onboardPlatforms = [
  {
    displayName: "Airtable",
    kind: AIRTABLE_PLATFORM.kind,
    logo: <SvgAirtableColored className="h-6 mr-2" />,
  },
  {
    displayName: "Auth0",
    kind: "auth0",
    logo: <SvgAuthZeroFilled className="h-6 mr-2" color="#EB5323" />,
  },
  {
    displayName: "Github",
    kind: GITHUB_PLATFORM.kind,
    logo: <SvgGithubFilled className="h-6 mr-2" />,
  },
  {
    displayName: "Hubspot",
    kind: HUBSPOT_PLATFORM.kind,
    logo: <SvgHubspotFilled className="h-6 mr-2" color="#F8761F" />,
  },
  {
    displayName: "Linear",
    kind: "linear",
    logo: <SvgLinearFilled className="h-6 mr-2" color="#5E6AD2" />,
  },
  {
    displayName: "Marketo",
    kind: "marketo",
    logo: <SvgMarketoFilled className="h-6 mr-2" color="#5C4C9F" />,
  },
  {
    displayName: "NetSuite",
    kind: "netsuite",
    logo: <SvgNetSuiteFilled className="h-6 mr-2" color="#0082CA" />,
  },
  {
    displayName: "Quickbooks",
    kind: "quickbooks",
    logo: <SvgQuickbooksFilled className="h-6 mr-2" color="#01A200" />,
  },
  {
    displayName: "Salesforce",
    kind: SALESFORCE_PLATFORM.kind,
    logo: (
      <SvgSalesforceColored
        fillColor="#00A1E0"
        textColor="#FFFFFF"
        className="h-6 mr-2"
      />
    ),
  },
  {
    displayName: "SendGrid",
    kind: "sendgrid",
    logo: <SvgSendGridFilled className="h-6 mr-2" color="#1A82E2" />,
  },
  {
    displayName: "ServiceNow",
    kind: "servicenow",
    logo: <SvgServiceNowFilled className="h-6 mr-2" color="#81b5a1" />,
  },
  {
    displayName: "Shopify",
    kind: "shopify",
    logo: <SvgShopifyFilled className="h-6 mr-2" color="#96BF47" />,
  },
  {
    displayName: "Stripe",
    kind: STRIPE_PLATFORM.kind,
    logo: <SvgStripeFilled className="h-6 mr-2" color="#635aff" />,
  },
  {
    displayName: "Workday",
    kind: "workday",
    logo: <SvgWorkdayColored className="h-6 mr-2" />,
  },
  {
    displayName: "ZenDesk",
    kind: "zendesk",
    logo: <SvgZendeskFilled className="h-6 mr-2" color="#00343B" />,
  },
];

interface OnboardPlatformListProps {
  setPlatform: (platform: ResourceKind | string) => void;
}

export const OnboardPlatformList = ({
  setPlatform,
}: OnboardPlatformListProps) => {
  return (
    <div className="grid grid-cols-3 grid-flow gap-4 justify-evenly">
      {onboardPlatforms.map((platform) => {
        return (
          <button
            onClick={() => {
              setPlatform(platform.kind);
            }}
            className="flex justify-center relative py-6 px-10 w-5/6 rounded-md bg-cool-gray-100"
            key={platform.kind}
          >
            {platform.logo}
            <span className="font-bold">{platform.displayName}</span>
          </button>
        );
      })}
    </div>
  );
};
