import * as React from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}

function SvgComponent({
  title,
  titleId,
  ...props
}: React.SVGProps<SVGSVGElement> & SVGRProps) {
  return (
    <svg viewBox="0 0 24 24" fill="none" aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24 12c0 6.627-5.373 12-12 12S0 18.627 0 12 5.373 0 12 0s12 5.373 12 12ZM4.7 15.3A4.666 4.666 0 0 1 8 7.333h3.333v12.534A1.734 1.734 0 0 1 9.6 18.133V9.067H8a2.933 2.933 0 1 0 0 5.866h.667v1.734H8A4.667 4.667 0 0 1 4.7 15.3Zm10.633-7.967H16a4.667 4.667 0 0 1 0 9.334h-3.333V4.133A1.734 1.734 0 0 1 14.4 5.867v9.066H16a2.933 2.933 0 1 0 0-5.866h-.667V7.333Z"
        fill="currentColor"
      />
    </svg>
  );
}

const SvgQuickbooksFilled = React.memo(SvgComponent);
export default SvgQuickbooksFilled;
