import React, { useState } from "react";
import { Popover } from "@headlessui/react";
import {
  DatabaseIcon,
  PencilIcon,
  PlusIcon,
  TrashIcon,
} from "@heroicons/react/outline";
import Head from "next/head";
import { useRef } from "react";
import { toast } from "react-toastify";
import { match } from "ts-pattern";
import { Outlet, Link } from "react-router-dom";

import { deleteDatabase } from "lib/api";
import { useDatabases } from "lib/api/hooks";
import { Button } from "lib/lucidez";
import { DatabaseWithResources } from "lib/platforms";
import { useWorkable } from "lib/utils";
import { PageLoadingSpinner } from "components/core/PageLoadingSpinner";
import SvgSpinner from "components/svg/Spinner";
import HoverPopover from "components/core/HoverPopover";

interface DatabaseCardProps {
  database: DatabaseWithResources;
  refreshDatabases: () => void;
}

const readableErrors = {
  has_resources:
    "Database can't be deleted because it's a destination for one or more syncs.",
};

function DatabaseCard({ database, refreshDatabases }: DatabaseCardProps) {
  const deleteBtnRef = useRef<any>();
  const [isDeleting, workableDeletion] = useWorkable();

  const isSequinHosted = database.definition.type === "shared_postgres";
  const dbType = match(database.definition.type)
    .with("dedicated_postgres", () => "Self-hosted")
    .with("shared_postgres", () => "Sequin-hosted")
    .otherwise(() => "Unknown");

  const closeDeletePopover = () => {
    deleteBtnRef.current?.click();
  };

  const handleDelete = async () => {
    if (!database) {
      return;
    }
    const response = await workableDeletion(() => deleteDatabase(database.id));
    if (response.isOk()) {
      toast.success("Successfully deleted database", {
        toastId: "database-delete-success",
      });
      refreshDatabases();
      closeDeletePopover();
    } else {
      const errorMessage =
        readableErrors[response.error.error?.slug] ||
        "Unknown error while deleting database";
      toast.error(errorMessage, { toastId: "database-delete-error" });
    }
  };

  const syncsUsingThisDatabaseList =
    database.resources.length > 0 ? (
      <div className="w-48">
        <span className="font-medium">Syncs using this database:</span>
        <div className="mt-2 flex flex-col gap-2">
          {database.resources.map((resource) => (
            <Link
              to={`/syncs/${resource.permaslug}/collections`}
              key={resource.id}
              className="border border-gray-50 bg-gray-50 p-2 rounded flex flex-row hover:border-gray-200"
            >
              <span className="flex-1">{resource.name}</span>
            </Link>
          ))}
        </div>
      </div>
    ) : (
      <div>No syncs are using this database.</div>
    );

  return (
    <>
      <div className="p-7 gap-2 bg-white shadow-md rounded flex justify-between items-center flex-col sm:flex-row">
        <div className="grid grid-cols-2-auto min-w-full gap-8 items-center justify-start md:grid-cols-4-auto sm:min-w-0">
          <div className="p-3 rounded-full border border-dashed border-gray-500 max-w-fit">
            <DatabaseIcon className="h-6" />
          </div>
          <div className="w-40 flex flex-col">
            <span className="text-base font-bold">
              {database.name || (
                <i
                  className="text-gray-500"
                  title="Unable to get the database name"
                >
                  No name
                </i>
              )}
            </span>
            <span className="text-sm text-gray-500 ph-no-capture">
              {database.definition.dbname}
            </span>
          </div>
          <HoverPopover
            content={syncsUsingThisDatabaseList}
            containerProps={{
              placement: "bottom-end",
            }}
          >
            <div className="w-12 flex flex-col">
              <span className="text-base font-bold">
                {database.resources.length}
              </span>
              <span className="text-sm text-gray-500">Syncs</span>
            </div>
          </HoverPopover>
          <div className="flex flex-col">
            <span className="text-base font-bold ph-no-capture">
              {isSequinHosted ? dbType : database.definition.host}
            </span>
            {!isSequinHosted && (
              <span className="text-sm text-gray-500">{dbType}</span>
            )}
          </div>
        </div>
        <div className="flex gap-2 flex-row sm:flex-col md:flex-row">
          <Link to={`/databases/${database.id}/edit`}>
            <button
              className={`transition-all border p-3 flex justify-center
              items-center rounded-full border-gray-200 hover:border-black
            dark:border-gray-600 dark:hover:border-white`}
            >
              <PencilIcon className="h-4" />
            </button>
          </Link>
          <Popover className="relative">
            <Popover.Button
              className="transition-all border p-3 flex justify-center items-center rounded-full border-gray-200 hover:border-black dark:border-gray-600 dark:hover:border-white"
              ref={deleteBtnRef}
              disabled={isDeleting}
            >
              {isDeleting ? (
                <SvgSpinner className="h-4 animate-spin" />
              ) : (
                <TrashIcon className="h-4" />
              )}
            </Popover.Button>

            <Popover.Panel className="absolute z-10 bottom-full border border-gray-200 bg-white p-4 shadow-lg rounded left-1/2 transform -translate-x-1/2 w-64">
              <p className="text-xs">
                Are you sure you want to delete this Database?
              </p>
              <div className="flex flex-row mt-4">
                <div className="flex-1" />
                <Button
                  variant="outlined"
                  size="sm"
                  onClick={handleDelete}
                  isLoading={isDeleting}
                >
                  Yes
                </Button>
                <Button
                  variant="primary"
                  size="sm"
                  onClick={closeDeletePopover}
                  className="ml-2"
                >
                  No
                </Button>
              </div>
            </Popover.Panel>
          </Popover>
        </div>
      </div>
    </>
  );
}

export default function Databases() {
  const [databases, refreshDatabases] = useDatabases();
  const filteredDatabases = databases?.filter(
    (x) => x.status !== "pending_removal"
  );

  return (
    <>
      <Head>
        <title>Databases - Sequin</title>
      </Head>
      <main className="pb-12">
        <PageHeader />

        <div className="custom-container mt-12">
          <div className="flex flex-col gap-3">
            {databases ? (
              databases.length > 0 ? (
                filteredDatabases.map((database) => (
                  <DatabaseCard
                    key={database.id}
                    database={database}
                    refreshDatabases={refreshDatabases}
                  />
                ))
              ) : (
                <div className="text-center">
                  You don't have any destination databases. You can add one by{" "}
                  <Link to="/syncs/new">adding a new sync.</Link>
                </div>
              )
            ) : (
              <PageLoadingSpinner />
            )}
            <Outlet />
          </div>
        </div>
      </main>
    </>
  );
}

const PageHeader = () => {
  const [isShowingAddKeyNotice, setIsShowingAddKeyNotice] = useState(false);

  return (
    <div className="w-full pt-8 min-h-[40px]">
      <div className="custom-container">
        <div className="flex flex-row items-center justify-between gap-24 min-h-[40px]">
          <h1 className="flex-1 font-bold text-2xl ml-2">Databases</h1>
          <Button
            variant="primary"
            iconLeft={<PlusIcon className="w-4 h-4" />}
            onClick={() => setIsShowingAddKeyNotice((show) => !show)}
          >
            Add Database
          </Button>
        </div>
        {isShowingAddKeyNotice && (
          <>
            <div className="relative mt-4">
              <svg
                viewBox="0 0 24 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="h-3 w-6 text-gray-200 rotate-180 m-px absolute -translate-y-full right-[46px]"
              >
                <rect width="24" height="1.5" fill="white" />
                <path
                  d="M 24 1 C 18 1 18 10 12 10 C 6 10 6 1 0 1"
                  stroke="rgba(229, 229, 229)"
                  fill="white"
                  strokeWidth="1"
                  strokeLinejoin="round"
                />
              </svg>
            </div>
            <div className="border py-4 pl-6 rounded flex flex-row items-stretch bg-white shadow-sm">
              <div className="flex flex-1 flex-col justify-center h-48">
                <h2 className="text-lg font-bold">Add new database</h2>
                <p className="mt-2">
                  You can connect a new database by{" "}
                  <Link to="/" className="link">
                    editing
                  </Link>{" "}
                  or{" "}
                  <Link to="/syncs/new" className="link">
                    creating a new sync
                  </Link>{" "}
                  from the Syncs list.
                </p>
              </div>
              <div>
                <img
                  src="/static/img/add-database-banner.png"
                  className="h-48 rounded-lg"
                />
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};
