import React from "react";
import Head from "next/head";
import classNames from "classnames";

import { getUrl } from "lib/api";
import { useLatestBillingPlan, useOrg } from "lib/api/hooks";
import { Button } from "lib/lucidez";
import { capitalize } from "lib/utils/formatting";
import { PageLoadingSpinner } from "components/core/PageLoadingSpinner";
import { MAILTO_LINK } from "site-constants";

const marFormatter = new Intl.NumberFormat();
const currencyFormatter = new Intl.NumberFormat("en-US", {
  currency: "USD",
  maximumFractionDigits: 0,
  style: "currency",
});

const goToCheckout = (priceId) => {
  window.location.href = getUrl(`/api/checkout?price_id=${priceId}`);
};

const contactUs = () => {
  window.location.href = MAILTO_LINK;
};

export const Checkout = () => {
  const [org] = useOrg();
  const [latestBillingPlan] = useLatestBillingPlan();

  if (!org || !latestBillingPlan) {
    return <PageLoadingSpinner />;
  }

  const marDisplay = org?.usages?.monthlyActiveRows ? (
    <p>
      Your current Monthly Active Rows:{" "}
      <b>{marFormatter.format(org.usages.monthlyActiveRows)}</b>
    </p>
  ) : (
    <p>You haven't written any data this month.</p>
  );

  return (
    <>
      <Head>
        <title>Checkout</title>
      </Head>

      <main className="flex flex-col flex-1 w-full mt-6 custom-container">
        <h1 className="font-bold text-3xl mb-4">Checkout</h1>
        {marDisplay}
        <div className="grid grid-cols-4 gap-6 my-10 w-5/6">
          {latestBillingPlan?.definition?.pricing?.map((plan) => {
            let formattedMar = marFormatter.format(plan.included_mar);
            let formattedPrice = currencyFormatter.format(plan.price / 100);
            let isDisabled =
              org?.usages?.monthlyActiveRows >= plan.included_mar;
            let callToActionText = "Purchase";
            let handleClick = () => {
              goToCheckout(plan.stripe_price_id);
            };

            if (plan.tier === "business") {
              formattedMar = "Tailored";
              formattedPrice = "Tailored";
              isDisabled = false;
              callToActionText = "Contact us";
              handleClick = contactUs;
            }

            return (
              <div
                key={plan.tier}
                className={classNames(
                  "bg-white rounded-md border border-gray-200 py-4 px-2 text-center",
                  {
                    "pointer-events-none cursor-not-allowed bg-gray-100 text-gray-400":
                      isDisabled,
                  }
                )}
              >
                <h2 className="font-bold text-lg mb-4">
                  {capitalize(plan.tier)}
                </h2>
                <h3 className="mb-4">Included MAR: {formattedMar}</h3>
                <h4 className="font-bold text-2xl mb-6">{formattedPrice}</h4>
                <Button
                  onClick={handleClick}
                  variant="primary"
                  className={classNames({
                    "pointer-events-none cursor-not-allowed bg-gray-300":
                      isDisabled,
                  })}
                >
                  {callToActionText}
                </Button>
              </div>
            );
          })}
        </div>
      </main>
    </>
  );
};
