import React, { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { mutate } from "swr";
import { toast } from "react-toastify";
import { XCircleIcon } from "@heroicons/react/solid";

import { Resource, getPlatformForKind } from "lib/platforms";
import { updateResource, deleteResource } from "lib/api";
import { useResourceBySlug } from "lib/api/hooks";
import { Button } from "lib/lucidez/components/Button";
import ResourceForm, {
  ResourceProvider,
} from "components/platforms/common/ResourceForm";
import { SyncAllQuirkProvider } from "components/platforms/common/forms/SelectTablesStep";
import { PageLoadingSpinner } from "components/core/PageLoadingSpinner";
import Modal from "components/core/Modal";
import SvgSpinner from "components/svg/Spinner";
import { ConfirmPendingChanges } from "lib/utils/ConfirmPendingChanges";

export const SyncPageSettings = () => {
  const { permaslug } = useParams();
  const [resource] = useResourceBySlug(permaslug as string, {
    waitUntilLoaded: true,
  });

  if (!resource) {
    return <PageLoadingSpinner />;
  }
  return <SettingsForm resource={resource} />;
};

interface SettingsFormProps {
  resource: Resource;
}

const SettingsForm = ({ resource }: SettingsFormProps) => {
  const platform = getPlatformForKind(resource.kind);

  const formMethods = useForm<any>({
    defaultValues: platform.getInitialUpdateState(resource),
    mode: "all",
  });

  const hasPendingChanges = formMethods.formState.isDirty;

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [shouldShowDeleteConfirmation, setShouldShowDeleteConfirmation] =
    useState(false);
  const navigate = useNavigate();

  const [{ showFatalError, fatalErrorMessage }, setFatalError] = useState({
    showFatalError: false,
    fatalErrorMessage: null,
  });

  const handleClose = () => {
    setShouldShowDeleteConfirmation(false);
  };

  const handleCloseErrorModal = () => {
    setFatalError({
      showFatalError: false,
      fatalErrorMessage,
    });
  };

  const handleDelete = async () => {
    setIsSubmitting(true);
    await deleteResource(resource.id);
    await mutate("/api/resources");

    navigate("/", {
      state: {
        shouldShowToast: true,
        toastMessage: `${resource.name} has been deleted`,
      },
    });
  };

  const dirtyFieldsCount = Object.keys(
    formMethods.formState.dirtyFields
  ).length;

  const isValid = formMethods.formState.isValid;
  const hasErrors = Object.keys(formMethods.formState.errors).length > 0;

  const isSaveButtonDisabled =
    !hasPendingChanges || isSubmitting || hasErrors || !isValid;

  const onSubmit = async (data) => {
    setIsSubmitting(true);

    const forApi = platform.prepareFieldsForUpdate(data);
    const response = await updateResource(resource.id, forApi);

    if (response.isOk()) {
      mutate("/api/resources");

      formMethods.reset({}, { keepValues: true });
      toast.success("Your changes have been saved.", {
        toastId: "sync-settings-changes-saved",
      });
    } else if (JSON.stringify(response).includes("is banned")) {
      setFatalError({
        showFatalError: true,
        fatalErrorMessage:
          "This Airtable account has been banned from accessing Sequin. If you think this was an error, please contact us.",
      });
    } else if (response.error.error?.summary) {
      setFatalError({
        showFatalError: true,
        fatalErrorMessage: response.error.error.summary,
      });
    } else {
      // handle server errors not related to validation
      setFatalError({
        showFatalError: true,
        fatalErrorMessage: "An unknown error has occurred.",
      });
    }

    setIsSubmitting(false);
  };

  return (
    <section className="py-12 flex-1">
      <div className="custom-container">
        <ResourceProvider resource={resource}>
          <SyncAllQuirkProvider
            allowSyncAll={resource.schema.syncAllTables === true}
          >
            <FormProvider {...formMethods}>
              <form
                className="relative h-full flex flex-row w-full justify-between"
                onSubmit={(event) => {
                  event.preventDefault();
                }}
              >
                <div className="w-2/3">
                  <h2 className="text-2xl font-bold mb-6">Settings</h2>
                  <ResourceForm kind={platform.kind} isFullPage={true} />
                  <hr className="my-8" />
                  <div>
                    <h3 className="text-2xl font-bold mt-8 mb-6">
                      Delete sync
                    </h3>
                    <Button
                      onClick={() => setShouldShowDeleteConfirmation(true)}
                      variant="tertiary"
                      className="w-[45%] justify-center"
                    >
                      Delete sync
                    </Button>
                  </div>
                </div>
                <div className="border-l mt-12 ml-8 pt-2 pl-8 w-1/4">
                  <Button
                    variant="primary"
                    onClick={() => {
                      formMethods.handleSubmit(onSubmit)();
                    }}
                    disabled={isSaveButtonDisabled}
                    className="w-full justify-center"
                    size="lg"
                  >
                    <span>
                      Save changes{" "}
                      {hasPendingChanges && (
                        <span className="ml-1 font-normal text-gray-400">
                          ({dirtyFieldsCount}
                          {<>&nbsp;</>}
                          {dirtyFieldsCount === 1 ? "change" : "changes"})
                        </span>
                      )}
                    </span>
                  </Button>
                  <Button
                    onClick={() => {
                      formMethods.reset();
                    }}
                    className="mt-3 w-full justify-center"
                    size="lg"
                  >
                    Cancel
                  </Button>
                </div>
              </form>
            </FormProvider>
          </SyncAllQuirkProvider>
        </ResourceProvider>
        {shouldShowDeleteConfirmation && (
          <DeleteConfirmationModal
            onClose={handleClose}
            onDelete={handleDelete}
            resourceName={resource.name}
            isSubmitting={isSubmitting}
          />
        )}
        {showFatalError && (
          <ErrorModal
            onClose={handleCloseErrorModal}
            message={fatalErrorMessage}
          />
        )}
        <ConfirmPendingChanges hasPendingChanges={hasPendingChanges} />
      </div>
    </section>
  );
};

const DeleteConfirmationModal = ({
  onClose,
  resourceName,
  onDelete,
  isSubmitting,
}: {
  onClose: () => void;
  resourceName: string;
  onDelete: () => void;
  isSubmitting: boolean;
}) => (
  <Modal
    onClose={onClose}
    containerClassName="w-3/4 custom-container py-32 lg:px-16 max-w-2xl"
  >
    <div className="p-8">
      <div className="flex justify-end">
        <button onClick={onClose}>
          <XCircleIcon className="h-5 w-5" aria-hidden="true" />
        </button>
      </div>
      <div className="flex flex-col justify-center text-center">
        <h3 className="text-xl font-bold w-full mb-6">
          Are you sure you want to delete this sync?
        </h3>
        <p className="mb-6">
          <strong>{resourceName}</strong> will be deleted. You can't undo this.
        </p>
        <div className="flex justify-center text-center mb-4">
          <Button
            id="confirm-delete-btn"
            variant="primary"
            onClick={onDelete}
            className="w-2/5 justify-center bg-gray-900"
            disabled={isSubmitting}
            iconLeft={
              isSubmitting ? (
                <SvgSpinner className="h-4 w-4 mr-2 animate-spin" />
              ) : null
            }
          >
            Delete sync
          </Button>
        </div>
        <a
          onClick={onClose}
          className="font-bold text-gray-500 cursor-pointer m-auto"
        >
          No, take me back
        </a>
      </div>
    </div>
  </Modal>
);

const ErrorModal = ({
  onClose,
  message,
}: {
  onClose: () => void;
  message: string;
}) => (
  <Modal
    onClose={onClose}
    containerClassName="w-3/4 custom-container py-32 lg:px-16 max-w-2xl"
  >
    <div className="p-8">
      <div className="flex justify-end">
        <button onClick={onClose}>
          <XCircleIcon className="h-5 w-5" aria-hidden="true" />
        </button>
      </div>
      <div className="flex flex-col justify-center text-center">
        <h3 className="text-xl font-bold w-full mb-6">
          Some of your changes could not be saved
        </h3>
        <p className="mb-6">{message}</p>
        <div className="flex justify-center text-center mb-4">
          <Button
            id="confirm-delete-btn"
            variant="primary"
            onClick={onClose}
            className="w-2/5 justify-center bg-gray-900"
          >
            Continue
          </Button>
        </div>
      </div>
    </div>
  </Modal>
);
