import React from "react";
import { useParams } from "react-router-dom";
import groupBy from "lodash/groupBy";

import {
  useResourceBackfills,
  useResourceBySlug,
  useResourceCollectionStatuses,
  useResourceTablesSyncing,
} from "lib/api/hooks";
import { Collections } from "components/resources/jobs/FullPageJobStatus";
import { PageLoadingSpinner } from "components/core/PageLoadingSpinner";

export const SyncPageCollections = () => {
  const { permaslug } = useParams();

  const [resource] = useResourceBySlug(permaslug as string, {
    waitUntilLoaded: true,
  });

  const [backfills] = useResourceBackfills(resource?.id, {
    refreshInterval: 5000,
    revalidateOnMount: true,
    initialData: null,
  });

  const [tables] = useResourceTablesSyncing(resource?.id);

  const [collectionStatuses] = useResourceCollectionStatuses(resource?.id, {
    refreshInterval: 5000,
    revalidateOnMount: true,
    initialData: null,
  });

  if (!resource || !collectionStatuses) {
    return <PageLoadingSpinner />;
  }

  const groupedJobs = groupBy(collectionStatuses, "jobKind");
  const deltaJobsCollectionStatuses = groupedJobs.delta ?? [];

  return (
    <section className="pt-6 pb-12 bg-gray-100 flex-1">
      <div className="custom-container">
        <Collections
          backfills={backfills}
          collectionStatuses={deltaJobsCollectionStatuses}
          isResourceDisabled={!resource.active}
          tables={tables}
        />
      </div>
    </section>
  );
};
