import React from "react";
import { match } from "ts-pattern";
import {
  CheckCircleIcon,
  DotsCircleHorizontalIcon,
  ExclamationCircleIcon,
  XCircleIcon,
} from "@heroicons/react/outline";

import { ResourceStatus } from "lib/api/types";
import HoverPopover from "components/core/HoverPopover";

interface ResourceStatusBadgeProps {
  isActive: boolean;
  isFirstBackfillRunning?: boolean;
  resourceStatus: ResourceStatus;
}

export const SyncPageResourceStatusBadge = ({
  isActive,
  isFirstBackfillRunning,
  resourceStatus,
}: ResourceStatusBadgeProps) => {
  if (!isActive || resourceStatus === null) {
    return (
      <div className="inline-flex items-center text-xxs max-w-fit">
        <div className="flex items-center relative">
          <span className="h-4 w-4 rounded-full inline-block border" />
          <span className={`text-xs ml-1 text-gray-400`}>Disabled</span>
        </div>
      </div>
    );
  }

  return (
    <div className="inline-flex items-center text-xxs max-w-fit">
      <SyncPageFriendlyStatusRenderer
        level={isFirstBackfillRunning ? "backfilling" : resourceStatus.level}
      />
    </div>
  );
};

const syncPageFriendlyStatusMap = {
  customer_intervention: {
    heading: "Error",
    message: "Your sync requires your attention.",
  },
  degraded: {
    heading: "Degraded",
    message: "Your sync is running slower than usual.",
  },
  healthy: {
    heading: "Healthy",
    message: "Your sync is healthy.",
  },
  sequin_intervention: {
    heading: "Error",
    message: "We've detected an error on our end. We're looking into it.",
  },
  backfilling: {
    heading: "Backfilling",
    message: "Your sync is backfilling new tables.",
  },
};

type SyncLevel = ResourceStatus["level"] | "backfilling";

const SyncPageFriendlyStatusRenderer = ({ level }: { level: SyncLevel }) => {
  const statusClassName = match(level)
    .with("backfilling", () => "text-purple-500")
    .with("healthy", () => "text-emerald-600")
    .with("degraded", () => "text-yellow-300")
    .with("customer_intervention", () => "text-red-500")
    .with("sequin_intervention", () => "text-amber-600")
    .otherwise(() => "text-success");

  const statusIcon = match(level)
    .with("backfilling", () => (
      <DotsCircleHorizontalIcon className={`h-4 w-4 ${statusClassName}`} />
    ))
    .with("healthy", () => (
      <CheckCircleIcon className={`h-4 w-4 ${statusClassName}`} />
    ))
    .with("degraded", () => (
      <ExclamationCircleIcon className={`h-4 w-4 ${statusClassName}`} />
    ))
    .with("customer_intervention", () => (
      <XCircleIcon className={`h-4 w-4 ${statusClassName}`} />
    ))
    .with("sequin_intervention", () => (
      <XCircleIcon className={`h-4 w-4 ${statusClassName}`} />
    ))
    .otherwise(() => (
      <CheckCircleIcon className={`h-4 w-4 ${statusClassName}`} />
    ));

  return (
    <HoverPopover
      content={<>{syncPageFriendlyStatusMap[level].message}</>}
      containerProps={{ placement: "bottom-start" }}
    >
      <div className="flex items-center relative">
        {statusIcon}
        <span className={`text-xs ml-1 ${statusClassName}`}>
          {syncPageFriendlyStatusMap[level].heading}
        </span>
      </div>
    </HoverPopover>
  );
};

export const SyncListResourceStatusBadge = ({
  isActive,
  isFirstBackfillRunning,
  resourceStatus,
}: ResourceStatusBadgeProps) => {
  if (!isActive || resourceStatus === null) {
    return (
      <div className="inline-flex items-center text-xxs max-w-fit border border-gray-200 rounded-full py-0.5 px-1.5">
        <div className="flex items-center relative">
          <span className="h-3 w-3 rounded-full bg-gray-300 inline-block" />
          <span className={`text-xs ml-1 text-gray-400`}>Disabled</span>
        </div>
      </div>
    );
  }

  return (
    <div className="inline-flex items-center text-xxs max-w-fit">
      <SyncListFriendlyStatusRenderer
        level={isFirstBackfillRunning ? "backfilling" : resourceStatus.level}
      />
    </div>
  );
};

const syncListFriendlyStatusMap = {
  ...syncPageFriendlyStatusMap,
};

export const SyncListFriendlyStatusRenderer = ({
  level,
}: {
  level: SyncLevel;
}) => {
  const statusClassName = match(level)
    .with("backfilling", () => "text-purple-500")
    .with("customer_intervention", () => "text-red-500")
    .with("degraded", () => "text-yellow-300")
    .with("healthy", () => "text-emerald-600")
    .with("sequin_intervention", () => "text-amber-600")
    .otherwise(() => "text-success");

  const statusIcon = match(level)
    .with("backfilling", () => (
      <DotsCircleHorizontalIcon className={`h-4 w-4 ${statusClassName}`} />
    ))
    .with("customer_intervention", () => (
      <XCircleIcon className={`h-4 w-4 ${statusClassName}`} />
    ))
    .with("degraded", () => (
      <DotsCircleHorizontalIcon className={`h-4 w-4 ${statusClassName}`} />
    ))
    .with("healthy", () => (
      <CheckCircleIcon className={`h-4 w-4 ${statusClassName}`} />
    ))
    .with("sequin_intervention", () => (
      <XCircleIcon className={`h-4 w-4 ${statusClassName}`} />
    ))
    .otherwise(() => (
      <CheckCircleIcon className={`h-4 w-4 ${statusClassName}`} />
    ));

  return (
    <HoverPopover
      content={<>{syncListFriendlyStatusMap[level].message}</>}
      containerProps={{ placement: "bottom-start" }}
    >
      <div className="flex items-center relative  border border-gray-200 rounded-full py-0.5 px-1.5">
        {statusIcon}
        <span className={`text-xs ml-1 ${statusClassName}`}>
          {syncPageFriendlyStatusMap[level].heading}
        </span>
      </div>
    </HoverPopover>
  );
};
