import * as React from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}

function SvgComponent({
  title,
  titleId,
  ...props
}: React.SVGProps<SVGSVGElement> & SVGRProps) {
  return (
    <svg viewBox="0 0 21 24" fill="none" aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        d="M14.42 0v24l6.134-4.896V3.331L14.42 0ZM11.574 18.76 6.62 20.92V2.077l4.954 1.474V18.76ZM0 18.202l3.773-.989.003-12.036L.003 4.58 0 18.202Z"
        fill="currentColor"
      />
    </svg>
  );
}

const SvgMarketoFilled = React.memo(SvgComponent);
export default SvgMarketoFilled;
