import React, { useEffect } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { LoginStatus } from "../lib/api/types";
import { useLoginStatus } from "../lib/api/utilHooks";
import { PageLoadingSpinner } from "./core/PageLoadingSpinner";

export default function RequiresLogin({
  children,
}: React.PropsWithChildren<{}>) {
  const navigate = useNavigate();

  const status = useLoginStatus();
  const location = useLocation();

  useEffect(() => {
    // Wait for status to explicitly be LoggedOut
    if (status === LoginStatus.LoggedOut) {
      const redirectPath =
        location.search !== ""
          ? `${location.pathname}${location.search}`
          : location.pathname;

      if (["/", "/login", ""].includes(redirectPath) === false) {
        navigate(`/login?redirect_path=${encodeURIComponent(redirectPath)}`, {
          replace: true,
        });
      } else {
        navigate(`/login`, { replace: true });
      }
    }
  }, [status]);

  if (status !== LoginStatus.LoggedIn) {
    return <PageLoadingSpinner />;
  } else {
    return (
      <>
        {children}
        <Outlet />
      </>
    );
  }
}
