import * as React from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}

function SvgComponent({
  title,
  titleId,
  ...props
}: React.SVGProps<SVGSVGElement> & SVGRProps) {
  return (
    <svg viewBox="0 0 38 37" fill="none" aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        d="M23.5 0H0v8.5h14.5l9 11.5V0ZM14 37h23.5v-8.5H23L14 17v20Z"
        fill="currentColor"
      />
      <path
        d="M36.5 2h-11v6.5H29v18h7.5V2ZM9 10.5H1.5V35h11v-6.5H9v-18Z"
        fill="currentColor"
      />
    </svg>
  );
}

const SvgNetSuiteFilled = React.memo(SvgComponent);
export default SvgNetSuiteFilled;
