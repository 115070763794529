import React, { useEffect } from "react";
import Head from "next/head";
import {
  Navigate,
  Outlet,
  Route,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
  useLocation,
} from "react-router-dom";
import { StateMachineProvider, createStore } from "little-state-machine";
import { wrapCreateBrowserRouter } from "@sentry/react";

import AuthLayout from "components/AuthLayout";
import CheckoutSuccess from "components/composite/CheckoutSuccess";
import ConfirmReset from "components/composite/ConfirmReset";
import Databases from "components/composite/Databases";
import EmailConfirmation from "components/composite/EmailConfirmation";
import ForgotPassword from "components/composite/ForgotPassword";
import JoinOrg from "components/composite/JoinOrg";
import Login from "components/composite/Login";
import Logout from "components/composite/Logout";
import LogoutDone from "components/composite/LogoutDone";
import RequiresLogin from "components/RequiresLogin";
import Signup from "components/composite/Signup";
import UserInvite from "components/composite/UserInvite";
import Users from "components/composite/Users";
import WritesBetaConfirmation from "components/WritesBetaConfirmation";
import { Credentials } from "components/composite/Credentials";
import { CommonChrome, CommonLayout, FullPageLayout } from "components/Layouts";
import {
  Connect,
  Select,
  Write,
  Splash,
} from "components/onboard/ConnectAndQuery";
import { BetaPlatform } from "components/onboard/BetaPlatform";
import { Checkout } from "components/composite/Checkout";
import { EditDatabase } from "components/composite/EditDatabase";
import { NewSync } from "components/composite/NewSync";
import { NotFound } from "components/composite/NotFound";
import { Overview } from "components/onboard/Overview";
import { EnvironmentSelection } from "components/onboard/EnvironmentSelection";
import { SourceSelection } from "components/onboard/SourceSelection";
import { DestinationSelection } from "components/onboard/DestinationSelection";
import { TableMapping } from "components/onboard/TableMapping";
import { SyncInterstitial } from "components/onboard/SyncInterstitial";
import { SyncList } from "components/composite/Home";
import { SyncPage } from "components/composite/SyncPage";
import { SyncPageBackfills } from "components/composite/SyncPage/SyncPageBackfills";
import { SyncPageCollections } from "components/composite/SyncPage/SyncPageCollections";
import { SyncPageConnectionInstructions } from "components/composite/SyncPage/SyncPageConnectionInstructions";
import { SyncPageMapping } from "components/composite/SyncPage/SyncPageMapping";
import { SyncPageSchema } from "components/composite/SyncPage/SyncPageSchema";
import { SyncPageSettings } from "components/composite/SyncPage/SyncPageSettings";
import { Usage } from "components/composite/Usage";
import { ErrorBoundary } from "components/ErrorBoundary";
import { globalState } from "lib/globalState";
import { pageView } from "lib/utils/analytics";

createStore(globalState);

const Root = () => {
  const location = useLocation();

  useEffect(() => {
    pageView(location.pathname);
  }, [location]);

  return <Outlet />;
};

const sentryCreateBrowserRouter = wrapCreateBrowserRouter(createBrowserRouter);

export default function Home() {
  const router = sentryCreateBrowserRouter(
    createRoutesFromElements(
      <Route path="/" element={<Root />} errorElement={<ErrorBoundary />}>
        {/* Routes of the console that require login */}
        <Route path="/" element={<RequiresLogin />}>
          <Route path="/" element={<CommonChrome />}>
            <Route path="/" element={<CommonLayout />}>
              <Route path="/" element={<SyncList />}>
                <Route path="syncs" index />
                <Route path="usage" element={<Usage />} />
                <Route path="syncs/new" element={<NewSync />} />
              </Route>
              <Route path="databases" element={<Databases />}>
                <Route path=":databaseId">
                  <Route path="edit" element={<EditDatabase />} />
                </Route>
              </Route>
              <Route path="credentials" element={<Credentials />} />
              <Route path="checkout">
                <Route index element={<Checkout />} />
                <Route path="success" element={<CheckoutSuccess />} />
              </Route>
              <Route path="users">
                <Route index element={<Users />} />
                <Route path="invite" element={<UserInvite />} />
              </Route>
            </Route>
            <Route path="/" element={<FullPageLayout />}>
              <Route path="syncs">
                <Route path=":permaslug">
                  <Route element={<SyncPage />}>
                    <Route
                      path="collections"
                      element={<SyncPageCollections />}
                    />
                    <Route
                      path="connection-instructions"
                      element={<SyncPageConnectionInstructions />}
                    />
                    <Route path="backfills" element={<SyncPageBackfills />} />
                    <Route path="mapping" element={<SyncPageMapping />} />
                    <Route path="schema" element={<SyncPageSchema />} />
                    <Route path="settings" element={<SyncPageSettings />} />
                  </Route>
                </Route>
              </Route>
            </Route>
          </Route>

          {/* Onboarding routes */}
          <Route path="onboard">
            <Route index={true} element={<Overview />} />
            <Route path="1" element={<EnvironmentSelection />} />
            <Route path="2" element={<SourceSelection />} />
            <Route path="3" element={<DestinationSelection />} />
            <Route path="4" element={<TableMapping />} />
            <Route path="5" element={<SyncInterstitial />} />
            <Route path="6" element={<Splash />} />
            <Route path="7" element={<Connect />} />
            <Route path="8" element={<Select />} />
            <Route path="9" element={<Write />} />
            <Route path="source" element={<BetaPlatform />} />
            <Route path="redesigned">
              <Route
                index={true}
                element={<Navigate to="/onboard" replace />}
              />
              <Route path="*" element={<Navigate to="/onboard" replace />} />
            </Route>
          </Route>

          <Route path="/" element={<CommonChrome />}>
            <Route path="/features" element={<CommonLayout />}>
              <Route path="writes_beta" element={<WritesBetaConfirmation />} />
            </Route>
          </Route>
        </Route>
        {/* End routes that require login */}

        {/* Routes of the console that do not require login */}
        <Route path="/" element={<CommonChrome />}>
          <Route path="/" element={<CommonLayout />}>
            <Route path="logout">
              <Route index element={<Logout />} />
              <Route path="done" element={<LogoutDone />} />
            </Route>
            <Route path="confirmation" element={<EmailConfirmation />} />
            <Route path="join" element={<JoinOrg />} />
          </Route>
        </Route>

        {/* Routes of the console part of auth process */}
        <Route path="/" element={<AuthLayout />}>
          <Route path="signup" element={<Signup />} />
          <Route path="login">
            <Route index element={<Login />} />
            <Route path="confirm-reset" element={<ConfirmReset />} />
            <Route path="forgot-password" element={<ForgotPassword />} />
          </Route>
        </Route>

        <Route path="*" element={<NotFound />} />
      </Route>
    )
  );

  return (
    <>
      <Head>
        <title>Syncs - Sequin</title>
      </Head>

      <StateMachineProvider>
        <RouterProvider router={router} />
      </StateMachineProvider>
    </>
  );
}
