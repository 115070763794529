import React, { useEffect, useState } from "react";

import { MAILTO_LINK, SEQUIN_STATUS_PAGE } from "site-constants";

type ServiceStatus =
  | "operational"
  | "degraded_performance"
  | "partial_outage"
  | "major_outage";
interface StatusApiResponse {
  components: {
    id: string;
    name: "Airtable Sync" | string;
    status: ServiceStatus;
    created_at: string;
    updated_at: string;
  }[];
}

export default function Footer() {
  let [status, setStatus] = useState<ServiceStatus | undefined>();

  useEffect(() => {
    const fetchStatus = async () => {
      try {
        const response = await fetch(SEQUIN_STATUS_PAGE);
        const json: StatusApiResponse = await response.json();
        const component = json.components[0];
        setStatus(component.status);
      } catch (error) {
        // We don't really care that there was an error fetching the status
        // we just don't want the app to crash when there is one.
      }
    };

    fetchStatus();
  }, []);

  let getDotColor = () => {
    switch (status) {
      case "operational":
        return "#73d13d";
      case "partial_outage":
        return;
      case "degraded_performance":
        return "#ffc53d";
      case "major_outage":
        return "#f5222d";
      default:
        return "#bbb";
    }
  };

  let getStatusText = () => {
    switch (status) {
      case "operational":
        return "All systems nominal";
      case "partial_outage":
        return;
      case "degraded_performance":
        return "Systems degraded";
      case "major_outage":
        return "Partial or major outage";
      default:
        return "Error loading status";
    }
  };

  return (
    <div className="w-full bg-black text-white flex flex-col dark:border-t border-gray-800">
      <div className="w-full mx-auto px-8">
        <div className="py-8 flex flex-col xl:flex-row">
          <div className="flex-1 mb-6 xl:mb-0">
            <span className="text-gray-400 text-xs">
              &copy; Sequin Labs, Inc. {new Date().getFullYear()}
            </span>
          </div>
          <div className="grid grid-flow-col grid-rows-2 md:grid-rows-1 lg:grid-rows-1 gap-8 items-center">
            <a
              className="text-gray-400 text-xs"
              target="_blank"
              href="https://sequin.io/terms"
              rel="noreferrer"
            >
              Terms of Service
            </a>

            <a
              className="text-gray-400 text-xs"
              target="_blank"
              href="https://sequin.io/privacy"
              rel="noreferrer"
            >
              Privacy Policy
            </a>

            <a
              className="text-gray-400 text-xs"
              target="_blank"
              href="https://sequin.io/legal/security"
              rel="noreferrer"
            >
              Security
            </a>

            <a
              className="text-gray-400 text-xs"
              target="_blank"
              href="https://sequin.io/legal/dpa"
              rel="noreferrer"
            >
              Data Processing
            </a>

            <a className="text-xs" href="https://status.sequin.io">
              <div className="flex flex-row items-center">
                <div
                  className="h-3 w-3 rounded-full mr-2"
                  style={{ background: getDotColor() }}
                />
                {getStatusText()}
              </div>
            </a>
            <div className="flex flex-row">
              <a
                href="https://www.linkedin.com/company/sequin-io/"
                target="_blank"
                rel="noreferrer noopener"
              >
                <img src="/static/img/linkedin.svg" alt="LinkedIn" />
              </a>
              <a
                href="https://twitter.com/sequin_io"
                className="mt-1 ml-4"
                target="_blank"
                rel="noreferrer noopener"
              >
                <img src="/static/img/twitter.svg" alt="Twitter" />
              </a>
              <a
                href={MAILTO_LINK}
                target="_blank"
                className="ml-4"
                rel="noreferrer noopener"
              >
                <img src="/static/img/email.svg" alt="E-mail" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
