import React, { useRef } from "react";
import Head from "next/head";
import classNames from "classnames";
import { Link, matchRoutes, useLocation, useNavigate } from "react-router-dom";
import { LogoutIcon } from "@heroicons/react/outline";
import { useStateMachine } from "little-state-machine";
import { FormProvider, useForm } from "react-hook-form";

import { MAILTO_LINK } from "site-constants";
import { useResources } from "lib/api/hooks";
import { Button } from "lib/lucidez/components/Button";
import ThemedBrand from "components/core/ThemedBrand";
import { AIRTABLE_PLATFORM } from "lib/platforms/airtable";
import { updateCurrentStep, updateFormData } from "lib/globalState";
import { AsyncValidationProvider } from "components/platforms/common/forms/util";

const syncInterstitialStep = 5;
// const connectQueryStartStep = 7;
const connectQueryEndStep = 9;

export const OnboardLayout = ({
  children,
  nextButtonIsActive,
  onNextClick,
}: React.PropsWithChildren<{
  nextButtonIsActive: boolean;
  onNextClick?: (event: React.SyntheticEvent) => void;
}>) => {
  // const location = useLocation();
  // const currentStep = location.pathname.split("/")[3];

  return (
    <div className="w-full min-h-screen flex flex-col">
      <OnboardingHeader />
      <main className="flex flex-col flex-1 w-[1400px] max-w-[99.5%] relative mt-16 overflow-auto">
        <div className="flex flex-col items-center min-h-full flex-1 px-4 text-center">
          <div className="flex flex-row flex-1 w-full">
            {/* <nav className="flex flex-col w-1/3 border-r border-gray-200">
              <div className="flex flex-row h-full">
                <div className="w-1/2">&nbsp;</div>
                <MainNavigationItems currentStep={parseInt(currentStep, 10)} />
              </div>
            </nav> */}
            <div className="flex flex-col px-12 w-full text-left">
              {children}
            </div>
          </div>
        </div>
        <div className="h-28" />
      </main>
      <FooterNavigation
        nextButtonIsActive={nextButtonIsActive}
        onNextClick={onNextClick}
      />
    </div>
  );
};

// const MainNavigationItems = ({ currentStep }: { currentStep: number }) => {
//   return (
//     <ol className="flex flex-col text-left w-full">
//       <li className="mb-8">
//         <div
//           className={classNames("flex justify-between", {
//             "font-bold": currentStep > 0,
//             "text-cool-gray-400": currentStep > syncInterstitialStep,
//           })}
//         >
//           <span>1. Source</span>
//           {currentStep > 2 && <CheckIcon className="w-5 xl:mr-16 sm:mr-4" />}
//         </div>
//       </li>
//       <li className="mb-8">
//         <div
//           className={classNames("flex justify-between", {
//             "font-bold": currentStep > 2,
//             "text-cool-gray-400": currentStep > syncInterstitialStep,
//           })}
//         >
//           <span>2. Destination</span>
//           {currentStep > 3 && <CheckIcon className="w-5 xl:mr-16 sm:mr-4" />}
//         </div>
//       </li>
//       <li className="mb-8">
//         <div
//           className={classNames("flex justify-between", {
//             "font-bold": currentStep > 3,
//             "text-cool-gray-400": currentStep > syncInterstitialStep,
//           })}
//         >
//           <span>3. Sync</span>
//           {currentStep > syncInterstitialStep && (
//             <CheckIcon className="w-5 xl:mr-16 sm:mr-4" />
//           )}
//         </div>
//       </li>
//       <li className="mb-8">
//         <div className="flex justify-between">
//           <span
//             className={classNames({
//               "font-bold": currentStep > syncInterstitialStep,
//             })}
//           >
//             4. Connect + Query
//           </span>
//           {currentStep > connectQueryStartStep && (
//             <CheckIcon className="w-5 xl:mr-16 sm:mr-4" />
//           )}
//         </div>
//       </li>
//       {currentStep > syncInterstitialStep && (
//         <ul className="ml-6 list-none">
//           <li className="mb-8">
//             <div className="flex justify-between">
//               <span className={classNames({ "font-bold": currentStep > 6 })}>
//                 Connect to Sequin
//               </span>
//               {currentStep > connectQueryStartStep && (
//                 <CheckIcon className="w-5 xl:mr-16 sm:mr-4" />
//               )}
//             </div>
//           </li>
//           <li className="mb-8">
//             <div className="flex justify-between">
//               <span
//                 className={classNames({
//                   "font-bold": currentStep > connectQueryStartStep,
//                 })}
//               >
//                 Select
//               </span>
//               {currentStep > 8 && (
//                 <CheckIcon className="w-5 xl:mr-16 sm:mr-4" />
//               )}
//             </div>
//           </li>
//           <li className="mb-8">
//             <div className="flex justify-between">
//               <span className={classNames({ "font-bold": currentStep > 8 })}>
//                 Write
//               </span>
//             </div>
//           </li>
//         </ul>
//       )}
//     </ol>
//   );
// };

const FooterNavigation = ({
  nextButtonIsActive,
  onNextClick,
}: {
  nextButtonIsActive: boolean;
  onNextClick: (event: React.SyntheticEvent) => void;
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { actions } = useStateMachine({ updateCurrentStep });
  const [resources] = useResources();
  const latestResource = resources && resources[resources.length - 1];

  const isOverviewPage = Array.isArray(
    matchRoutes([{ path: "/onboard/" }], location)
  );

  const currentStep = isOverviewPage
    ? 0
    : parseInt(location.pathname.split("/")[2], 10);

  const nextStepLink =
    (currentStep === connectQueryEndStep ||
      currentStep === syncInterstitialStep) &&
    latestResource
      ? `/syncs/${latestResource.permaslug}/backfills`
      : `/onboard/${currentStep + 1}`;
  let nextButtonText = "Next";
  let nextButtonVariant = "primary";
  if (currentStep === syncInterstitialStep) {
    nextButtonText = "Finish";
  }
  if (currentStep > syncInterstitialStep) {
    nextButtonText =
      currentStep === connectQueryEndStep ? "Finish" : "Skip this step";
    nextButtonVariant =
      currentStep === connectQueryEndStep ? "primary" : "tertiary";
  }

  // const previousStepLink =
  // currentStep === 1 ? "/onboard/" : `/onboard/${currentStep - 1}`;

  const defaultNextClickHandler = (event) => {
    if (window && window.analytics) {
      window.analytics.track(`Onboarding Footer Nav ${nextButtonText} Clicked`);
    }

    if (currentStep !== connectQueryEndStep) {
      actions.updateCurrentStep(currentStep + 1);
    }

    if (currentStep === connectQueryEndStep && latestResource) {
      event.preventDefault();
      navigate(`/syncs/${latestResource.permaslug}/backfills`, {
        state: { shouldShowOnboardingModal: true },
      });
    }
  };
  const handleNextClick = onNextClick ? onNextClick : defaultNextClickHandler;

  return (
    <footer className="w-full relative flex flex-row bg-cool-gray-50 border-t border-gray-200">
      <div className="flex flex-row py-4 mx-8 items-center justify-between w-full">
        <div className="flex justify-start">
          {/*
            Don't allow going back from the sync interstitial since the sync has already started.
            Don't allow going back to the Connect + Query splash page
           */}
          {/* {currentStep > 0 && currentStep !== 4 && currentStep !== 6 && (
            <Link
              to={previousStepLink}
              onClick={() => {
                if (window && window.analytics) {
                  window.analytics.track("Onboarding Footer Nav Back Clicked", {
                    step: getNameOfStep(currentStep),
                  });
                }

                actions.updateCurrentStep(currentStep - 1);
              }}
            >
              <Button variant="tertiary" size="lg" as="span">
                Back
              </Button>
            </Link>
          )} */}
        </div>
        <div className="flex justify-end">
          <Link to={nextStepLink} onClick={handleNextClick}>
            <Button
              variant={nextButtonVariant}
              size="lg"
              as="button"
              disabled={!nextButtonIsActive}
            >
              {nextButtonText}
            </Button>
          </Link>
        </div>
      </div>
    </footer>
  );
};

export const OnboardFormProvider = ({
  children,
}: React.PropsWithChildren<{}>) => {
  const { actions, state } = useStateMachine({ updateFormData });

  const stateRef = useRef(state["onboardState"].currentFormData);

  const formMethods = useForm<any>({
    defaultValues:
      stateRef.current || AIRTABLE_PLATFORM.getInitialCreateState(),
    mode: "all",
  });

  formMethods.watch(() => {
    saveDataToStore();
  });

  const saveDataToStore = () => {
    const newValues = formMethods.getValues();

    if (
      JSON.stringify(state["onboardState"].currentFormData) !==
      JSON.stringify(newValues)
    ) {
      actions.updateFormData(formMethods.getValues());
    }
  };

  return (
    <AsyncValidationProvider>
      <FormProvider {...formMethods}>{children}</FormProvider>
    </AsyncValidationProvider>
  );
};

export const OnboardingHeader = ({
  currentStep,
  shouldShowSubwayNav,
}: {
  currentStep?: number;
  shouldShowSubwayNav?: boolean;
}) => {
  return (
    <>
      <Head>
        <title>Welcome to Sequin!</title>
      </Head>
      <header className="w-full relative flex flex-row bg-cool-gray-50 border-b border-gray-200">
        <div className="flex flex-row py-6 ml-14 mr-8 items-center justify-between w-full">
          <ThemedBrand className="h-9" />
          {shouldShowSubwayNav && (
            <SubwayNavigation currentStep={currentStep} />
          )}
          <div className="flex justify-end">
            <a
              className="contact-us-trigger hidden lg:flex mr-6 font-semibold"
              href={MAILTO_LINK}
            >
              Help
            </a>
            <Link to="/logout" className="inline-flex font-semibold">
              <LogoutIcon className="mr-1 w-5" />
              Logout
            </Link>
          </div>
        </div>
      </header>
    </>
  );
};

interface SubwayNavigationProps {
  currentStep: number;
}

// TODO: Improve maintanability, componentize underlying steps
// Currently very laborous to mantain
const SubwayNavigation = ({ currentStep }: SubwayNavigationProps) => {
  const stepCircleClasses =
    "rounded-full w-4 h-4 text-white text-xxs flex items-center justify-center font-bold";
  const stepLabelClasses = "ml-1.5 font-bold text-xxs";
  const borderClasses = "flex-1 border-t border-dashed mx-3";

  return (
    <div className="flex flex-row items-center container max-w-lg">
      <div className="flex flex-row items-center">
        <span className={classNames(stepCircleClasses, "bg-black")}>1</span>
        <span className={classNames(stepLabelClasses, "text-black")}>
          Integration
        </span>
      </div>
      <div
        className={classNames(borderClasses, {
          "border-gray-400": currentStep < 2,
          "border-black": currentStep > 1,
        })}
      />
      <div className="flex flex-row items-center">
        <span
          className={classNames(stepCircleClasses, {
            "bg-gray-400": currentStep < 2,
            "bg-black": currentStep > 1,
          })}
        >
          2
        </span>
        <h2
          className={classNames(stepLabelClasses, {
            "text-gray-400": currentStep < 2,
            "text-black": currentStep > 1,
          })}
        >
          Credentials
        </h2>
      </div>
      <div
        className={classNames(borderClasses, {
          "border-gray-400": currentStep < 3,
          "border-black": currentStep > 2,
        })}
      />
      <div className="flex flex-row items-center">
        <span
          className={classNames(stepCircleClasses, {
            "bg-gray-400": currentStep < 3,
            "bg-black": currentStep > 2,
          })}
        >
          3
        </span>
        <h2
          className={classNames(stepLabelClasses, {
            "text-gray-400": currentStep < 4,
            "text-black": currentStep > 3,
          })}
        >
          Sync
        </h2>
      </div>
      <div
        className={classNames(borderClasses, {
          "border-gray-400": currentStep < 4,
          "border-black": currentStep > 3,
        })}
      />
      <div className="flex flex-row items-center">
        <span
          className={classNames(stepCircleClasses, {
            "bg-gray-400": currentStep < 4,
            "bg-black": currentStep > 3,
          })}
        >
          4
        </span>
        <h2
          className={classNames(stepLabelClasses, {
            "text-gray-400": currentStep < 4,
            "text-black": currentStep > 3,
          })}
        >
          Connect + Query
        </h2>
      </div>
    </div>
  );
};

// const getNameOfStep = (currentStep: number) => {
//   if (currentStep === 1) {
//     return "Integration";
//   }

//   if (currentStep === 2) {
//     return "Credentials";
//   }

//   if (currentStep === 3) {
//     return "Sync";
//   }

//   if (currentStep === 4) {
//     const connectParts = location.pathname.split("connect/")[1];
//     if (!connectParts) {
//       return "Connect + Query";
//     }

//     const currentSubstep = parseInt(connectParts, 10);

//     if (currentSubstep === 1) {
//       return "Connect to Sequin";
//     }

//     if (currentSubstep === 2) {
//       return "Select";
//     }

//     if (currentSubstep === 3) {
//       return "Write";
//     }
//   }
// };
