import { Popover } from "@headlessui/react";
import { TrashIcon, UserIcon, XIcon } from "@heroicons/react/outline";
import Head from "next/head";
import React, { useReducer, useRef, useState } from "react";
import { toast } from "react-toastify";
import { mutate } from "swr";
import { useNavigate } from "react-router-dom";

import Users from "components/composite/Users";
import Drawer from "components/core/Drawer";
import { PageLoadingSpinner } from "components/core/PageLoadingSpinner";
import SvgSpinner from "components/svg/Spinner";
import { createPendingUser, deletePendingUser, resendInvite } from "lib/api";
import { useUsers } from "lib/api/hooks";
import { PendingUser } from "lib/api/types";
import { useId, useWorkable } from "lib/utils";
import { Button } from "lib/lucidez";

interface State {
  emails: string[];
  error: null | string;
  value: string;
}

enum Actions {
  AddEmails,
  RemoveEmail,
  SetValue,
}

type UnderActionObject =
  | {
      type: Actions.AddEmails;
      emails: string[];
      alreadyInvitedEmails: string[];
    }
  | { type: Actions.RemoveEmail; email: string }
  | { type: Actions.SetValue; value: string };

type ActionObject =
  | { type: Actions.AddEmails; emails: string[] }
  | { type: Actions.RemoveEmail; email: string }
  | { type: Actions.SetValue; value: string };

const isEmail = (email: string) => /[\w\d.-]+@[\w\d.-]+\.[\w\d.-]+/.test(email);

const errorForEmail = (email: string, invalidEmails: string[]) => {
  let error = null;

  if (invalidEmails.includes(email)) {
    error = `${email} has already been added.`;
  }

  if (!isEmail(email)) {
    error = `${email} is not a valid email address.`;
  }

  return error;
};

const reducer = (state: State, action: UnderActionObject): State => {
  switch (action.type) {
    case Actions.AddEmails: {
      const invalidEmails = [...state.emails, ...action.alreadyInvitedEmails];

      for (let i = 0; i < action.emails.length; i++) {
        const email = action.emails[i];
        const error = errorForEmail(email, invalidEmails);

        if (error) {
          return {
            ...state,
            error,
          };
        }
      }

      return {
        error: null,
        emails: [...state.emails, ...action.emails],
        value: "",
      };
    }

    case Actions.RemoveEmail: {
      return {
        ...state,
        emails: state.emails.filter((e) => e !== action.email),
      };
    }

    case Actions.SetValue: {
      return {
        ...state,
        error: null,
        value: action.value,
      };
    }
  }
};

export default function UserInvite() {
  const navigate = useNavigate();
  const handleClose = () => navigate("/users");

  const [users] = useUsers();
  const sortedPendingUsers = users
    ? users
        .filter((x) => x.status === "pending")
        .sort((a, b) => (a.email || a.id).localeCompare(b.email || b.id))
    : [];
  const invitedEmails = users ? users.map((u) => u.email) : [];

  const exposedReducer = (s: State, a: ActionObject): State => {
    if (a.type === Actions.AddEmails) {
      return reducer(s, {
        type: Actions.AddEmails,
        emails: a.emails,
        alreadyInvitedEmails: invitedEmails,
      });
    }

    return reducer(s, a);
  };

  const reducerBag = useReducer(exposedReducer, {
    emails: [],
    error: null,
    value: "",
  });
  const [state, dispatch] = reducerBag;
  const { emails: invitingEmails } = state;

  const [isSubmitting, applyIsSubmitting] = useWorkable();

  const doSubmit = async () => {
    let emailsToSubmit = [...invitingEmails];

    // check if there's a email in the input
    const value = state.value.trim();

    if (value) {
      const invalidEmails = [...state.emails, ...invitedEmails];
      const error = errorForEmail(value, invalidEmails);

      dispatch({ type: Actions.AddEmails, emails: [value] });

      if (error) {
        return false;
      } // error should already be in the state

      emailsToSubmit.push(value);
    }

    for (let i = 0; i < emailsToSubmit.length; i++) {
      const email = emailsToSubmit[i];
      await createPendingUser({ email });
    }

    if (window && window.analytics) {
      window.analytics.track("User Invites Sent", {
        invitedEmails: emailsToSubmit,
      });
    }

    return true;
  };

  const handleSubmit = async () => {
    if (await applyIsSubmitting(doSubmit)) {
      toast.success("Users invited", { toastId: "users-invited" });
      handleClose();
    }
  };

  return (
    <Users>
      <Head>
        <title>Invite users - Sequin</title>
      </Head>
      <Drawer onClose={handleClose} title="Invite Users">
        <div className="relative h-full flex">
          <div className="absolute flex-1 flex flex-col h-full max-h-full w-full">
            <div className="flex-1 overflow-auto px-8 pb-8">
              <div className="py-8">
                <UserInvitingHelper
                  reducerBag={reducerBag}
                  invitedEmails={invitedEmails}
                />
                <h2 className="font-bold text-xl mt-8">Invited users</h2>
                {users ? (
                  <table className="w-full mt-2">
                    <tbody>
                      {sortedPendingUsers.map((u, idx) => (
                        <UserRow user={u as PendingUser} key={u.id + idx} />
                      ))}
                    </tbody>
                  </table>
                ) : (
                  <PageLoadingSpinner />
                )}
              </div>
            </div>
            <div className="flex flex-row p-4 border-t bg-white">
              <div className="flex-1" />
              <Button variant="outlined" size="md" onClick={handleClose}>
                Cancel
              </Button>
              <Button
                variant="primary"
                size="md"
                onClick={handleSubmit}
                className="ml-2"
                isLoading={isSubmitting}
                disabled={invitingEmails.length < 1 && !state.value.trim()}
              >
                Send Invites
              </Button>
            </div>
          </div>
        </div>
      </Drawer>
    </Users>
  );
}

function UserRow({ user }: { user: PendingUser }) {
  const [isDeleting, applyIsDeleting] = useWorkable();
  const [isResending, applyIsResending] = useWorkable();
  const [hasResend, setHasResend] = useState(false);

  const deleteBtnRef = useRef<any>();

  const handleDelete = async () => {
    closeDeletePopover();

    await applyIsDeleting(() => deletePendingUser(user.id));
    await mutate("/api/users");
  };

  const handleResend = async () => {
    await applyIsResending(() => resendInvite(user.id));
    setHasResend(true);
    toast.success(`Invite resent to ${user.email}`, {
      toastId: "invite-resent",
    });
  };

  const closeDeletePopover = () => {
    deleteBtnRef.current?.click();
  };

  return (
    <tr className="flex flex-row w-full py-4">
      <td>
        <div className="border border-dashed border-gray-200 rounded-full w-14 h-14 flex items-center justify-center">
          <UserIcon className="h-6" />
        </div>
      </td>
      <td className="flex flex-col justify-center ml-5 flex-1">
        <span className="font-bold">{user.email}</span>
      </td>
      <td className="flex flex-row justify-center items-center">
        {!hasResend && (
          <Button
            variant="outlined"
            size="sm"
            className="mr-3"
            onClick={handleResend}
            isLoading={isResending}
          >
            Resend
          </Button>
        )}
        <Popover className="relative h-6">
          <Popover.Button
            className="text-gray-600 hover:text-black focus:text-black"
            ref={deleteBtnRef}
            disabled={isDeleting}
          >
            {isDeleting ? (
              <SvgSpinner className="h-6 animate-spin" />
            ) : (
              <TrashIcon className="h-6" />
            )}
          </Popover.Button>
          <Popover.Panel className="absolute z-10 bottom-full border border-gray-200 bg-white p-4 shadow-lg rounded right-0 transform -translate-y-1 w-64">
            <p className="text-xs">
              Are you sure you want to delete this user?
            </p>
            <div className="flex flex-row mt-4">
              <div className="flex-1" />
              <Button
                variant="outlined"
                size="sm"
                onClick={handleDelete}
                isLoading={isDeleting}
              >
                Yes
              </Button>
              <Button
                variant="primary"
                size="sm"
                onClick={closeDeletePopover}
                className="ml-2"
              >
                No
              </Button>
            </div>
          </Popover.Panel>
        </Popover>
      </td>
    </tr>
  );
}

// adapted from https://www.freecodecamp.org/news/how-to-create-email-chips-in-pure-react-ad1cc3ecea16/
function UserInvitingHelper({
  reducerBag,
  invitedEmails,
}: {
  reducerBag: [State, React.Dispatch<ActionObject>];
  invitedEmails: string[];
}) {
  const [state, dispatch] = reducerBag;
  const { emails, value: insertingValue, error } = state;

  const inputRef = useRef<HTMLInputElement>();
  const inputId = useId();

  const onAdd = (emails: string[]) =>
    dispatch({ type: Actions.AddEmails, emails });

  const onRemove = (email: string) =>
    dispatch({ type: Actions.RemoveEmail, email });

  const setInsertingValue = (value: string) =>
    dispatch({ type: Actions.SetValue, value });

  const handlePaste = (e: React.ClipboardEvent<any>) => {
    e.preventDefault();

    const paste = e.clipboardData.getData("text");
    const pastingEmails = paste.match(/[\w\d.-]+@[\w\d.-]+\.[\w\d.-]+/g);

    const invalidEmails = [...emails, ...invitedEmails];

    if (pastingEmails) {
      onAdd(pastingEmails.filter((email) => !invalidEmails.includes(email)));
    }
  };

  const tryAddCurrentValue = () => {
    const value = insertingValue.trim();

    if (value) {
      onAdd([value]);
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<any>) => {
    if (["Enter", "Tab", ",", ";"].includes(e.key)) {
      e.preventDefault();
      tryAddCurrentValue();
    }
  };

  const handleBlur = () => {
    tryAddCurrentValue();
  };

  const handleChange = (e: React.ChangeEvent<any>) => {
    setInsertingValue(e.target.value);
  };

  return (
    <div className="w-full">
      <label htmlFor={inputId}>Email addresses</label>
      <div
        className="textbox-outlined p-2 px-3 w-full mt-2 flex flex-col cursor-text"
        onClick={() => inputRef.current?.focus()}
      >
        <div className="w-full">
          {emails.map((email) => (
            <div
              key={email}
              className="inline-flex mr-1 mb-1 text-xs pl-2 pr-1 rounded-full border border-gray-200 items-center"
            >
              {email}{" "}
              <button className="ml-1" onClick={() => onRemove(email)}>
                <XIcon className="h-3 w-3" />
              </button>
            </div>
          ))}
        </div>
        <input
          value={insertingValue}
          className="w-full outline-none py-1"
          placeholder='Type or paste one or multiple email addresses and press "Enter"'
          onChange={handleChange}
          onKeyDown={handleKeyDown}
          onPaste={handlePaste}
          onBlur={handleBlur}
          ref={inputRef}
          id={inputId}
          autoComplete="off"
          type="email"
        />
      </div>
      <label htmlFor={inputId} className="text-error text-xs">
        {error}
      </label>
    </div>
  );
}
