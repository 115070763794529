import React, { useState } from "react";
import classNames from "classnames";
import { Menu, Transition } from "@headlessui/react";
import { CheckIcon, PencilIcon } from "@heroicons/react/solid";

import { updateResource } from "lib/api";
import { ResourceEnvironment } from "lib/api/types";
import { Resource } from "lib/platforms";

interface EnvironmentProps {
  className?: string;
  resource: Resource;
  size?: "lg" | "sm";
}

export const EnvironmentBadge = ({
  className = "",
  resource,
  size = "lg",
}: EnvironmentProps) => {
  if (size !== "lg") {
    return (
      <div
        className={classNames(
          "flex items-center px-2 py-1 text-xxs border rounded-full text-cool-gray-500",
          className
        )}
      >
        {prettyDisplay(resource.resourceEnvironment)}
      </div>
    );
  }
  return (
    <div
      className={classNames(
        "flex items-center px-3 py-2 text-xxs border rounded-full text-cool-gray-500",
        className
      )}
    >
      {prettyDisplay(resource.resourceEnvironment)}
    </div>
  );
};

interface EditableEnvironmentProps extends EnvironmentProps {
  mutateResourceEnvironment: (
    resourceEnvironment: ResourceEnvironment
  ) => Promise<any>;
}

export const EditableEnvironmentBadge = ({
  className = "",
  mutateResourceEnvironment,
  resource,
}: EditableEnvironmentProps) => {
  // Store the value of resource.resourceEnvironment locally, and render with
  // this value, so that changes appear immediately, rather than waiting for the
  // mutation get request to update. After that request comes back, this value
  // will be reset, and everything becomes consistent.
  const [resourceEnvironment, setResourceEnvironment] =
    useState<ResourceEnvironment>(resource.resourceEnvironment);
  return (
    <div>
      <div
        className={classNames(
          "flex items-center justify-around relative px-3 py-2 text-xxs border rounded-full text-cool-gray-500",
          className
        )}
      >
        {prettyDisplay(resourceEnvironment)}

        <Menu as="div" className="inline-block text-left">
          <Menu.Button className="flex flex-row rounded-full items-center justify-center text-gray-600">
            <PencilIcon className="w-3 h-3 ml-2 cursor-pointer" />
          </Menu.Button>
          <Transition
            as={React.Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items className="dropdown-items w-36 absolute top-[25px] left-0">
              {resourceEnvironments.map((environment) => {
                return (
                  <Menu.Item key={environment}>
                    {({ active }) => (
                      <button
                        className={classNames(
                          "group flex rounded items-center w-full px-4 py-2 text-xs font-semibold",
                          {
                            "text-gray-700": !active,
                            "bg-gray-100 text-black": active,
                          }
                        )}
                        onClick={async () => {
                          setResourceEnvironment(environment);
                          await updateResource(resource.id, {
                            resourceEnvironment: environment,
                          });
                          mutateResourceEnvironment(environment);
                        }}
                      >
                        {prettyDisplay(environment)}
                        {resourceEnvironment === environment && (
                          <CheckIcon className="h-3 w-3 ml-4" />
                        )}
                      </button>
                    )}
                  </Menu.Item>
                );
              })}
            </Menu.Items>
          </Transition>
        </Menu>
      </div>
    </div>
  );
};

const resourceEnvironments: ResourceEnvironment[] = [
  "development",
  "staging",
  "production",
];

const prettyDisplay = (environment: ResourceEnvironment): string => {
  switch (environment) {
    case "development": {
      return "Development";
    }
    case "staging": {
      return "Staging";
    }
    case "production": {
      return "Production";
    }
  }
};
