import React, { useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import classNames from "classnames";
import {
  CheckIcon,
  CheckCircleIcon,
  UserAddIcon,
  XCircleIcon,
} from "@heroicons/react/outline";
import { Light as SyntaxHighlighter } from "react-syntax-highlighter";
import sql from "react-syntax-highlighter/dist/cjs/languages/hljs/sql";
import stackoverflowLight from "react-syntax-highlighter/dist/cjs/styles/hljs/stackoverflow-light";

import { getPlatformForKind, Resource } from "lib/platforms";
import { AirtableResource } from "lib/platforms/airtable";
import {
  usePlatformTables,
  useRecentOnboardingProxyQueryLog,
  useRecentProxyConnection,
} from "lib/api/hooks";
import { useLatestResource } from "lib/api/utilHooks";
import { randomInteger } from "lib/utils/numbers";
import { Button } from "lib/lucidez/components/Button";
import { PageLoadingSpinner } from "components/core/PageLoadingSpinner";
import { CopyTextToClipboardButton } from "components/core/CopyTextToClipboardButton";
import { SvgAirtableColored } from "components/svg/AirtableFilled";
import SvgHubspotFilled from "components/svg/Hubspot";
import SvgSalesforceFilled from "components/svg/Salesforce";
import {
  OnboardingHeader,
  OnboardLayout,
} from "components/onboard/OnboardLayout";

enum Steps {
  Connect = "Connect to Sequin",
  Select = "Select",
  Write = "Write",
}

SyntaxHighlighter.registerLanguage("sql", sql);

export const ConnectAndQuery = () => {
  const location = useLocation();
  const currentStep = parseInt(location.pathname.split("connect/")[1], 10);

  return (
    <div className="custom-container flex flex-row gap-16">
      <nav className="flex flex-col w-52">
        <ol className="flex flex-col mr-8 list-decimal text-left font-bold text-cool-gray-400">
          <li className="flex justify-between text-black mb-8">
            {Steps.Connect}
            {currentStep > 1 && <CheckIcon className="h-5 justify-end" />}
          </li>
          <li
            className={classNames("flex justify-between mb-8", {
              "text-black": currentStep > 1,
            })}
          >
            {Steps.Select}
            {currentStep > 2 && <CheckIcon className="h-5" />}
          </li>
          <li
            className={classNames("flex", {
              "text-black": currentStep > 2,
            })}
          >
            {Steps.Write}
          </li>
        </ol>
      </nav>
      <div className="flex flex-col flex-1">
        <Outlet />
      </div>
    </div>
  );
};

export const Splash = () => {
  const navigate = useNavigate();
  const latestResource = useLatestResource();

  const handleCancelClick = () => {
    const url = latestResource
      ? `/syncs/${latestResource.permaslug}/backfills`
      : "/";
    navigate(url, {
      state: { shouldShowOnboardingModal: true },
    });
  };

  const handleNextClick = () => {
    navigate("/onboard/7");
  };

  return (
    <div className="w-full min-h-screen flex flex-col">
      <OnboardingHeader />
      <main className="flex flex-col flex-1 relative">
        <div className="flex flex-row items-center min-h-full flex-1 px-4 text-center">
          <div className="flex flex-col items-center w-full">
            <h1 className="text-center w-full text-2xl font-bold mb-6">
              Connect + query with Sequin
            </h1>
            <p className="mb-3">
              In three steps we'll show you how to use your database.
            </p>
            <p className="mb-16">
              <b>You can always do this later.</b>
            </p>
            <div className="flex">
              <Button
                className="mr-6 px-10"
                variant="outlined"
                size="lg"
                onClick={handleCancelClick}
              >
                Do this later
              </Button>
              <Button
                className="px-12"
                variant="primary"
                size="lg"
                onClick={handleNextClick}
              >
                Continue
              </Button>
            </div>
          </div>
        </div>
      </main>
    </div>
  );
};

export const Connect = () => {
  const navigate = useNavigate();
  const [stepIsCompleted, setStepIsCompleted] = useState<boolean>(false);
  const latestResource = useLatestResource();

  const [connection] = useRecentProxyConnection(latestResource?.database?.id, {
    refreshInterval: 1500,
  });

  useEffect(() => {
    if (connection?.id) {
      setStepIsCompleted(true);
    }
  }, [connection?.id]);

  if (!latestResource) {
    return <PageLoadingSpinner />;
  }

  const platform = getPlatformForKind(latestResource?.kind);

  const handleNextClick = () => {
    if (window && window.analytics) {
      window.analytics.track("Onboarding Connect Next Clicked");
    }
    navigate("/onboard/8");
  };

  return (
    <OnboardLayout nextButtonIsActive={true}>
      <div className="text-left">
        <h2 className="text-2xl font-bold">Connect via Sequin</h2>
        <p className="text-sm mt-6 text-cool-gray-600">
          While we backfill, connect to your database to see your{" "}
          {platform.displayName} data syncing to tables in{" "}
          <code>{latestResource.database.schema}</code>.
        </p>
        <p className="text-sm mt-6 mb-4 text-cool-gray-600">
          Connect via Sequin's Postgres Proxy below. The Proxy enables powerful
          features like <code>insert</code> and <code>update</code>, which
          you'll learn about in a moment.
        </p>

        <div
          className={classNames(
            "bg-cool-gray-100 rounded-md w-full p-4 text-xs font-medium text-cool-gray-700 mt-6",
            {
              "bg-emerald-100 text-emerald-600": stepIsCompleted,
            }
          )}
        >
          <div className="flex items-center">
            {!stepIsCompleted ? (
              <>
                <span className="relative flex h-2 w-2 mr-2">
                  <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-cool-gray-400 opacity-75" />
                  <span className="relative inline-flex rounded-full h-2 w-2 bg-cool-gray-400" />
                </span>
                <p>Waiting for you to connect.</p>
              </>
            ) : (
              <span className="flex flex-row w-full items-center">
                <CheckCircleIcon className="w-5 h-5 mr-2 text-emerald-600 rounded-full" />
                Successfully connected.
              </span>
            )}
          </div>
        </div>
        <div className="flex justify-end mt-8">
          <Button
            size="md"
            variant="primary"
            onClick={handleNextClick}
            disabled={!stepIsCompleted}
          >
            Next
          </Button>
        </div>
      </div>
    </OnboardLayout>
  );
};

export const Select = () => {
  const navigate = useNavigate();
  const [stepIsCompleted, setStepIsCompleted] = useState<boolean>(false);

  const latestResource = useLatestResource();

  const [queryLog] = useRecentOnboardingProxyQueryLog(latestResource?.id, {
    refreshInterval: 1500,
  });

  useEffect(() => {
    if (queryLog?.id) {
      setStepIsCompleted(true);
    }
  }, [queryLog?.id]);

  if (!latestResource) {
    return <PageLoadingSpinner />;
  }

  const platform = getPlatformForKind(latestResource?.kind);

  const handleNextClick = () => {
    if (window && window.analytics) {
      window.analytics.track("Onboarding Select Next Clicked");
    }
    navigate("/onboard/9");
  };

  return (
    <OnboardLayout nextButtonIsActive={true}>
      <div className="text-left">
        <h2 className="text-2xl font-bold">Select</h2>
        <p className="text-sm mt-6 text-cool-gray-600">
          Because Sequin syncs your {platform.displayName} data to Postgres,
          reading from {platform.displayName} is as easy as running a{" "}
          <code>select</code>.
        </p>
        {latestResource?.kind === "airtable" ? (
          <AirtableSelectQuery resource={latestResource} />
        ) : (
          <DefaultSelectQuery
            namespace={latestResource?.database.schema}
            table={latestResource?.schema.tables[0]}
          />
        )}
        <div
          className={classNames(
            "bg-cool-gray-100 rounded-md w-full p-4 text-xs font-medium text-cool-gray-700 mt-6",
            {
              "bg-emerald-100 text-emerald-600": stepIsCompleted,
            }
          )}
        >
          <div className="flex items-center">
            {!stepIsCompleted ? (
              <>
                <span className="relative flex h-2 w-2 mr-2">
                  <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-cool-gray-400 opacity-75" />
                  <span className="relative inline-flex rounded-full h-2 w-2 bg-cool-gray-400" />
                </span>
                <p>Waiting for you to run the query above.</p>
              </>
            ) : (
              <span className="flex flex-row w-full items-center">
                <CheckCircleIcon className="w-5 h-5 mr-2 text-emerald-600 rounded-full" />
                You ran the <code>select</code> query.
              </span>
            )}
          </div>
        </div>
        {stepIsCompleted && (
          <p className="text-sm mt-6">
            Because your data is in Postgres, with Sequin you can run any kind
            of <code>select</code> query on your {platform.displayName} data.
            You can even <code>join</code> across tables!
          </p>
        )}
        <div className="flex justify-end mt-8">
          <Button
            size="md"
            variant="primary"
            onClick={handleNextClick}
            disabled={!stepIsCompleted}
          >
            Next
          </Button>
        </div>
      </div>
    </OnboardLayout>
  );
};

export const Write = () => {
  const [currentStep, setCurrentStep] = useState<number>(1);
  const [isQuerying, setIsQuerying] = useState<boolean>(false);

  const latestResource = useLatestResource();

  if (!latestResource) {
    return <PageLoadingSpinner />;
  }

  const platform = getPlatformForKind(latestResource?.kind);
  const correctInsertQuery = getCorrectInsertQuery(latestResource?.kind);
  const incorrectInsertQuery = getIncorrectInsertQuery(latestResource?.kind);

  const handleQueryClick = () => {
    setIsQuerying(true);
    setTimeout(() => {
      setCurrentStep(currentStep + 1);
      setIsQuerying(false);
    }, randomInteger(700, 3500));

    if (window && window.analytics) {
      window.analytics.track("Onboarding Connect Simulate Query Clicked", {
        query: currentStep === 1 ? "successful" : "unsuccessful",
      });
    }
  };

  return (
    <OnboardLayout nextButtonIsActive={true}>
      <div className="text-left">
        <h2 className="text-2xl font-bold">Write</h2>
        <p className="text-sm mt-6 text-cool-gray-600">
          Using Sequin's Postgres Proxy, you can make <code>inserts</code> and{" "}
          <code>updates</code> to the API via SQL as well. Changes are committed
          to the API and your database at the same time.
        </p>

        <p className="text-sm mt-6 text-cool-gray-600">
          We know running a test <code>insert</code> or <code>update</code> on
          your {platform.displayName} data may not be feasible. So we'll just
          show you a simulated example on {platform.displayName} of how it
          works.
        </p>
        <p className="text-sm mt-6 text-cool-gray-600">
          First, let's run this <code>insert</code>. It opens a new case for
          customers that purchased a defective product in the last 90 days:
        </p>
        <div className="mt-6">
          <SyntaxHighlighter
            codeTagProps={{ className: "undecorated" }}
            language="sql"
            showLineNumbers={true}
            style={stackoverflowLight}
            className="rounded-md border border-gray-200"
          >
            {correctInsertQuery}
          </SyntaxHighlighter>
        </div>
        <p className="text-sm mt-6 text-cool-gray-400">
          Running this query is only a simulation and will not affect any of
          your data.
        </p>
        {currentStep > 1 && (
          <>
            <div className="bg-emerald-100 rounded-md w-full p-4 mt-6 flex flex-col">
              <div className="flex flex-row w-full text-xs items-center font-medium">
                <CheckCircleIcon className="w-5 h-5 mr-2 text-emerald-600 rounded-full" />
                The <code>insert</code> was successful.
              </div>
              <SuccessfulQueryConfirmation kind={latestResource?.kind} />
            </div>
            <p className="text-sm mt-6 text-cool-gray-600">
              When you run an <code>insert</code>, Sequin makes an API call
              behind the scenes. If the <code>insert</code> is accepted by the
              API, it's committed to the database and your query returns
              successfully.
            </p>
            <p className="text-sm mt-6 text-cool-gray-600">
              But what happens when the API rejects your changes? Let's simulate
              this invalid query:
            </p>
            <div className="mt-6">
              <SyntaxHighlighter
                codeTagProps={{ className: "undecorated" }}
                language="sql"
                showLineNumbers={true}
                style={stackoverflowLight}
                className="rounded-md border border-gray-200"
              >
                {incorrectInsertQuery}
              </SyntaxHighlighter>
            </div>
          </>
        )}
        {currentStep > 2 && (
          <>
            <div className="bg-red-100 rounded-md w-full p-4 mt-6">
              <div className="flex flex-col">
                <div className="flex flex-row w-full text-xs items-center font-medium">
                  <XCircleIcon className="w-5 h-5 mr-2 text-red-600 rounded-full" />
                  <p className="text-red-800">
                    The <code>insert</code> call errored.
                  </p>
                </div>
                <UnsuccessfulQueryConfirmation kind={latestResource?.kind} />
              </div>
            </div>
            <p className="text-sm mt-6 text-cool-gray-600">
              Because Sequin validates all changes with the API first, we
              received a validation error on insert. This prevented the write
              from being committed to our database, which would have caused our
              database to be out-of-sync with {platform.displayName}. And{" "}
              {platform.displayName}'s validation error came back to us as a
              Postgres error.
            </p>
          </>
        )}
        {currentStep < 3 && (
          <Button
            className="mt-6"
            variant="tertiary"
            onClick={handleQueryClick}
            disabled={isQuerying}
          >
            Simulate query
          </Button>
        )}
      </div>
    </OnboardLayout>
  );
};

const DefaultSelectQuery = ({
  namespace,
  table,
}: {
  namespace: string;
  table: string;
}) => {
  const selectQuery = `select * from ${namespace}.${table} limit 10;`;
  return (
    <>
      <p className="text-sm mt-6 text-cool-gray-600">
        Run the <code>select</code> query below on your database to see a few
        records from <code>{table}</code>
      </p>
      <div className="mt-6 relative">
        <SyntaxHighlighter
          codeTagProps={{ className: "undecorated" }}
          language="sql"
          showLineNumbers={true}
          style={stackoverflowLight}
          className="rounded-md border border-gray-200"
        >
          {selectQuery}
        </SyntaxHighlighter>
        <CopyTextToClipboardButton
          textToCopy={selectQuery}
          className={"absolute right-1 top-1.5 h-6"}
        />
      </div>
    </>
  );
};

const AirtableSelectQuery = ({ resource }: { resource: AirtableResource }) => {
  const [tables, _mutate, _error] = usePlatformTables(
    resource?.credential?.id,
    {
      base_id: resource?.definition?.baseId,
      credential_id: resource?.credential?.id,
    },
    resource?.id,
    {
      revalidateOnFocus: false,
    }
  );

  const foundTable = tables?.find((airtable) => {
    return airtable.id === resource.schema.tables[0];
  });

  let table = resource.schema.tables[0];
  if (foundTable) {
    table = foundTable.db_name;
  }

  return (
    <DefaultSelectQuery namespace={resource?.database.schema} table={table} />
  );
};

const renderSuccessfulConfirmation = (kind: Resource["kind"]) => {
  switch (kind) {
    case "airtable": {
      return (
        <>
          <SvgAirtableColored className="w-7 text-[#00A1E0] mr-2" />
          <p className="font-bold text-xs">Airtable</p>
        </>
      );
    }

    case "hubspot": {
      return (
        <>
          <SvgHubspotFilled className="w-7 text-[#F8761F] mr-2" />
          <p className="font-bold text-xs">Hubspot</p>
        </>
      );
    }

    case "salesforce": {
      return (
        <>
          <SvgSalesforceFilled className="w-7 text-[#00A1E0] mr-2" />
          <p className="font-bold text-xs">Salesforce</p>
        </>
      );
    }
  }
};

const SuccessfulQueryConfirmation = ({ kind }: { kind: Resource["kind"] }) => {
  let successConfirmation = (
    <>
      <UserAddIcon className="w-4 h-4 mr-2" />
      21 new Cases created just now{" "}
    </>
  );

  if (kind === "airtable") {
    successConfirmation = (
      <>
        <UserAddIcon className="w-4 h-4 mr-2" />1 new Order created just now
      </>
    );
  }
  if (kind === "hubspot") {
    successConfirmation = (
      <>
        <UserAddIcon className="w-4 h-4 mr-2" />1 new Case created just now
      </>
    );
  }

  return (
    <div className="flex bg-emerald-200 rounded-lg p-3 mt-4 justify-between">
      <div className="flex items-center">
        {renderSuccessfulConfirmation(kind)}
      </div>
      <div className="flex text-xs items-center">{successConfirmation}</div>
    </div>
  );
};

const renderUnsuccessfulConfirmation = (kind: Resource["kind"]) => {
  switch (kind) {
    case "airtable": {
      return (
        <>
          <SvgAirtableColored className="w-7 text-[#00A1E0] mr-2" />
          <p className="font-bold text-xs">Airtable</p>
        </>
      );
    }

    case "hubspot": {
      return (
        <>
          <SvgHubspotFilled className="w-7 text-[#F8761F] mr-2" />
          <p className="font-bold text-xs">Hubspot</p>
        </>
      );
    }

    case "salesforce": {
      return (
        <>
          <SvgSalesforceFilled className="w-7 text-[#00A1E0] mr-2" />
          <p className="font-bold text-xs">Salesforce</p>
        </>
      );
    }
  }
};

const UnsuccessfulQueryConfirmation = ({
  kind,
}: {
  kind: Resource["kind"];
}) => {
  return (
    <div className="flex bg-red-200 rounded-lg p-3 mt-4 justify-between">
      <div className="flex items-center">
        {renderUnsuccessfulConfirmation(kind)}
      </div>
      <div className="flex text-xs items-center">
        <UserAddIcon className="w-4 h-4 mr-2" />
        Create failed: invalid email address
      </div>
    </div>
  );
};

const getCorrectInsertQuery = (kind: Resource["kind"]) => {
  if (kind === "airtable") {
    return `insert into order (contact_id, product_id)
select contacts.id, products.id
from contacts
join products on contacts.id = product.user_id
where contacts.email = 'paul.atreides@dune.io'`;
  }

  if (kind === "hubspot" || kind === "salesforce") {
    return `insert into cases (user_id, case_reason)
select users.id, 'Ordered defective pneumatic actuator assembly #C5'
from users
join orders on users.id = orders.user_id
where orders.order_date >= (now() - interval '90 days')
and orders.product_id = 'prod_a7hdy16sl';`;
  }
};

const getIncorrectInsertQuery = (kind: Resource["kind"]) => {
  if (kind === "airtable") {
    return `insert into contacts (first_name, last_name, email)
values ('Paul', 'Atreides', 'paul.atreides__dune.io')`;
  }

  if (kind === "hubspot") {
    return `insert into hubspot.contacts (first_name, last_name, email)
values ('Paul', 'Atreides', 'paul.atreides__dune.io')`;
  }

  if (kind === "salesforce") {
    return `insert into salesforce.contacts (first_name, last_name, email)
values ('Paul', 'Atreides', 'paul.atreides__dune.io')`;
  }
};
