import * as React from "react";
import { SVGProps, memo } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}

const SvgComponent = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    viewBox="0 0 363 259"
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    style={{
      fillRule: "evenodd",
      clipRule: "evenodd",
      strokeLinejoin: "round",
      strokeMiterlimit: 2,
    }}
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M173.82 40.5v112.86H80.34L173.82 40.5Zm0-40.5c0 25.641-21.099 46.74-46.74 46.74-25.641 0-46.74-21.099-46.74-46.74h93.48Zm15.4 153.37c0-25.641 21.099-46.74 46.74-46.74 25.641 0 46.74 21.099 46.74 46.74h-93.48Zm0-40.5V0h93.5l-93.5 112.86v.01Z"
      style={{
        fill: "currentColor",
        fillRule: "nonzero",
      }}
      transform="translate(-125.055) scale(1.68873)"
    />
  </svg>
);

const SvgZendeskFilled = memo(SvgComponent);
export default SvgZendeskFilled;
