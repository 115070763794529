import React from "react";

import { Database } from "lib/api/types";
import { Button } from "lib/lucidez";
import { Resource } from "lib/platforms";
import { CopyableCodeLine } from "components/core/CopyableCodeLine";
import { ContactUs } from "components/core/ContactUs";
import { MAILTO_LINK } from "site-constants";

export default function ResourceConnectionHelper({
  resource,
}: {
  resource: Resource;
}) {
  const { database } = resource;

  return (
    <div>
      <DatabaseCredentialsHelper database={database} />
    </div>
  );
}

const DatabaseCredentialsHelper = ({ database }: { database: Database }) => {
  if (database.user) {
    const conUrl = `postgresql://${database.user.username}:${database.user.password}@${database.definition.host}:${database.definition.port}/${database.definition.dbname}`;

    return (
      <div>
        <section>
          <h3 className="font-bold mb-2">Connect URL</h3>
          <CopyableCodeLine code={conUrl} />
        </section>
        <hr className="my-8" />
        <section>
          <h3 className="font-bold mb-2">
            Connect with <code>psql</code>
          </h3>
          <CopyableCodeLine code={"psql -d " + conUrl} />
        </section>
        <hr className="my-8" />
        <h3 className="font-bold mb-2">Connection Details</h3>
        <table>
          <tbody>
            <tr>
              <td className="font-bold pr-2">Host</td>
              <td>
                <CopyableCodeLine code={database.definition.host} discrete />
              </td>
            </tr>
            <tr>
              <td className="font-bold pr-2">Port</td>
              <td>
                <CopyableCodeLine
                  code={database.definition.port.toString()}
                  discrete
                />
              </td>
            </tr>
            <tr>
              <td className="font-bold pr-2">Database</td>
              <td>
                <CopyableCodeLine code={database.definition.dbname} discrete />
              </td>
            </tr>
            <tr>
              <td className="font-bold pr-2">Schema</td>
              <td>
                <CopyableCodeLine code={database.schema} discrete />
              </td>
            </tr>
            <tr>
              <td className="font-bold pr-2">User</td>
              <td>
                <CopyableCodeLine code={database.user.username} discrete />
              </td>
            </tr>
            <tr>
              <td className="font-bold pr-2">Password</td>
              <td>
                <CopyableCodeLine code={database.user.password} discrete />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  } else if (database.definition.type === "dedicated_postgres") {
    return (
      <div>
        <h3 className="font-bold">We're syncing to your database</h3>
        <table className="mt-2">
          <tr>
            <td className="font-bold pr-2">Host</td>
            <td>
              <CopyableCodeLine code={database.definition.host} discrete />
            </td>
          </tr>
          <tr>
            <td className="font-bold pr-2">Port</td>
            <td>
              <CopyableCodeLine
                code={database.definition.port.toString()}
                discrete
              />
            </td>
          </tr>
          <tr>
            <td className="font-bold pr-2">Database</td>
            <td>
              <CopyableCodeLine code={database.definition.dbname} discrete />
            </td>
          </tr>
          <tr>
            <td className="font-bold pr-2">Schema</td>
            <td>
              <CopyableCodeLine code={database.schema} discrete />
            </td>
          </tr>
          {database.readGroup && (
            <tr>
              <td className="font-bold pr-2">Read group</td>
              <td>
                <CopyableCodeLine code={database.readGroup} discrete />
              </td>
            </tr>
          )}
        </table>
        <p className="mt-2 text-sm">
          To connect to your database, you'll need a username and password.
          Don't have these? Talk to your database administrator or{" "}
          <ContactUs>talk to us.</ContactUs>
        </p>
        {database.readGroup && (
          <>
            <hr className="my-8" />
            <section>
              <h3 className="font-bold mb-2">Managing permissions</h3>
              <p>
                The <code>{database.readGroup}</code> role is provided for your
                convenience. The role has permission to read all the Sequin data
                in your database. You can give or revoke the read role
                permission to your database users at any time.
              </p>
              <p className="my-4">
                You can grant a user the ability to read from Sequin-managed
                schemas via the Sequin read role for this database:
              </p>
              <CopyableCodeLine
                code={`grant ${database.readGroup} to some_user;`}
              />
            </section>
          </>
        )}
      </div>
    );
  } else {
    return (
      <div className="text-center">
        <h3 className="font-bold">Hmm, that's odd.</h3>
        <p className="mt-1">
          We didn't find any login credentials for you to access this database.
        </p>
        <Button
          as="a"
          href={MAILTO_LINK}
          className="contact-us-trigger mt-4"
          variant="primary"
        >
          Contact us
        </Button>
      </div>
    );
  }
};
