import React from "react";
import Head from "next/head";
import { Link } from "react-router-dom";
import { Button } from "../../lib/lucidez";

export default function CheckoutSuccess() {
  return (
    <>
      <Head>
        <title>Success!</title>
      </Head>

      <main className="custom-container my-20 w-1/2 p-8 text-center">
        <div className="p-24 rounded-md bg-white dark:bg-gray-900 shadow-lg">
          <div className="text-3xl font-bold text-accent">Success!</div>
          <div className="text-m">
            You've signed up for a Sequin subscription.
          </div>
          <Link to="/">
            <Button variant="primary" size="xl" className="mt-8" as="a">
              Back to dashboard
            </Button>
          </Link>
        </div>
      </main>
    </>
  );
}
