import * as React from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}

function SvgComponent({
  title,
  titleId,
  ...props
}: React.SVGProps<SVGSVGElement> & SVGRProps) {
  return (
    <svg viewBox="0 0 128 132" fill="none" aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        d="M126.034 62.252 69.278 5.496 63.778 0 1.526 62.252a5.224 5.224 0 0 0 0 7.38l39.032 39.031 23.22 23.225 62.256-62.256a5.222 5.222 0 0 0 0-7.38ZM63.778 85.438 44.283 65.945l19.495-19.5 19.499 19.5-19.5 19.493Z"
        fill="currentColor"
      />
    </svg>
  );
}

const SvgJiraFilled = React.memo(SvgComponent);
export default SvgJiraFilled;
