import * as React from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}

const SvgComponent = ({
  title,
  titleId,
  ...props
}: React.SVGProps<SVGSVGElement> & SVGRProps) => {
  return (
    <svg viewBox="0 0 256 256" fill="none" aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <path fill="#9DE1F3" d="M85.334 85.333H0V256h170.667V85.333z" />
      <path fill="#27B4E1" d="M85.334 0v170.666H256V0z" />
      <path
        fill="#1A82E2"
        d="M0 256h85.333v-85.333H0zM170.667 85.333H256V0h-85.333z"
      />
      <path fill="currentColor" d="M85.334 170.667h85.333V85.334H85.334z" />
    </svg>
  );
};

export const SvgSendGridFilled = React.memo(SvgComponent);
