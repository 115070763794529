import React, { useState, useMemo } from "react";
import { Link } from "react-router-dom";
import classNames from "classnames";
import { match } from "ts-pattern";
import _ from "lodash";
import { formatDistanceToNow } from "date-fns";
import { Popover } from "@headlessui/react";
import { InformationCircleIcon } from "@heroicons/react/solid";
import {
  DotsCircleHorizontalIcon,
  RefreshIcon,
} from "@heroicons/react/outline";

import { ContactUs } from "components/core/ContactUs";
import { FriendlyDateTime } from "components/core/FriendlyDateTime";
import MarkdownRender from "components/core/MarkdownRender";
import {
  ResourceBackfill,
  ResourceCollectionStatus,
  ResourceTableSyncing,
  ResourceCompletedBackfill,
} from "lib/api/types";
import { Resource } from "lib/platforms";
import { useFilter } from "lib/utils/useFilter";
import { SyncLagTime } from "components/resources/SyncLagTime";
import { FilterBox } from "components/core/FilterBox";

interface CollectionsProps {
  backfills: ResourceBackfill[];
  collectionStatuses: ResourceCollectionStatus[];
  isResourceDisabled?: boolean;
  tables: ResourceTableSyncing[];
}

export const Collections = ({
  backfills,
  collectionStatuses,
  isResourceDisabled,
  tables,
}: CollectionsProps) => {
  const backfillsLookup = _.keyBy(backfills, "tableId");

  const [filter, setFilter] = useState("");

  const filteredTables = useFilter(tables, filter);
  const filteredTableIds =
    filter !== "" && tables
      ? filteredTables.map((table) => table.id)
      : collectionStatuses.map((collectionStatus) => collectionStatus.tableId);

  const filteredCollectionStatuses = useMemo(
    () =>
      collectionStatuses.filter((cS) => filteredTableIds.includes(cS.tableId)),
    [collectionStatuses, filteredTableIds]
  );
  const tablesLookup = _.keyBy(filteredTables, "id");

  return (
    <div
      className={classNames(
        "border rounded",
        isResourceDisabled ? "bg-cool-gray-50" : "bg-white"
      )}
    >
      <div className="px-6 pb-6 pt-3">
        <FilterBox
          filter={filter}
          setFilter={setFilter}
          label="Search collections"
        />
      </div>
      <table className="w-full border-collapse border-cool-gray-200">
        <thead className="h-7 text-cool-gray-500 text-xxs font-bold border-t border-b">
          <tr>
            <th className="lg:w-1/4 text-left pl-7">Status</th>
            <th className="lg:w-1/4 text-left">Collection</th>
            <th className="lg:w-1/4 text-left">Destination</th>
            <th className="lg:w-48 text-right pr-7 ">Latest activity</th>
          </tr>
        </thead>
        <tbody className="divide-y">
          {filteredCollectionStatuses.map((collectionStatus) => {
            const backfill = backfillsLookup[collectionStatus.tableId];
            const table = tablesLookup[collectionStatus.tableId];

            const isBackfilling = backfill && backfill.status !== "completed";

            return (
              <CollectionsItem
                key={collectionStatus.tableId}
                collectionStatus={collectionStatus}
                isBackfilling={isBackfilling}
                isResourceDisabled={isResourceDisabled}
                table={table}
              />
            );
          })}
        </tbody>
      </table>
      {filter === "" && filteredCollectionStatuses.length === 0 && (
        <div className="w-full h-80 flex flex-col justify-center items-center">
          <RefreshIcon className="w-6 h-6 text-cool-gray-400" />
          <p className="text-cool-gray-500 text-sm mt-2">
            No collections currently syncing
          </p>
        </div>
      )}
      {filter !== "" && filteredCollectionStatuses.length === 0 && (
        <div className="w-full h-80 flex flex-col justify-center items-center">
          <p className="text-cool-gray-500 text-sm mt-2">
            No collections found for "{filter}"
          </p>
        </div>
      )}
    </div>
  );
};

const CollectionsItem = ({
  collectionStatus,
  isBackfilling,
  isResourceDisabled,
  table,
}: {
  collectionStatus: ResourceCollectionStatus;
  isBackfilling?: boolean;
  isResourceDisabled?: boolean;
  table?: ResourceTableSyncing;
}) => {
  const shouldShowErrorMessage =
    !isResourceDisabled &&
    ["customer_intervention", "sequin_intervention", "degraded"].includes(
      collectionStatus?.level
    );

  return (
    <>
      <tr className="h-14 align-middle">
        <td className="pl-7">
          <div className="flex flex-row gap-2">
            <SyncLagTime
              lagTime={collectionStatus.averageCycleTimeMs}
              level={isResourceDisabled ? "disabled" : collectionStatus.level}
            />
            {isBackfilling && (
              <span className="text-xs text-violet-600 h-6 leading-5 px-4 border border-gray-300 rounded-full whitespace-nowrap">
                Backfill in progress
              </span>
            )}
          </div>
        </td>
        <td className="text-cool-gray-500 text-xs">
          {table?.providerName || collectionStatus.tableId}
        </td>
        <td className="text-purple-800 font-mono font-bold text-xs">
          {table?.dbName || collectionStatus.tableId}
        </td>
        <td className="pr-7 text-right w-48 text-cool-gray-500 text-xs">
          {isResourceDisabled ? (
            <>--</>
          ) : (
            <ActivityLabel collectionStatus={collectionStatus} />
          )}
        </td>
      </tr>
      {shouldShowErrorMessage && (
        <ErrorRow collectionStatus={collectionStatus} table={table} />
      )}
    </>
  );
};

interface NewlyCreatedBackfillsProps {
  resource: Resource;
}

export const NewlyCreatedBackfills = ({
  resource,
}: NewlyCreatedBackfillsProps) => {
  const isSequinHosted =
    resource.database.definition.type === "shared_postgres";

  const CTAText = isSequinHosted ? (
    <>
      In the meantime, you can see information about your database on the{" "}
      <Link
        className="link"
        to={`/syncs/${resource.permaslug}/connection-instructions`}
      >
        Connection instructions
      </Link>{" "}
      tab.
    </>
  ) : (
    <>
      You'll see tables appear in your{" "}
      <code className="code-block">{resource.database.schema}</code> schema
      shortly.
    </>
  );
  return (
    <div className="bg-white border rounded">
      <table className="w-full border-collapse border-cool-gray-200">
        <thead className="h-7 text-cool-gray-500 text-xxs font-bold border-t border-b">
          <tr>
            <th className="pl-7 w-1/5 text-left">Collection</th>
            <th className="w-1/5 text-left">Destination</th>
            <th className="w-1/5 text-left">Start time</th>
            <th className="pr-7 text-right w-1/5">Records processed</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td colSpan={4}>&nbsp;</td>
          </tr>
          <tr>
            <td colSpan={4} className="text-center">
              <DotsCircleHorizontalIcon className="w-8 h-8 text-violet-600 mx-auto" />
              Backfill initializing
            </td>
          </tr>
          <tr>
            <td colSpan={4}>&nbsp;</td>
          </tr>
          <tr>
            <td colSpan={4} className="text-center px-8">
              Your backfill is starting. We'll show backfill progress here in a
              bit. {CTAText}
            </td>
          </tr>
          <tr>
            <td colSpan={4}>&nbsp;</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

interface CompletedBackfillsProps {
  backfills: ResourceCompletedBackfill[];
  tables: ResourceTableSyncing[];
}

export const CompletedBackfills = ({
  backfills,
  tables,
}: CompletedBackfillsProps) => {
  const [filter, setFilter] = useState("");

  const filteredTables = useFilter(tables, filter);
  const filteredTableIds =
    filter !== "" && tables
      ? filteredTables.map((table) => table.id)
      : backfills.map((backfill) => backfill.tableId);

  const filteredBackfills = useMemo(
    () =>
      backfills.filter((backfill) =>
        filteredTableIds.includes(backfill.tableId)
      ),
    [backfills, filteredTableIds]
  );
  const tablesLookup = _.keyBy(filteredTables, "id");

  return (
    <div className="bg-white border rounded">
      <div className="px-6 pb-6 pt-3">
        <FilterBox
          filter={filter}
          setFilter={setFilter}
          label="Search backfills"
        />
      </div>
      <table className="w-full border-collapse border-cool-gray-200">
        <thead className="h-7 text-cool-gray-500 text-xxs font-bold border-t border-b">
          <tr>
            <th className="pl-7 w-1/5 text-left">Collection</th>
            <th className="w-1/5 text-left">Destination</th>
            <th className="w-1/5 text-left">Start time</th>
            <th className="w-1/5 text-left">Completed at</th>
            <th className="pr-7 text-right w-1/5">Records processed</th>
          </tr>
        </thead>
        <tbody className="divide-y">
          {filteredBackfills.map((backfill) => {
            const table = tablesLookup[backfill.tableId];

            return (
              <CompletedBackfillsItem
                key={backfill.tableId}
                backfill={backfill}
                table={table}
              />
            );
          })}
        </tbody>
      </table>
      {filter === "" && filteredBackfills.length === 0 && (
        <div className="w-full h-24 flex flex-col justify-center items-center">
          <p className="text-cool-gray-500 text-sm mt-2">
            No backfills completed yet
          </p>
        </div>
      )}
      {filter !== "" && filteredBackfills.length === 0 && (
        <div className="w-full h-80 flex flex-col justify-center items-center">
          <p className="text-cool-gray-500 text-sm mt-2">
            No backfills found for "{filter}"
          </p>
        </div>
      )}
    </div>
  );
};

const CompletedBackfillsItem = ({
  backfill,
  table,
}: {
  backfill: ResourceCompletedBackfill;
  table?: ResourceTableSyncing;
}) => {
  const formatter = new Intl.NumberFormat("en-US");

  return (
    <tr className="h-14 align-middle">
      <td className="pl-7">
        <div className="flex flex-row gap-2 text-xs text-cool-gray-500">
          {table?.providerName || backfill.tableId}
        </div>
      </td>
      <td className="text-purple-800 font-mono text-xs font-bold">
        {table?.dbName || backfill.tableId}
      </td>
      <td className="text-cool-gray-500 text-xs">
        <FriendlyDateTime
          iso8601={backfill.startedAt}
          mode="absolute-full-date"
        />
      </td>
      <td className="text-cool-gray-500 text-xs">
        <FriendlyDateTime
          iso8601={backfill.updatedAt}
          mode="absolute-full-date"
        />
      </td>
      <td className="pr-7 text-right w-48 text-cool-gray-500 text-xs">
        {formatter.format(backfill.rows)} rows modified
      </td>
    </tr>
  );
};

interface InProgressBackfillsProps {
  backfills: ResourceBackfill[];
  collectionStatuses: ResourceCollectionStatus[];
  tables: ResourceTableSyncing[];
}

export const InProgressBackfills = ({
  backfills,
  collectionStatuses,
  tables,
}: InProgressBackfillsProps) => {
  const collectionsStatusesLookup = _.keyBy(collectionStatuses, "tableId");
  const tablesLookup = _.keyBy(tables, "id");

  return (
    <div className="bg-white border rounded">
      <table className="w-full border-collapse border-cool-gray-200">
        <thead className="h-7 text-cool-gray-500 text-xxs font-bold border-t border-b">
          <tr>
            <th className="pl-7 w-1/5 text-left">Collection</th>
            <th className="w-1/5 text-left">Destination</th>
            <th className="w-1/5 text-left">Start time</th>
            <th className="pr-7 text-right w-1/5">Records processed</th>
          </tr>
        </thead>
        <tbody className="divide-y">
          {backfills.map((backfill) => {
            const collectionStatus =
              collectionsStatusesLookup[backfill.tableId];
            const table = tablesLookup[backfill.tableId];

            return (
              <InProgressBackfillsItem
                key={backfill.tableId}
                backfill={backfill}
                collectionStatus={collectionStatus}
                table={table}
              />
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

const InProgressBackfillsItem = ({
  backfill,
  collectionStatus,
  table,
}: {
  backfill: ResourceBackfill;
  collectionStatus: ResourceCollectionStatus;
  table?: ResourceTableSyncing;
}) => {
  const shouldShowErrorMessage = [
    "customer_intervention",
    "sequin_intervention",
    "degraded",
  ].includes(collectionStatus?.level);

  const formatter = new Intl.NumberFormat("en-US");

  return (
    <>
      <tr className="h-14 align-middle">
        <td className="pl-7 text-xs">
          <div className="flex flex-row items-center w-full">
            <span className="inline-block h-1.5 w-1.5 bg-violet-600 rounded-full mr-2 font-bold" />{" "}
            {table?.providerName || backfill.tableId}
          </div>
        </td>
        <td className="text-purple-800 font-mono text-xs">
          {table?.dbName || backfill.tableId}
        </td>

        <td className="text-cool-gray-500 text-xs">
          {backfill.status === "active" ? (
            <>
              <FriendlyDateTime
                iso8601={backfill.startedAt}
                mode="absolute-full-date"
              />
            </>
          ) : (
            <>Pending</>
          )}
        </td>
        <td className="pr-7 text-right w-48 text-cool-gray-500 text-xs">
          {backfill.status === "active" ? (
            <>{formatter.format(backfill.rows)} rows modified</>
          ) : (
            <>--</>
          )}
        </td>
      </tr>
      {shouldShowErrorMessage && (
        <ErrorRow collectionStatus={collectionStatus} table={table} />
      )}
    </>
  );
};

const ActivityLabel = ({
  collectionStatus,
}: {
  collectionStatus: ResourceCollectionStatus;
}) => {
  const shouldShowErrorMessage = collectionStatus?.level !== "healthy";

  const lastActivity = collectionStatus?.lastActivityAt
    ? new Date(collectionStatus.lastActivityAt)
    : null;

  const failingSince = collectionStatus?.failingSince
    ? new Date(collectionStatus.failingSince)
    : null;
  const erroringSinceFormatted =
    collectionStatus?.level !== "healthy" &&
    formatErroringSince(collectionStatus);

  const isDegraded = collectionStatus?.level === "degraded";

  if (shouldShowErrorMessage && erroringSinceFormatted) {
    return (
      <div className="flex justify-end items-end w-full">
        <span
          className={classNames(
            "font-bold",
            isDegraded ? "text-yellow-500" : "text-red-500"
          )}
        >
          {erroringSinceFormatted}
        </span>
        {failingSince && (
          <ErroringExplanationPopover
            label={isDegraded ? "degraded" : "error"}
            failingSince={failingSince}
          />
        )}
      </div>
    );
  }

  if (lastActivity) {
    return (
      <div className="flex justify-end items-end w-full">
        <span className="inline-block w-full">
          <FriendlyDateTime iso8601={collectionStatus.lastActivityAt} /> ago
        </span>
        <span className="inline-block text-left">
          <ActivityExplanationPopover lastActivity={lastActivity} />
        </span>
      </div>
    );
  }

  return <>--</>;
};

interface JobStatusProps {
  collectionStatus: ResourceCollectionStatus;
  table?: ResourceTableSyncing;
}

const ErrorRow = ({ collectionStatus, table }: JobStatusProps) => {
  return (
    <tr>
      <td colSpan={4} className="bg-cool-gray-50 px-7 py-4 text-xs">
        {collectionStatus.level === "degraded" ? (
          <DegradedJobStatus
            collectionStatus={collectionStatus}
            table={table}
          />
        ) : (
          <ErrorJobStatus collectionStatus={collectionStatus} table={table} />
        )}
      </td>
    </tr>
  );
};

const DegradedJobStatus = ({ collectionStatus, table }: JobStatusProps) => {
  const jobKindRetrieval = match(collectionStatus.jobKind)
    .with("backfill", () => "backfilling")
    .with("delta", () => "getting updates for")
    .run();

  const tableDisplayName = table?.providerName
    ? table.providerName
    : collectionStatus.tableId;

  return (
    <div className="text-xs">
      <p>
        We're having trouble {jobKindRetrieval} the collection{" "}
        <b>{tableDisplayName}</b>.
      </p>
      <p className="font-bold mt-2">
        {collectionStatus.message} <ContactUs>Contact us</ContactUs> for help
        and more information.
      </p>
      {collectionStatus.message && collectionStatus.instructions ? (
        <div className="mt-2">
          <CollectionError
            label="degraded"
            message={collectionStatus.message}
            instructions={collectionStatus.instructions}
          />
        </div>
      ) : null}
    </div>
  );
};

const ErrorJobStatus = ({ collectionStatus, table }: JobStatusProps) => {
  const jobKindRetrieval = match(collectionStatus.jobKind)
    .with("backfill", () => "backfilling")
    .with("delta", () => "getting updates for")
    .run();

  const tableDisplayName = table?.providerName
    ? table.providerName
    : collectionStatus.tableId;

  return (
    <div className="text-xs">
      <p>
        We're having trouble {jobKindRetrieval} the collection{" "}
        <b>{tableDisplayName}</b>.
      </p>
      <p className="font-bold mt-2">
        {collectionStatus.level === "customer_intervention" ? (
          "These errors require your action:"
        ) : (
          <>
            {collectionStatus.message} <ContactUs>Contact us</ContactUs> for
            help and more information.
          </>
        )}
      </p>
      {collectionStatus.message && collectionStatus.instructions ? (
        <div className="mt-2">
          <CollectionError
            label="error"
            message={collectionStatus.message}
            instructions={collectionStatus.instructions}
          />
        </div>
      ) : null}
    </div>
  );
};

interface CollectionErrorProps {
  label: "error" | "degraded";
  message?: string;
  instructions?: string;
}

const CollectionError = ({
  label,
  message,
  instructions,
}: CollectionErrorProps) => {
  return (
    <div className="mt-4">
      <div>
        {label === "error" ? (
          <span className="text-red-600 font-bold">Error:</span>
        ) : (
          <span className="text-yellow-600 font-bold">Degraded:</span>
        )}{" "}
        <span>{message}</span>
      </div>
      {instructions && (
        <div className="mt-1">
          <MarkdownRender>{instructions}</MarkdownRender>
        </div>
      )}
    </div>
  );
};

interface ActivityExplanationPopoverProps {
  lastActivity: Date;
}

const ActivityExplanationPopover = ({
  lastActivity,
}: ActivityExplanationPopoverProps) => {
  return (
    <Popover className="relative flex items-center ml-1">
      <Popover.Button>
        <InformationCircleIcon className="w-4 h-4 text-cool-gray-400" />
      </Popover.Button>
      <Popover.Panel className="absolute z-10 border border-gray-200 bg-white p-4 shadow-lg rounded w-64 right-0 top-9">
        <p>
          The last time we created, updated, or deleted a record in this
          collection was{" "}
          <a className="link" title={lastActivity.toLocaleString()}>
            {formatDistanceToNow(lastActivity)} ago.
          </a>{" "}
        </p>
        <p className="mt-4">
          This can be blank for syncs that haven't had a change in source data.
        </p>
      </Popover.Panel>
    </Popover>
  );
};

interface ErroringExplanationPopoverProps {
  failingSince: Date;
  label: "error" | "degraded";
}

const ErroringExplanationPopover = ({
  failingSince,
  label,
}: ErroringExplanationPopoverProps) => {
  const caption =
    label === "error"
      ? "This collection has failed to complete syncing since"
      : "This collection has been completing syncs slower than normal since";

  return (
    <Popover className="relative flex items-center ml-1">
      <Popover.Button>
        <InformationCircleIcon
          className={classNames(
            "w-4 h-4",
            label === "error" ? "text-red-500" : "text-yellow-500"
          )}
        />
      </Popover.Button>
      <Popover.Panel className="absolute z-10 border border-gray-200 bg-white p-4 shadow-lg rounded w-64 right-0 top-7">
        <p>
          {caption} {failingSince.toLocaleString()}.
        </p>
      </Popover.Panel>
    </Popover>
  );
};

const formatErroringSince = (
  status: ResourceCollectionStatus
): React.ReactElement | null => {
  const isErroring = ["customer_intervention", "sequin_intervention"].includes(
    status.level
  );

  const isDegraded = status.level === "degraded";

  if (isErroring || isDegraded) {
    const label = isDegraded ? "Degraded" : "Erroring";

    if (!status.failingSince) {
      return <>{label}</>;
    }

    const erroringSinceDate = new Date(status.failingSince);

    return (
      <>
        {label} as of {formatDistanceToNow(erroringSinceDate)} ago
      </>
    );
  }

  return null;
};
