import React from "react";

interface ThemedBrandProps {
  className?: string;
}

export default function ThemedBrand({ className = "h-8" }: ThemedBrandProps) {
  return (
    <div>
      <img
        className={`dark:hidden ${className}`}
        src="/static/brand_black.svg"
        alt="Sequin"
      />
      <img
        className={`hidden dark:inline-block ${className}`}
        src="/static/brand_white.svg"
        alt="Sequin"
      />
    </div>
  );
}
