import React, { useEffect, useState } from "react";
import { useController, useWatch } from "react-hook-form";
import { useStateMachine } from "little-state-machine";
import { CheckCircleIcon } from "@heroicons/react/outline";
import classNames from "classnames";

import { getPlatformForKind } from "lib/platforms";
import { BaseState } from "lib/platforms/base";

import {
  OnboardFormProvider,
  OnboardLayout,
} from "components/onboard/OnboardLayout";
import { ExternalDatabaseSetup } from "components/platforms/common/forms/DestinationStep/ExternalDatabaseSetup";

export const DestinationSelection = () => {
  return (
    <OnboardFormProvider>
      <UnprovidedDestinationSelection />
    </OnboardFormProvider>
  );
};

const UnprovidedDestinationSelection = () => {
  const { state } = useStateMachine();
  const platformFromState = state["onboardState"].platformKind;
  const platform = platformFromState && getPlatformForKind(platformFromState);

  const resourceName = useWatch<BaseState<any>>({ name: "name" });
  const { field } = useController<BaseState<any>>({
    name: "destination",
    rules: {
      validate: (destination) =>
        destination.type !== "placeholder" &&
        (destination.type === "new" || destination.validSchema),
    },
  });
  const destination = field.value;

  const [stepIsCompleted, setStepIsCompleted] = useState(
    destination?.type === "new"
  );
  const [isShowingExternalDBSetup, setIsShowingExternalDBSetup] =
    useState<boolean>(false);

  const isSelfHostedAndValid =
    destination?.type === "external" && destination?.validSchema;

  const handleFinishFlow = (wasCancelled) => {
    if (!wasCancelled && window.analytics) {
      window.analytics.track("Destination Flow Completed", {
        destinationType: destination.type,
      });
    }

    setStepIsCompleted(true);
    setIsShowingExternalDBSetup(false);
  };

  const handleSequinHostedClick = () => {
    field.onChange({ type: "new" });

    if (window.analytics) {
      window.analytics.track("Destination Flow Completed", {
        destinationType: "new",
      });
    }

    setStepIsCompleted(true);
    setIsShowingExternalDBSetup(false);
  };

  const handleSelfHostedClick = () => {
    if (isSelfHostedAndValid) {
      return;
    }

    setStepIsCompleted(false);
    setIsShowingExternalDBSetup(true);
  };

  const handleChangeDestination = (destination) => {
    field.onChange(destination);
  };

  const handleClose = () => {
    setIsShowingExternalDBSetup(false);
    if (destination.type === "new") {
      setStepIsCompleted(true);
    }
  };

  useEffect(() => {
    if (isSelfHostedAndValid) {
      setStepIsCompleted(true);
      setIsShowingExternalDBSetup(false);
    }
  }, []);

  const activeSelection = "bg-cool-gray-50 border-cool-gray-400";

  const hasSetupExternalDB = destination.type === "external" && stepIsCompleted;

  return (
    <OnboardLayout nextButtonIsActive={stepIsCompleted}>
      {stepIsCompleted && (
        <div className="flex justify-center mb-6">
          <CheckCircleIcon className="w-12 h-12 mr-2 text-emerald-600 rounded-full" />
        </div>
      )}

      <h1 className="text-lg text-center mb-2">
        You'll start with a pre-configured Sequin dev database.
      </h1>
      <p className="text-sm mb-12 text-center">
        <b>You can always change this later.</b>
      </p>
      <div className="flex justify-center">
        <button
          onClick={handleSequinHostedClick}
          className={classNames(
            "flex flex-col items-center mx-12 w-2/5 max-w-[400px] p-10 border border-cool-gray-200 rounded-lg",
            { [activeSelection]: destination.type === "new" }
          )}
        >
          <div className="flex mb-4 text-emerald-600 items-center">
            {destination.type === "new" && (
              <>
                <div className="rounded-full w-2 h-2 bg-emerald-600"></div>
                <h2 className="ml-4 text-bold">Connected</h2>
              </>
            )}
          </div>
          <h3 className="mb-4 text-lg font-bold">Sequin database</h3>
          <p className="mb-4">
            Launch a new, pre-configured database on a shared Sequin instance.
          </p>
          (easiest)
        </button>
        <button
          onClick={handleSelfHostedClick}
          className={classNames(
            "flex flex-col items-center mx-12 w-2/5 max-w-[400px] p-10 border border-cool-gray-200 rounded-lg",
            { [activeSelection]: destination.type === "external" }
          )}
        >
          <div className="flex mb-4 text-emerald-600 items-center">
            {hasSetupExternalDB && (
              <>
                <div className="rounded-full w-2 h-2 bg-emerald-600"></div>
                <h2 className="ml-4 text-bold">Connected</h2>
              </>
            )}
          </div>
          <h3 className="mb-4 text-lg font-bold">Cloud database</h3>
          <p className="mb-4">
            Connect sequin to an existing Postgres database with credentials you
            provide.
          </p>
          (still very easy)
        </button>
        {isShowingExternalDBSetup && (
          <ExternalDatabaseSetup
            resourceName={resourceName}
            platformName={platform?.displayName}
            resourceKind={platform?.kind}
            destination={destination}
            onDestinationChange={handleChangeDestination}
            onFinishFlow={handleFinishFlow}
            databases={[]}
            onClose={handleClose}
          />
        )}
      </div>
    </OnboardLayout>
  );
};
