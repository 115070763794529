import Head from "next/head";
import React, { useRef, useState } from "react";
import { getReCaptchaToken, requestPasswordReset } from "../../lib/api";
import { toast } from "react-toastify";
import { FormUtils } from "../../lib/utils";
import ReCAPTCHA from "react-google-recaptcha";
import { Button } from "../../lib/lucidez";

const { bindEnterKeyPress } = FormUtils;

enum WorkingState {
  none,
  error,
  working,
  sent,
}

export default function ForgotPassword() {
  const [workingState, setWorkingState] = useState(WorkingState.none);
  const working = workingState === WorkingState.working;

  const [email, setEmail] = useState("");

  const recaptchaRef = useRef<ReCAPTCHA>();

  const handleReset = async () => {
    setWorkingState(WorkingState.working);

    const token = await recaptchaRef.current.executeAsync();

    const result = await requestPasswordReset(email, token);

    if (result.isOk()) {
      setWorkingState(WorkingState.sent);
    } else {
      if (result.error?.error) {
        toast.error(result.error.error.summary, {
          toastId: "password-reset-error",
        });
      } else {
        toast.error("Something went wrong", {
          toastId: "password-reset-unknown-error",
        });
      }

      setWorkingState(WorkingState.error);
    }
  };

  return (
    <>
      <Head>
        <title>Forgot Password - Sequin</title>
      </Head>
      <div>
        <h2 className="text-2xl font-bold">Forgot Password?</h2>
        {workingState === WorkingState.sent ? (
          <div className="bg-blue-500 w-full py-2 px-4 rounded text-white mt-2">
            A reset password link was sent to your email.
          </div>
        ) : (
          <div className="flex flex-col gap-6">
            <p>Insert your email below to reset your password:</p>
            <input
              placeholder="Email"
              className="auth-input"
              disabled={working}
              name="email"
              value={email}
              onChange={({ target: { value } }) => setEmail(value)}
              onKeyPress={bindEnterKeyPress(handleReset)}
            ></input>
            <Button
              size="xl"
              variant="primary"
              isLoading={working}
              onClick={handleReset}
              className="justify-center"
              iconLeft={<></>}
              iconRight={<></>}
            >
              Continue
            </Button>
            <ReCAPTCHA
              ref={recaptchaRef}
              size="invisible"
              sitekey={getReCaptchaToken()}
            />
          </div>
        )}
      </div>
    </>
  );
}
