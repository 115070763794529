import * as React from "react";
import { SVGProps, memo } from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}

const SvgComponent = ({
  title,
  titleId,
  ...props
}: SVGProps<SVGSVGElement> & SVGRProps) => (
  <svg
    viewBox="0 0 550 550"
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    style={{
      fillRule: "evenodd",
      clipRule: "evenodd",
      strokeLinejoin: "round",
      strokeMiterlimit: 2,
    }}
    aria-labelledby={titleId}
    {...props}
  >
    {title ? <title id={titleId}>{title}</title> : null}
    <path
      d="M106.107 155.98c-.926-1.106-2.171-1.922-3.689-2.452-1.56-.545-3.447-.807-5.668-.807-2.094 0-4.486.232-7.186.695l-30.978 5.295c.328-2.387 1.513-5.524 3.604-9.347 2.334-4.285 5.775-9.43 10.321-15.419l.003-.003 3.577-4.778c.592-.731 1.416-1.81 2.483-3.235 7.086-9.296 11.473-16.865 13.155-22.807a47.79 47.79 0 0 0 1.759-10.18c.058-.936.084-1.865.084-2.787 0-2.45-.2-4.862-.606-7.236-.369-2.162-.901-3.962-1.615-5.405-.714-1.441-1.62-2.537-2.739-3.228-1.262-.769-3.036-1.094-5.358-1.098-1.917 0-4.216.233-6.907.691l-35.8 6.126c-4.335.743-7.537 2.019-9.579 3.941-1.623 1.524-2.462 3.496-2.453 5.759 0 .592.054 1.203.163 1.836.503 3.042 2.089 5.272 4.644 6.407 1.509.681 3.332 1.006 5.459 1.006 1.449 0 3.041-.152 4.78-.448l29.964-5.053c.051.53.086 1.058.086 1.583 0 1.872-.324 3.7-.974 5.499-.889 2.48-3.112 6.026-6.653 10.533a744.008 744.008 0 0 0-4.612 5.745c-8.08 9.788-13.868 18.134-17.37 25.076l-.003.009c-2.486 4.778-4.212 9.485-5.158 14.118-.551 2.702-.83 5.297-.83 7.786 0 1.783.143 3.508.429 5.179.41 2.396 1.002 4.373 1.799 5.939.796 1.56 1.813 2.725 3.067 3.415 1.168.634 2.885.884 5.247.892 3 0 7.038-.443 12.148-1.315l32.484-5.553c5.752-.991 9.753-2.363 12.077-4.272 1.858-1.525 2.821-3.621 2.811-6.088 0-.624-.058-1.269-.174-1.94-.273-1.602-.867-2.976-1.792-4.079Zm-27.532-30.054.002-.001-.003.004h-.002l.003-.003Z"
      style={{
        fill: "currentColor",
        fillRule: "nonzero",
      }}
      transform="rotate(9.37 1771.569 404.566) scale(4.0811)"
    />
  </svg>
);

const SvgZohoFilled = memo(SvgComponent);
export default SvgZohoFilled;
