import React from "react";

export default function Divider({ children }: React.PropsWithChildren<{}>) {
  return (
    <div className="w-full relative flex justify-center">
      <span className="bg-white z-10 px-1 text-gray-400">{children}</span>
      <hr className="w-full border-gray-400 absolute top-1/2" />
    </div>
  );
}
