import React from "react";
import { Link } from "react-router-dom";

export const NotFound = () => {
  return (
    <div className="grid min-w-full min-h-screen place-content-center">
      <div className="flex flex-col items-center gap-2">
        <h1 className="font-bold text-base">404 - Page not found</h1>
        <Link className="link max-w-fit" to="/">
          Return to console
        </Link>
      </div>
    </div>
  );
};

export const FullPageSyncNotFound = () => {
  return (
    <div className="grid min-w-full h-96 place-content-center bg-white">
      <div className="flex flex-col items-center gap-2">
        <h1 className="font-bold text-base">That sync wasn't found</h1>
        <Link className="link max-w-fit" to="/">
          Return to syncs list
        </Link>
      </div>
    </div>
  );
};
