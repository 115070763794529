import * as React from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}

function SvgComponent({
  title,
  titleId,
  ...props
}: React.SVGProps<SVGSVGElement> & SVGRProps) {
  return (
    <svg viewBox="0 0 49 49" fill="none" aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 11C0 4.925 4.925 0 11 0h27c6.075 0 11 4.925 11 11v27c0 6.075-4.925 11-11 11H11C4.925 49 0 44.075 0 38V11Zm20.188 18.952H17v5.796h3.188v-5.796ZM23.306 14c2.802 0 3.988.152 5.7 1.063C30.855 16.07 32 18.043 32 20.513c0 2.277-.925 4.25-2.718 5.437-1.643 1.09-3.506 1.242-6.003 1.242H17V14h6.306Zm-3.118 10.432h3.822c2.677 0 4.706-1.076 4.706-3.891 0-2.622-1.642-3.781-4.927-3.74l-3.601.028v7.603Z"
        fill="currentColor"
      />
    </svg>
  );
}

const SvgPagerDutyFilled = React.memo(SvgComponent);
export default SvgPagerDutyFilled;
