import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { mutate } from "swr";
import { useNavigate, useSearchParams } from "react-router-dom";
import { FormProvider, useForm, useFormState } from "react-hook-form";

import { createResource } from "lib/api";
import { getPlatformForKind, ResourceKind } from "lib/platforms";
import Drawer from "components/core/Drawer";
import Steps from "components/core/Steps";
import ResourceForm from "components/platforms/common/ResourceForm";
import { PlatformList } from "components/PlatformList";
import { SyncAllQuirkProvider } from "components/platforms/common/forms/SelectTablesStep";
import { Button } from "lib/lucidez";
import { useCreateResourceStatus, useResource } from "lib/api/hooks";
import {
  AsyncValidationProvider,
  useAsyncValidation,
} from "components/platforms/common/forms/util";
import { ConfirmPendingChanges } from "lib/utils/ConfirmPendingChanges";

export const NewSync = () => {
  const [query] = useSearchParams();
  const navigate = useNavigate();

  const handleClose = () => navigate("/");

  const step = query.has("kind") ? "new" : "kind";

  return (
    <Drawer onClose={handleClose} title={query.get("kind") && "Add sync"}>
      <Steps activeStep={step} key={step}>
        <Steps.Step stepKey="kind" className="h-full w-full">
          <SelectKind />
        </Steps.Step>
        <Steps.Step stepKey="new" className="h-full w-full">
          <NewSyncForm kind={query.get("kind") as any} onClose={handleClose} />
        </Steps.Step>
      </Steps>
    </Drawer>
  );
};

const SelectKind = () => {
  const buildPlatformLink = (kind: string) => `/syncs/new?kind=${kind}`;

  return (
    <div className="relative h-full flex overflow-auto">
      <div className="absolute flex-1 flex flex-col h-full max-h-full w-full items-center">
        <Drawer.Title className="font-bold text-2xl">
          Which platform?
        </Drawer.Title>
        <div className="w-full px-16 my-8 pb-8">
          <PlatformList
            buildPlatformLink={buildPlatformLink}
            showUpcomingPlatforms
            siteLocation="new_source"
          />
        </div>
      </div>
    </div>
  );
};

const NewSyncForm = ({
  kind,
  onClose,
}: {
  kind: ResourceKind;
  onClose: () => void;
}) => {
  const navigate = useNavigate();
  const platform = getPlatformForKind(kind);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [createResourceJobId, setCreateResourceJobId] = useState<string | null>(
    null
  );
  const [createResourceStatus] = useCreateResourceStatus(createResourceJobId, {
    refreshInterval: 2500,
  });
  const createdResourceId =
    createResourceStatus && createResourceStatus.state === "completed"
      ? createResourceStatus.resource_id
      : null;
  const [createdResource] = useResource(createdResourceId, {
    waitUntilLoaded: true,
  });

  const formMethods = useForm<any>({
    defaultValues: platform.getInitialCreateState(),
    mode: "all",
  });

  const onSubmit = async (data) => {
    setIsSubmitting(true);

    const forApi = platform.prepareFieldsForCreate(data);
    const response = await createResource(forApi);

    if (response.isOk()) {
      setCreateResourceJobId(response.value.job_id);
    } else if (JSON.stringify(response).includes("is banned")) {
      toast.error(
        "This Airtable account has been banned from accessing Sequin. If you think this was an error, please contact us.",
        { toastId: "airtable-account-banned" }
      );
      setIsSubmitting(false);
    } else if (response.error.error?.summary) {
      toast.error(response.error.error?.summary, { toastId: "new-sync-error" });
      setIsSubmitting(false);
    } else {
      // handle server errors not related to validation
      toast.error("An unknown error happened.", {
        toastId: "new-sync-unknown-error",
      });
      setIsSubmitting(false);
    }
  };

  useEffect(() => {
    if (createdResource) {
      setIsSubmitting(false);

      navigate(`/syncs/${createdResource.permaslug}/backfills`, {
        state: {
          isBackfilling: true,
        },
      });
    }
  }, [createdResource]);

  useEffect(() => {
    mutate("/api/resources");
  }, [createdResourceId]);

  return (
    <SyncAllQuirkProvider allowSyncAll={false}>
      <AsyncValidationProvider>
        <FormProvider {...formMethods}>
          <form
            className="relative h-full flex"
            onSubmit={(e) => {
              e.preventDefault();
            }}
          >
            <div className="absolute flex-1 flex flex-col h-full max-h-full w-full">
              <div className="flex-1 overflow-auto px-8 pb-8">
                <div className="py-8">
                  <h4 className="text-xl">
                    Connect Sequin to your {platform.displayName}{" "}
                    {platform.resourceAppositive}
                  </h4>
                  <p className="text-xs mt-2">This will take 2 minutes.</p>
                </div>
                <ResourceForm kind={platform.kind} isCreate />
              </div>
              <ActionArea
                onCancel={onClose}
                onSave={() => {
                  setIsSubmitting(true);
                  formMethods.handleSubmit(onSubmit)();
                }}
                isSubmitting={isSubmitting}
              />
            </div>
          </form>
        </FormProvider>
      </AsyncValidationProvider>
      <ConfirmPendingChanges
        hasPendingChanges={formMethods.formState.isDirty && !createdResourceId}
      />
    </SyncAllQuirkProvider>
  );
};

const ActionArea = ({
  onCancel,
  onSave,
  isSubmitting,
}: {
  onCancel: () => void;
  onSave: () => void;
  isSubmitting: boolean;
}) => {
  const { isValid, errors } = useFormState();
  const hasErrors = Object.keys(errors).length > 0;

  const { isAnyValidationRunning } = useAsyncValidation();

  return (
    <div className="flex flex-row p-4 border-t bg-white">
      <div className="flex-1" />
      <Button variant="outlined" size="md" onClick={onCancel}>
        Cancel
      </Button>
      <Button
        variant="primary"
        size="md"
        onClick={onSave}
        disabled={
          isAnyValidationRunning || !isValid || hasErrors || isSubmitting
        }
        isLoading={isSubmitting}
        className="ml-2"
      >
        Create
      </Button>
    </div>
  );
};
