import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { FormProvider, useForm } from "react-hook-form";
import { XCircleIcon } from "@heroicons/react/solid";
import isEmpty from "lodash/isEmpty";
import { toast } from "react-toastify";

import {
  testAndCreateSshTunnel,
  testExistingTunnel,
  testReachability,
  updateExternalDatabase,
  UpdateExternalDatabaseParams,
} from "lib/api";
import { DatabaseWithResources } from "lib/platforms";
import { useDatabases } from "lib/api/hooks";
import { Button } from "lib/lucidez";
import Modal from "components/core/Modal";
import {
  handleExternalSetupFormErrors,
  hostPortExternalDbFormDefaultValues,
} from "components/platforms/common/forms/DestinationStep/ExternalDatabaseSetup";
import {
  DatabaseNameForm,
  DatabaseNameFormInputs,
} from "components/platforms/common/forms/DestinationStep/ExternalDatabaseSetup/DatabaseNameForm";
import {
  HostPortExternalDbFormInputs,
  HostSection,
} from "components/platforms/common/forms/DestinationStep/ExternalDatabaseSetup/HostSection";
import {
  CredentialsForm,
  CredentialsFormInputs,
  DatabaseInfoForm,
} from "components/platforms/common/forms/DestinationStep/ExternalDatabaseSetup/CredentialsForm";
import { SshTunnelSection } from "components/platforms/common/forms/DestinationStep/ExternalDatabaseSetup/SshTunnelSection";
import MarkdownRender from "components/core/MarkdownRender";
import { SshTunnel } from "lib/api/types";

interface SshTunnelDetails {
  useTunnel: "none" | "new" | "existing";
  id?: string;
  host: string;
  port: number;
  user: string;
}

interface HostDetails {
  host: string;
  port: number;
}

export const EditDatabase = () => {
  const [databases, refreshDatabases] = useDatabases();
  const [fatalError, setFatalError] = useState<string>();
  const { databaseId } = useParams();
  const navigate = useNavigate();

  if (!databases) {
    return null;
  }

  const database = databases.find((db) => db.id === databaseId);

  if (!database) {
    return null;
  }

  const handleClose = () => {
    navigate("/databases");
  };

  return (
    <Modal
      onClose={handleClose}
      containerClassName="min-h-screen grid place-content-center"
      cardClassName="transform bg-white rounded shadow-xl max-w-xl w-96 p-5"
    >
      <div className="flex justify-end">
        <button onClick={handleClose}>
          <XCircleIcon className="h-5 w-5" aria-hidden="true" />
        </button>
      </div>
      {fatalError && (
        <div className="flex justify-between items-center bg-red-100 text-error rounded p-2 my-2">
          <span>
            <MarkdownRender>{fatalError}</MarkdownRender>
          </span>
        </div>
      )}
      <DatabaseForm
        database={database}
        setFatalError={setFatalError}
        refreshDatabases={refreshDatabases}
        handleClose={handleClose}
      />
    </Modal>
  );
};

interface DatabaseFormProps {
  database: DatabaseWithResources;
  setFatalError: (string) => void;
  refreshDatabases: () => void;
  handleClose: () => void;
}

const DatabaseForm = ({
  database,
  setFatalError,
  refreshDatabases,
  handleClose,
}: DatabaseFormProps) => {
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const isSequinHosted = database.definition.type === "shared_postgres";
  const hostPortFormMethods = useForm<HostPortExternalDbFormInputs>({
    defaultValues: hostPortExternalDbFormDefaultValues(
      {
        host: database.definition.host,
        port: database.definition.port,
      },
      {
        useTunnel: database.sshTunnelId ? "existing" : "none",
        id: database.sshTunnelId,
        host: "",
        port: 22,
        user: "",
      }
    ),
  });
  const { dirtyFields: hostPortDirtyFields } = hostPortFormMethods.formState;

  const dbCredentialsFormMethods = useForm<CredentialsFormInputs>({
    defaultValues: {
      dbname: database.definition.dbname,
      schema: "",
      user: database.definition.user,
      password: "",
      ssl: database.definition.ssl,
    },
  });
  const { dirtyFields: dbCredentialsDirtyFields } =
    dbCredentialsFormMethods.formState;

  const dbNameFormMethods = useForm<DatabaseNameFormInputs>({
    defaultValues: {
      name: database.name,
    },
  });
  const { dirtyFields: dbNameFormDirtyFields } = dbNameFormMethods.formState;

  const isCleanForm =
    isEmpty(hostPortDirtyFields) &&
    isEmpty(dbCredentialsDirtyFields) &&
    isEmpty(dbNameFormDirtyFields);

  const handleSave = async () => {
    if (!database) {
      return;
    }
    setFatalError(undefined);

    const hostForm = hostPortFormMethods.getValues();
    const credentialsForm = dbCredentialsFormMethods.getValues();
    const dbNameForm = dbNameFormMethods.getValues();

    let newTunnel: SshTunnel;
    const isNewTunnel = hostForm.sshTunnel.useTunnel === "new";

    if (isNewTunnel) {
      if (!hostForm.sshTunnel.host) {
        hostPortFormMethods.setError("sshTunnel.host", {
          message: "Host is required",
        });
        return;
      }

      if (!hostForm.sshTunnel.user) {
        hostPortFormMethods.setError("sshTunnel.user", {
          message: "User is required",
        });
        return;
      }
    }

    const testOrCreate = async (
      sshTunnelDetails: SshTunnelDetails,
      hostDetails: HostDetails
    ) => {
      if (sshTunnelDetails.useTunnel === "new") {
        return await testAndCreateSshTunnel({
          tunnel: sshTunnelDetails,
          ...hostDetails,
        });
      } else if (sshTunnelDetails.useTunnel === "existing") {
        return await testExistingTunnel(sshTunnelDetails.id, hostDetails);
      } else {
        return await testReachability(hostDetails);
      }
    };

    if (isNewTunnel) {
      try {
        setIsSaving(true);
        const res = await testOrCreate(
          { ...hostForm.sshTunnel, port: Number(hostForm.sshTunnel.port) },
          { ...hostForm.host, port: Number(hostForm.host.port) }
        );
        if (res.isErr()) {
          handleExternalSetupFormErrors(
            res.error,
            hostPortFormMethods.setError as any,
            (error) => {
              setFatalError(error);
              toast.error(error, { toastId: "external-setup-error" });
            },
            { ...hostForm, ...credentialsForm }
          );
          return;
        } else if (res.isOk() && res.value.ok) {
          newTunnel = (res.value as any).tunnel as SshTunnel;
        }
      } catch (e) {
        return;
      } finally {
        setIsSaving(false);
      }
    }

    const body: UpdateExternalDatabaseParams = {
      ssh_tunnel_id: isNewTunnel ? newTunnel?.id : hostForm.sshTunnel.id,
      name: dbNameFormDirtyFields.name ? dbNameForm.name : undefined,
      definition: {
        dbname: dbCredentialsDirtyFields.dbname
          ? credentialsForm.dbname
          : undefined,
        user: dbCredentialsDirtyFields.user ? credentialsForm.user : undefined,
        password: dbCredentialsDirtyFields.password
          ? credentialsForm.password
          : undefined,
        port: hostForm.host.port,
        host: hostForm.host.host,
        ssl: dbCredentialsDirtyFields.ssl ? credentialsForm.ssl : undefined,
      },
    };

    try {
      setIsSaving(true);
      const response = await updateExternalDatabase(database.id, body);

      if (response.isOk()) {
        toast.success("Successfully updated database", {
          toastId: "database-update-success",
        });
        refreshDatabases();
        handleClose();
      } else {
        handleExternalSetupFormErrors(
          response.error,
          dbCredentialsFormMethods.setError as any,
          setFatalError,
          { ...hostForm, ...credentialsForm }
        );
      }
    } catch (err) {
      toast.error("Unexpected error, try again later", {
        toastId: "database-error-unexpected",
      });
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <form
      className="flex flex-col gap-4"
      onSubmit={(event) => {
        event.preventDefault();
        handleSave();
      }}
    >
      <FormProvider {...dbNameFormMethods}>
        <DatabaseNameForm />
      </FormProvider>

      <hr />

      <FormProvider {...hostPortFormMethods}>
        <HostSection disabled={isSequinHosted} />
        <div className="p-3 border rounded ">
          <span className="font-bold">SSH Tunnel</span>
          <SshTunnelSection disabled={isSequinHosted} />
        </div>
      </FormProvider>

      <FormProvider {...dbCredentialsFormMethods}>
        <DatabaseInfoForm disabled={isSequinHosted} hideSchema />
        {!isSequinHosted && (
          <CredentialsForm disabled={isSequinHosted} isEdit />
        )}
      </FormProvider>

      <Button
        variant="primary"
        size="md"
        className="w-full justify-center"
        type="submit"
        disabled={isCleanForm}
        isLoading={isSaving}
      >
        Save
      </Button>
    </form>
  );
};
