import React from "react";
import { useFormContext } from "react-hook-form";

import { BaseState } from "lib/platforms/base";
import {
  OnboardFormProvider,
  OnboardLayout,
} from "components/onboard/OnboardLayout";
import { SelectEnvironment } from "components/platforms/common/forms/SelectEnvironmentStep";

export const EnvironmentSelection = () => {
  return (
    <OnboardFormProvider>
      <EnvironmentSelectionProvided />
    </OnboardFormProvider>
  );
};

const EnvironmentSelectionProvided = () => {
  const { getValues } = useFormContext<BaseState<any>>();
  const currentEnvironment = getValues("resourceEnvironment");

  const stepIsCompleted = Boolean(currentEnvironment);

  return (
    <OnboardLayout nextButtonIsActive={stepIsCompleted}>
      <h1 className="flex items-center w-full text-2xl font-bold mb-6">
        What kind of sync will this be?
      </h1>
      <p className="text-sm mb-6">
        Tag your sync’s environment to help you organize your development
        workflow.<b> You can always change this later.</b>
      </p>
      <p className="text-sm mb-8">
        <a
          className="link"
          href="https://docs.sequin.io/environments"
          target="_blank"
          rel="noreferrer noopener"
        >
          Learn more about environment tags
        </a>
      </p>
      <SelectEnvironment />
    </OnboardLayout>
  );
};
