import * as React from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}

function SvgComponent({
  title,
  titleId,
  ...props
}: React.SVGProps<SVGSVGElement> & SVGRProps) {
  return (
    <svg viewBox="0 0 22 24" fill="none" aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        d="M17.111 19.416 14.701 12l6.31-4.584c1.554 4.758 0 9.168-3.9 12Zm3.9-12L18.602 0h-7.8l2.397 7.416h7.814ZM10.802 0H2.999L.602 7.416h7.801L10.801 0ZM.589 7.416c-1.54 4.758 0 9.168 3.901 12L6.888 12 .589 7.416Zm3.901 12L10.8 24l6.311-4.584-6.31-4.584-6.311 4.584Z"
        fill="currentColor"
      />
    </svg>
  );
}

const SvgAuthZeroFilled = React.memo(SvgComponent);
export default SvgAuthZeroFilled;
