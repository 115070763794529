import React from "react";
import Head from "next/head";
import { Link } from "react-router-dom";
import { Button } from "../../lib/lucidez";

export default function LogoutDone() {
  return (
    <>
      <Head>
        <title>See ya! - Sequin</title>
      </Head>
      <main className="custom-container my-32 max-w-md p-8 rounded-md bg-white dark:bg-gray-900 shadow-lg">
        <h1 className="font-bold text-xl">Logged out</h1>
        <div className="mt-8">Until next time, stranger.</div>
        <div className="text-right">
          <Link to="/login">
            <Button className="mt-4" variant="primary" size="lg" as="span">
              Login
            </Button>
          </Link>
        </div>
      </main>
    </>
  );
}
