import React from "react";
import { ErrorMessage } from "@hookform/error-message";
import { useFormContext } from "react-hook-form";

export type DatabaseNameFormInputs = {
  name: string;
};

export const DatabaseNameForm = () => {
  const { register, formState } = useFormContext<DatabaseNameFormInputs>();

  return (
    <section className="grid grid-cols-1 gap-x-8 gap-y-4">
      <label>
        <span className="font-bold">Name</span>
        <input
          className="textbox-outlined textbox-md w-full mt-2"
          type="text"
          name="name"
          placeholder="My Database"
          {...register("name", {
            required: "Name is required",
          })}
        />
        <p className="text-error text-xs">
          <ErrorMessage errors={formState.errors} name="name" />
        </p>
      </label>
    </section>
  );
};
