import {
  ChartBarIcon,
  ChatIcon,
  CreditCardIcon,
  ExclamationCircleIcon,
  LogoutIcon,
  SearchIcon,
  UserCircleIcon,
  UserIcon,
} from "@heroicons/react/outline";
import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { match, P } from "ts-pattern";

import { getUrl } from "lib/api";
import { useFeature, useOrg } from "lib/api/hooks";
import { LoginStatus, Organization } from "lib/api/types";
import { numberOfDaysLeftInTrial } from "lib/utils/org";
import { useLoginStatus } from "lib/api/utilHooks";
import { Button } from "lib/lucidez/components/Button";
import Dropdown from "components/core/Dropdown";
import ThemedBrand from "components/core/ThemedBrand";
import Navbar from "components/Navbar";
import { ContactUs } from "components/core/ContactUs";
import { MAILTO_LINK } from "site-constants";

enum UpgradeCTAType {
  None = 0,
  Silent = 1,
  Loud = 2,
}

type UpgradeCTA = {
  type: UpgradeCTAType;
  content: (props: { org: Organization }) => React.ReactElement;
};

const matchUpgradeCTA = (org?: Organization) => {
  return match<Organization, UpgradeCTA>(org)
    .with(
      {
        subscriptionStatus: "trial",
        pricing: {
          definition: {
            schema: "v1",
          },
        },
        usages: {
          monthlyActiveRows: P.when(
            (mar: number) =>
              mar > org.pricing.definition.pricing.fixed.includedMar
          ),
        },
      },
      () => ({
        type: UpgradeCTAType.Loud,
        content: () => (
          <>
            <ExclamationCircleIcon className="h-5 ml-2 inline border-black text-black mr-1.5" />
            <span className="text-center">
              One or more syncs in your account exceeds our free tier.{" "}
              <UpgradeNowButton /> or{" "}
              <ContactUs className="underline mx-1 contact-us-trigger">
                contact us
              </ContactUs>{" "}
              to ensure your syncs keep running after the Pro trial ends.
            </span>{" "}
            <SeeUsageButton />
          </>
        ),
      })
    )
    .with(
      {
        subscriptionStatus: "trial",
        pricing: {
          definition: {
            schema: "v1",
          },
        },
        usages: {
          monthlyActiveRows: P.when(
            (mar: number) =>
              mar > org.pricing.definition.pricing.fixed.includedMar * 0.7
          ),
        },
      },
      () => ({
        type: UpgradeCTAType.Loud,
        content: (org) => {
          const usagePercentage = Math.round(
            (org.org.usages.monthlyActiveRows /
              org.org.pricing.definition.pricing.fixed.includedMar) *
              100
          );

          return (
            <>
              <ExclamationCircleIcon className="h-5 ml-2 inline border-black text-black mr-1.5" />
              <span className="text-center">
                One or more syncs in your account has consumed {usagePercentage}
                % of its free tier quota. <UpgradeNowButton /> or{" "}
                <ContactUs className="underline mx-1 contact-us-trigger">
                  contact us
                </ContactUs>{" "}
                to ensure your syncs keep running after the Pro trial ends.
              </span>{" "}
              <SeeUsageButton />
            </>
          );
        },
      })
    )
    .with(
      {
        subscriptionStatus: "trial",
        pricing: {
          definition: {
            schema: "v2",
          },
        },
      },
      () => ({
        type: UpgradeCTAType.Silent,
        content: (org) => {
          return (
            <>
              <ExclamationCircleIcon className="h-5 ml-2 inline border-black text-black mr-1.5" />
              <span className="text-center">
                You have {numberOfDaysLeftInTrial(org.org)} days left in your
                trial period.{" "}
                <Link to="/checkout">
                  <a className="underline">Upgrade now</a>
                </Link>{" "}
                to keep your syncs from pausing.
              </span>
            </>
          );
        },
      })
    )
    .with(
      {
        subscriptionStatus: "free",
        pricing: {
          definition: {
            schema: "v1",
          },
        },
        usages: {
          monthlyActiveRows: P.when(
            (mar: number) =>
              mar > org.pricing.definition.pricing.fixed.includedMar
          ),
        },
      },
      () => ({
        type: UpgradeCTAType.Loud,
        content: () => (
          <>
            <ExclamationCircleIcon className="h-5 ml-2 inline border-black text-black mr-1.5" />
            <span className="text-center">
              One or more syncs in your account exceeds our free tier.{" "}
              <UpgradeNowButton /> or{" "}
              <ContactUs className="underline mx-1 contact-us-trigger">
                contact us
              </ContactUs>{" "}
              to ensure your syncs keep running.
            </span>{" "}
            <SeeUsageButton />
          </>
        ),
      })
    )
    .with(
      {
        subscriptionStatus: "free",
        pricing: {
          definition: {
            schema: "v1",
          },
        },
        usages: {
          monthlyActiveRows: P.when(
            (mar: number) =>
              mar > org.pricing.definition.pricing.fixed.includedMar * 0.7
          ),
        },
      },
      () => ({
        type: UpgradeCTAType.Loud,
        content: (org) => {
          const percentil = Math.round(
            (org.org.usages.monthlyActiveRows /
              org.org.pricing.definition.quotas.freeTier.includedMar) *
              100
          );

          return (
            <>
              <ExclamationCircleIcon className="h-5 ml-2 inline border-black text-black mr-1.5" />
              <span className="text-center">
                One or more syncs in your account has consumed {percentil}% of
                its free tier quota. <UpgradeNowButton /> or{" "}
                <ContactUs className="underline mx-1 contact-us-trigger">
                  contact us
                </ContactUs>{" "}
                to ensure your syncs keep running.
              </span>{" "}
              <SeeUsageButton />
            </>
          );
        },
      })
    )
    .with(
      {
        subscriptionStatus: "free",
        pricing: {
          definition: {
            schema: "v2",
          },
        },
      },
      () => ({
        type: UpgradeCTAType.Silent,
        content: () => (
          <>
            <ExclamationCircleIcon
              color="#F5222D"
              className="h-5 ml-2 inline border-error mr-1.5"
            />
            <span className="text-center">
              You're on the free plan. <UpgradeNowButton /> for a faster sync
              and no monthly limits.
            </span>
          </>
        ),
      })
    )
    .with(
      {
        subscriptionStatus: "inactive",
        pricing: {
          definition: {
            schema: "v2",
          },
        },
      },
      () => ({
        type: UpgradeCTAType.Loud,
        content: () => (
          <>
            <ExclamationCircleIcon className="h-5 ml-2 inline border-black text-black mr-1.5" />
            <span className="text-center">
              Your trial has expired. <UpgradeNowButton /> or{" "}
              <ContactUs className="underline mx-1 contact-us-trigger">
                contact us
              </ContactUs>{" "}
              to get back in action.
            </span>{" "}
            <SeeUsageButton />
          </>
        ),
      })
    )
    .with(
      {
        subscriptionStatus: "pending_inactive",
        pricing: {
          definition: {
            schema: "v1",
          },
        },
      },
      () => ({
        type: UpgradeCTAType.Loud,
        content: () => (
          <>
            <ExclamationCircleIcon className="h-5 ml-2 inline border-black text-black mr-1.5" />
            <span className="text-center">
              One or more syncs in your account exceeds our free tier, so we're
              about to pause your account. <UpgradeNowButton /> or{" "}
              <ContactUs className="underline mx-1 contact-us-trigger">
                contact us
              </ContactUs>{" "}
              to avoid disruption.
            </span>{" "}
            <SeeUsageButton />
          </>
        ),
      })
    )
    .otherwise(() => ({
      type: UpgradeCTAType.None,
      content: () => <></>,
    }));
};

export default function Header() {
  const loginStatus = useLoginStatus();
  const isLogged = loginStatus === LoginStatus.LoggedIn;

  const [org] = useOrg();

  const upgradeCTA = matchUpgradeCTA({ ...org });

  return (
    <>
      {upgradeCTA.type == UpgradeCTAType.Loud && (
        <LoudCTA>
          <upgradeCTA.content org={{ ...org }} />
        </LoudCTA>
      )}
      <header className="header h-20 z-30 bg-white bg-opacity-60 border-b">
        <div className="w-full mx-auto px-10 h-full flex flex-row items-center justify-between">
          {isLogged ? (
            <Link to="/">
              <ThemedBrand />
            </Link>
          ) : (
            <a href="https://sequin.io">
              <ThemedBrand />
            </a>
          )}
          <HeaderItems
            upgradeCTA={upgradeCTA}
            org={org}
            loginStatus={loginStatus}
          />
        </div>
        <style jsx>{`
          .header {
            backdrop-filter: saturate(180%) blur(5px);
          }
        `}</style>
      </header>
      <div className="grid md:hidden grid-cols-4 items-center h-16 px-4">
        <NavbarCompact loginStatus={loginStatus} />
      </div>
    </>
  );
}

interface HeaderItemsProps {
  upgradeCTA: UpgradeCTA;
  org: Organization;
  loginStatus: LoginStatus;
}

const HeaderItems = ({ upgradeCTA, org, loginStatus }: HeaderItemsProps) => {
  const [featureAccountPage] = useFeature("feature_account_page");
  const navigate = useNavigate();

  const shouldShowBillingLink = upgradeCTA.type === UpgradeCTAType.None;
  const shouldShowCheckoutLink = !shouldShowBillingLink;

  if (loginStatus === LoginStatus.Loading) {
    return <></>;
  }
  if (loginStatus === LoginStatus.LoggedOut) {
    return <div className="flex-1" />;
  }

  return (
    <div className="grid grid-flow-col gap-10 items-center min-h-full">
      {upgradeCTA.type == UpgradeCTAType.Silent && (
        <SilentCTA>
          <upgradeCTA.content org={{ ...org }} />
        </SilentCTA>
      )}
      <div className="hidden md:grid grid-flow-col gap-10 items-center min-h-full">
        <Navbar />
      </div>

      <Button
        className="contact-us-trigger hidden lg:flex"
        iconLeft={<ChatIcon className="h-5" />}
        as="a"
        href={MAILTO_LINK}
        size="md"
      >
        Help
      </Button>

      <Dropdown>
        <Dropdown.Button className="flex flex-row rounded-full items-center justify-center text-gray-600">
          <UserIcon className="h-6 w-6" />
        </Dropdown.Button>
        <Dropdown.Items className="dropdown-items w-36">
          {featureAccountPage && (
            <Dropdown.StyledButtonItem onClick={() => navigate("/account")}>
              <UserCircleIcon className="mr-2 w-4 h-4" />
              Account
            </Dropdown.StyledButtonItem>
          )}
          {shouldShowBillingLink && (
            <Dropdown.StyledButtonItem
              onClick={() =>
                (window.location.href = getUrl("/api/billing/portal"))
              }
            >
              <CreditCardIcon className="mr-2 w-4 h-4" />
              Billing
            </Dropdown.StyledButtonItem>
          )}
          {shouldShowCheckoutLink && (
            <Dropdown.StyledButtonItem onClick={() => navigate("/checkout")}>
              <CreditCardIcon className="mr-2 w-4 h-4" />
              Checkout
            </Dropdown.StyledButtonItem>
          )}
          <Dropdown.StyledButtonItem
            onClick={() => {
              if (window && window.analytics) {
                window.analytics.track("Usages Clicked");
              }

              navigate("/usage");
            }}
          >
            <ChartBarIcon className="mr-2 w-4 h-4" />
            Usage
          </Dropdown.StyledButtonItem>
          <Dropdown.StyledButtonItem onClick={() => navigate("/logout")}>
            <LogoutIcon className="mr-2 w-4 h-4" />
            Logout
          </Dropdown.StyledButtonItem>
        </Dropdown.Items>
      </Dropdown>
    </div>
  );
};

interface NavbarCompactProps {
  loginStatus: LoginStatus;
}

const NavbarCompact = ({ loginStatus }: NavbarCompactProps) => {
  if (loginStatus === LoginStatus.Loading) {
    return <></>;
  }
  if (loginStatus === LoginStatus.LoggedOut) {
    return <div className="flex-1" />;
  }

  return <Navbar />;
};

const SilentCTA = ({ children }: React.PropsWithChildren<{}>) => (
  <div className="md:flex flex-row items-center text-xs hidden">{children}</div>
);

const LoudCTA = ({ children }: React.PropsWithChildren<{}>) => (
  <div className="p-3 bg-yellow-300 dark sticky top-0 z-40 flex flex-row items-center justify-center text-black">
    {children}
  </div>
);

const SeeUsageButton = () => {
  return (
    <Link to="/usage">
      <a className="ml-4 flex flex-row items-center border border-black text-black px-2 rounded text-xs py-1 hover:opacity-80 shadow">
        <SearchIcon className="w-4 mr-1" /> See usage
      </a>
    </Link>
  );
};

const UpgradeNowButton = () => (
  <Link to="/checkout">
    <a className="underline mx-1">Upgrade now</a>
  </Link>
);
