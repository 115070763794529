import React from "react";
import { useParams } from "react-router-dom";

import { useGetMessageQueue, useResourceBySlug } from "lib/api/hooks";
import { PageLoadingSpinner } from "components/core/PageLoadingSpinner";
import ResourceConnectionHelper from "components/resources/ResourceConnectionHelper";
import { EventsConnectionDisplay } from "components/resources/EventsConnectionDisplay";

export const SyncPageConnectionInstructions = () => {
  const { permaslug } = useParams();
  const [resource] = useResourceBySlug(permaslug as string, {
    waitUntilLoaded: true,
  });

  const [messageQueue] = useGetMessageQueue(resource?.messageQueueId);

  if (!resource) {
    return <PageLoadingSpinner />;
  }

  return (
    <section className="py-12 flex-1">
      <div className="custom-container">
        <div className="w-3/4">
          <h3 className="text-xl font-bold mb-6">Postgres</h3>
          <ResourceConnectionHelper resource={resource} />
          {messageQueue && (
            <>
              <hr className="my-6" />
              <h3 className="text-xl font-bold mb-6">Event stream</h3>
              <EventsConnectionDisplay messageQueue={messageQueue} />
            </>
          )}
        </div>
      </div>
    </section>
  );
};
