import * as React from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}

function SvgComponent({
  title,
  titleId,
  ...props
}: React.SVGProps<SVGSVGElement> & SVGRProps) {
  return (
    <svg
      viewBox="0 0 64 64"
      fill="currentColor"
      aria-labelledby={titleId}
      {...props}
    >
      {title ? <title id={titleId}>{title}</title> : null}
      <path d="M63.9 33.3v-2.7c0-2.3-.9-4.4-2.6-6.4-.9-1-2.1-2.1-3.5-3-.7-.5-1.5-1-2.4-1.5-.7-.3-1.3-.7-2-1-1.9-.8-3.9-1.5-6.1-2.1.3.9.5 1.9.7 2.8l.6 3c1.1.4 2.3.8 3.3 1.3.5.2 1 .4 1.5.7.2.1.5.2.7.3.9.5 1.6 1 2.3 1.5 2.6 1.7 4.2 3.7 4.7 5.7-.5 2.1-2.1 4.1-4.7 5.8.3.8.6 1.5.8 2.2.3.9.5 1.9.6 2.8 1.4-.9 2.6-2 3.5-3 1.7-2 2.6-4.1 2.6-6.4M24.9 48.9c-1.1-.9-2.2-1.8-3.2-2.8.2.8.4 1.7.7 2.5.8.1 1.7.2 2.5.3M30.7 46.6l1.2.9c.9.5 1.7 1.1 2.5 1.5.1.1.2.2.3.2 1.4 0 2.9-.1 4.3-.3.8-.1 1.7-.2 2.5-.3l3-.6c.9-.2 1.9-.4 2.8-.7 2.2-.6 4.2-1.3 6.1-2.1-.2-.9-.4-1.8-.7-2.7-.1-.2-.1-.5-.2-.8l-.6-1.5c-1 .5-2.2.9-3.3 1.3-.8.3-1.7.5-2.5.7-.1.1-.2.1-.3.1-1 .3-1.9.5-2.9.6l-1.5.3c-1.5.3-3 .4-4.6.6-1.6.1-3.2.2-4.9.2-1.6 0-3.2-.1-4.8-.2 1.1 1 2.4 1.9 3.6 2.8M11.2 21.4c0 .2.1.5.2.8l.6 1.5c1-.5 2.2-.9 3.3-1.3.8-.3 1.7-.5 2.5-.7.1-.1.2-.1.3-.1 1-.2 1.9-.5 2.9-.6l1.5-.3c1.5-.2 3-.4 4.6-.6 1.6-.1 3.2-.1 4.8-.1 1.7 0 3.3 0 4.9.1-1.1-1-2.4-1.9-3.6-2.8-.4-.3-.8-.6-1.3-.9-.8-.5-1.6-1.1-2.4-1.5-.1-.1-.2-.2-.3-.2-1.5 0-2.9.2-4.3.3-.8.1-1.7.2-2.5.3l-3 .6c-.9.2-1.9.4-2.8.7-2.2.6-4.2 1.3-6.1 2.1.2.9.4 1.8.7 2.7M39 15c1.1.9 2.2 1.8 3.2 2.8-.2-.8-.4-1.7-.7-2.5-.8-.1-1.7-.2-2.5-.3M2.8 31.9c.5-2 2.1-4 4.7-5.7-.3-.8-.6-1.5-.8-2.2-.3-.9-.5-1.9-.7-2.8-1.3.9-2.5 2-3.4 3-1.7 2-2.6 4.1-2.6 6.4v2.7c0 2.3.9 4.4 2.6 6.4.9 1 2.1 2 3.4 3 .8.5 1.6 1 2.5 1.5.7.3 1.3.7 2 1 1.9.8 3.9 1.5 6.1 2.1-.3-.9-.5-1.9-.7-2.8l-.6-3c-1.1-.4-2.3-.8-3.3-1.3-.5-.2-1-.4-1.5-.7-.2-.1-.5-.2-.7-.3-.9-.5-1.6-1-2.3-1.5C4.9 36 3.3 34 2.8 31.9" />
      <path d="M39 48.9c-1.4.1-2.9.3-4.3.3.7.5 1.5.9 2.2 1.2l2.1-1.5M55.5 10.3l-1.9-1.9c-1.6-1.6-3.8-2.5-6.4-2.7-1.4-.1-2.9.1-4.5.4-.9.1-1.9.4-2.8.6-.7.2-1.4.5-2.2.8-1.8.7-3.8 1.6-5.8 2.7.9.5 1.7 1 2.6 1.6.8.5 1.6 1.1 2.4 1.7 1.1-.6 2.2-1.1 3.3-1.5l1.5-.6c.3-.1.6-.1.8-.2.9-.3 1.8-.5 2.7-.7 3-.5 5.6-.2 7.4.8" />
      <path d="M52.6 11.3c1 1.8 1.3 4.4.8 7.4.7.3 1.3.7 2 1 .9.5 1.7 1 2.4 1.5.3-1.6.5-3.1.4-4.5-.2-2.6-1.1-4.8-2.7-6.4-.3-.4-.6-.6-1-.9M41.4 43.2l1.5-.3c1-.1 1.9-.3 2.9-.6.1 0 .2 0 .3-.1 1-1 1.9-2.1 2.8-3.2.5-.7 1.1-1.4 1.5-2.1l1.8-2.4c.5-.9 1-1.7 1.5-2.5 1.1-2 2-4 2.7-5.8-.7-.5-1.4-1-2.3-1.5-.2-.1-.5-.2-.7-.3-.5-.3-1-.5-1.5-.7-.4 1.1-.9 2.2-1.5 3.3-.3.7-.7 1.5-1.2 2.2 0 .1-.1.2-.2.3-.4.8-1 1.6-1.5 2.5l-.9 1.2c-.9 1.2-1.8 2.5-2.8 3.6-1.1 1.3-2.2 2.5-3.4 3.6-1.1 1.2-2.3 2.3-3.6 3.4 1.6-.2 3.1-.3 4.6-.6M24.9 15c1.4-.2 2.8-.3 4.3-.3-.7-.5-1.5-.9-2.2-1.2-.7.4-1.4 1-2.1 1.5M11.3 52.6c-1-1.8-1.3-4.4-.8-7.4-.7-.3-1.3-.7-2-1-.9-.5-1.7-1-2.5-1.5-.2 1.6-.4 3.1-.3 4.5.2 2.6 1.1 4.8 2.7 6.4l1.9 1.9c1.6 1.6 3.8 2.5 6.4 2.7 1.4.1 2.9-.1 4.5-.3.9-.2 1.9-.4 2.8-.7.7-.2 1.4-.5 2.2-.8 1.8-.7 3.8-1.6 5.7-2.7-.8-.5-1.6-1-2.5-1.5-.8-.6-1.6-1.1-2.4-1.7-1.1.5-2.2 1-3.3 1.4l-1.5.6c-.3.1-.6.2-.8.2-.9.3-1.8.5-2.7.7-3 .5-5.6.2-7.4-.8M9.8 39.2c.2.1.5.2.7.3.5.3 1 .5 1.5.7.4-1.1.9-2.2 1.5-3.3.3-.7.7-1.5 1.2-2.2 0-.1.1-.2.2-.3.4-.8 1-1.6 1.5-2.5l.9-1.2c.9-1.2 1.8-2.5 2.8-3.6 1.1-1.3 2.2-2.5 3.4-3.6 1.1-1.2 2.3-2.3 3.6-3.4-1.6.2-3.1.3-4.6.6L21 21c-1 .1-1.9.4-2.9.6-.1 0-.2 0-.3.1-1 1-1.9 2.1-2.8 3.2-.5.7-1.1 1.4-1.6 2.1-.5.8-1.1 1.6-1.7 2.4-.5.9-1 1.7-1.5 2.5-1.1 2-2 4-2.7 5.8.7.5 1.4 1 2.3 1.5" />
      <path d="M15 39c-.2-1.4-.3-2.9-.3-4.3-.5.7-.9 1.5-1.2 2.2.4.7 1 1.4 1.5 2.1M11.3 11.3c1.8-1 4.4-1.3 7.4-.8.3-.7.7-1.3 1-2 .5-.9 1-1.7 1.5-2.4-1.6-.3-3.1-.5-4.5-.4-2.6.2-4.8 1.1-6.4 2.7-.4.3-.6.6-.9 1 .3-.4.5-.7.9-1l-1.9 1.9c-1.6 1.6-2.5 3.8-2.7 6.4-.1 1.4.1 2.9.3 4.5.2.9.4 1.9.7 2.8.2.7.5 1.4.8 2.2.7 1.8 1.6 3.8 2.7 5.8.5-.9 1-1.7 1.5-2.6.6-.8 1.2-1.6 1.7-2.4-.5-1.1-1-2.2-1.4-3.3l-.6-1.5c-.1-.3-.2-.6-.2-.8-.3-.9-.5-1.8-.7-2.7-.5-3-.2-5.6.8-7.4M48.9 24.9c.1 1.4.3 2.9.3 4.3.5-.7.9-1.5 1.2-2.2-.4-.7-1-1.4-1.5-2.1M43.2 22.5l-.3-1.5c-.1-1-.4-1.9-.6-2.9 0-.1 0-.2-.1-.3-1-1-2.1-1.9-3.2-2.8-.7-.5-1.4-1.1-2.1-1.5l-2.4-1.8c-.9-.5-1.7-1-2.6-1.5-1.9-1.1-3.9-2-5.7-2.7-.5.7-1 1.4-1.5 2.3-.1.2-.2.5-.3.7-.3.5-.5 1-.7 1.5 1.1.4 2.2.9 3.3 1.5.7.3 1.5.7 2.2 1.2.1 0 .2.1.3.2.8.4 1.6 1 2.4 1.5.5.3.9.6 1.3.9 1.2.9 2.5 1.8 3.6 2.8 1.3 1.1 2.5 2.2 3.6 3.4 1.2 1.1 2.3 2.3 3.4 3.6-.2-1.6-.4-3.1-.6-4.6M20.7 41.4l.3 1.5c.1 1 .4 1.9.6 2.9 0 .1 0 .2.1.3 1 1 2.1 1.9 3.2 2.8.7.5 1.4 1.1 2.1 1.5l2.4 1.8c.9.5 1.7 1 2.5 1.5 2 1.1 4 2 5.8 2.7.5-.7 1-1.4 1.5-2.3.1-.2.2-.5.3-.7.3-.5.5-1 .7-1.5-1.1-.4-2.2-.9-3.3-1.5-.7-.3-1.5-.7-2.2-1.2-.1 0-.2-.1-.3-.2-.8-.4-1.6-1-2.5-1.5l-1.2-.9c-1.2-.9-2.5-1.8-3.6-2.8-1.3-1.1-2.5-2.2-3.6-3.4-1.2-1.1-2.3-2.3-3.4-3.6.2 1.6.3 3.1.6 4.6M53.6 55.5l1.9-1.9c1.6-1.6 2.5-3.8 2.7-6.4.1-1.4-.1-2.9-.4-4.5-.1-.9-.3-1.9-.6-2.8-.2-.7-.5-1.4-.8-2.2-.7-1.8-1.6-3.8-2.7-5.7-.5.8-1 1.6-1.5 2.5l-1.8 2.4c.6 1.1 1.1 2.2 1.5 3.3l.6 1.5c.1.3.1.6.2.8.3.9.5 1.8.7 2.7.5 3 .2 5.6-.8 7.4-1.8 1-4.4 1.3-7.4.8-.3.7-.7 1.3-1 2-.5.9-1 1.7-1.5 2.4 1.6.3 3.1.5 4.5.4 2.6-.2 4.8-1.1 6.4-2.7" />
      <path d="M17.3 30.7c-.3.4-.6.8-.9 1.3-.5.8-1.1 1.6-1.5 2.4-.1.1-.2.2-.2.3 0 1.4.1 2.9.3 4.3.1.8.2 1.7.3 2.5l.6 3c.2.9.4 1.9.7 2.8.6 2.2 1.3 4.2 2.1 6.1.9-.2 1.8-.4 2.7-.7.2-.1.5-.1.8-.2l1.5-.6c-.5-1-.9-2.2-1.3-3.3-.3-.8-.5-1.7-.7-2.5-.1-.1-.1-.2-.1-.3-.2-1-.5-1.9-.6-2.9l-.3-1.5c-.3-1.5-.4-3-.6-4.6-.1-1.6-.2-3.2-.2-4.8 0-1.7.1-3.3.2-4.9-1 1.1-1.9 2.4-2.8 3.6M48.9 39c-.9 1.1-1.8 2.2-2.8 3.2.8-.2 1.7-.4 2.5-.7.1-.8.2-1.7.3-2.5M15 24.9c.9-1.1 1.8-2.1 2.8-3.2-.8.2-1.7.4-2.5.7-.1.8-.2 1.7-.3 2.5M46.6 33.2l.9-1.2c.5-.9 1.1-1.7 1.5-2.5.1-.1.2-.2.2-.3 0-1.4-.2-2.9-.3-4.3-.1-.8-.2-1.7-.3-2.5l-.6-3c-.2-.9-.4-1.9-.7-2.8-.6-2.2-1.3-4.2-2.1-6.1-.9.2-1.8.4-2.7.7-.2.1-.5.1-.8.2l-1.5.6c.5 1 .9 2.2 1.3 3.3.3.8.5 1.7.7 2.5.1.1.1.2.1.3.2 1 .5 1.9.6 2.9l.3 1.5c.2 1.5.4 3 .6 4.6.1 1.6.1 3.2.1 4.9 0 1.6 0 3.2-.1 4.8 1-1.1 1.9-2.4 2.8-3.6M42.7 6c-1-1.3-2-2.5-3-3.4C37.7.9 35.6 0 33.3 0h-2.7c-2.3 0-4.4.9-6.4 2.6-1 .9-2.1 2.1-3 3.4-.5.8-1 1.6-1.5 2.5-.3.7-.7 1.3-1 2-.8 1.9-1.5 3.9-2.1 6.1.9-.3 1.9-.5 2.8-.7l3-.6c.4-1.1.8-2.3 1.3-3.3.2-.5.4-1 .7-1.5.1-.2.2-.5.3-.7.5-.9 1-1.6 1.5-2.3 1.7-2.6 3.7-4.2 5.7-4.7 2.1.5 4.1 2.1 5.8 4.7.8-.3 1.5-.6 2.2-.8M30.6 63.9h2.7c2.3 0 4.4-.9 6.4-2.6 1-.9 2-2.1 3-3.5.5-.7 1-1.5 1.5-2.4.3-.7.7-1.3 1-2 .8-1.9 1.5-3.9 2.1-6.1l-2.8.7-3 .6c-.4 1.1-.8 2.3-1.3 3.3-.2.5-.4 1-.7 1.5-.1.2-.2.5-.3.7-.5.9-1 1.6-1.5 2.3-1.7 2.6-3.7 4.2-5.8 4.7-2-.5-4-2.1-5.7-4.7-.8.3-1.5.6-2.2.8l-2.8.6c.9 1.4 2 2.6 3 3.5 2 1.7 4.1 2.6 6.4 2.6" />
    </svg>
  );
}

const SvgCloseFilled = React.memo(SvgComponent);
export default SvgCloseFilled;
