import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { CheckCircleIcon } from "@heroicons/react/solid";
import { useStateMachine } from "little-state-machine";

import {
  OnboardFormProvider,
  OnboardLayout,
} from "components/onboard/OnboardLayout";
import { OnboardPlatformList } from "components/PlatformList";
import { backendEvent, sendUpvote } from "lib/api";
import { useProfile } from "lib/api/hooks";
import { Button } from "lib/lucidez";
import { getPlatformForKind, Platform, ResourceKind } from "lib/platforms";
import { updateFormData, updatePlatformKind } from "lib/globalState";
import { useFormContext, useFormState } from "react-hook-form";
import { useAsyncValidation } from "components/platforms/common/forms/util";
import { bindEnterKeyPress } from "lib/utils/form";
import { track } from "lib/utils/analytics";

type SupportedAndBetaKind = ResourceKind | string;

export const SourceSelection = () => {
  return (
    <OnboardFormProvider>
      <SourceSelectionForm />
    </OnboardFormProvider>
  );
};

const SourceSelectionForm = () => {
  const { getValues } = useFormContext();
  const { isValid, errors } = useFormState();
  const navigate = useNavigate();

  const { actions, state } = useStateMachine({
    updateFormData,
    updatePlatformKind,
  });
  const platformFromState = state["onboardState"].platformKind;
  const platform = platformFromState && getPlatformForKind(platformFromState);
  const { isAnyValidationRunning } = useAsyncValidation();

  const [
    shouldShowResourceSuggestionLink,
    setShouldShowResourceSuggestionLink,
  ] = useState(platformFromState ? false : true);
  const [
    shouldShowResourceSuggestionForm,
    setShouldShowResourceSuggestionForm,
  ] = useState(false);
  const [suggestedPlatform, setSuggestedPlatform] = useState("");

  const [hasSuggestedPlatform, setHasSuggestedPlatform] = useState(false);

  const platformSuggestionInputRef = useRef(null);

  const [user] = useProfile();

  const hasErrors = Object.keys(errors).length > 0;

  const stepIsCompleted =
    !isAnyValidationRunning && isValid && !hasErrors && Boolean(platform);

  const OnboardCredentialForm = platform?.buildOnboardCredentialForm;

  const resourceEnvironment = getValues("resourceEnvironment");

  const shouldShowDevelopmentCallout =
    (resourceEnvironment === "development" ||
      resourceEnvironment === "staging") &&
    (platform?.kind === "airtable" ||
      platform?.kind === "hubspot" ||
      platform?.kind === "salesforce");

  const selectPlatform = (platformKind: SupportedAndBetaKind): void => {
    backendEvent("Onboarding Beta Source Selected", { source: platformKind });
    navigate("/onboard/source", {
      state: {
        kind: platformKind,
      },
    });
    return;
  };

  const deselectPlatform = () => {
    actions.updatePlatformKind(null);
    // Keep the already selected resource environment intact, but clear out the
    // rest of the form data
    actions.updateFormData({ resourceEnvironment });

    // If they've sent a suggestion, don't show the form when they hit the back button
    // after selecting a platform
    if (!hasSuggestedPlatform) {
      setShouldShowResourceSuggestionLink(true);
    }
  };

  const showPlatformSuggestionForm = (event) => {
    event.preventDefault();
    setShouldShowResourceSuggestionLink(false);
    setShouldShowResourceSuggestionForm(true);
  };

  const hidePlatformSuggestionForm = () => {
    setShouldShowResourceSuggestionLink(true);
    setShouldShowResourceSuggestionForm(false);
  };

  const handleSubmit = async () => {
    setShouldShowResourceSuggestionForm(false);
    track("Onboarding Source Suggested", {
      source: suggestedPlatform,
    });

    await sendUpvote(user ? user.email : "", suggestedPlatform);
    setHasSuggestedPlatform(true);
  };

  const handleEnterKeyPress = () => {
    if (suggestedPlatform !== "") {
      handleSubmit();
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Escape") {
      hidePlatformSuggestionForm();
    }
  };

  useEffect(() => {
    if (shouldShowResourceSuggestionForm) {
      platformSuggestionInputRef.current?.focus();
    }
  }, [shouldShowResourceSuggestionForm, platformSuggestionInputRef.current]);

  return (
    <OnboardLayout nextButtonIsActive={stepIsCompleted}>
      <h1 className="flex items-center w-full text-2xl font-bold mb-6">
        {platform
          ? `Authenticate with ${platform.displayName}`
          : "Which provider do you want to connect to first?"}
        {stepIsCompleted && (
          <CheckCircleIcon className="w-5 h-5 mr-2 text-success rounded-full" />
        )}
      </h1>
      {/* <p className="text-sm mb-4">
        Select a provider to connect to Sequin. We'll walk you through the
        process so you can get a complete understanding of Sequin.
      </p> */}
      {platform ? (
        <>
          {shouldShowDevelopmentCallout && (
            <DevelopmentCallout platform={platform} />
          )}
          <OnboardCredentialForm handleBackClick={deselectPlatform} />
        </>
      ) : (
        <OnboardPlatformList setPlatform={selectPlatform} />
      )}
      {shouldShowResourceSuggestionLink && (
        <div className="mt-6">
          <a href="" onClick={showPlatformSuggestionForm} className="link">
            My platform isn't listed here
          </a>
        </div>
      )}
      {shouldShowResourceSuggestionForm && (
        <div className="mt-6">
          <h2 className="mb-4 font-bold text-xl">
            What resource would you like to connect to?
          </h2>
          <input
            className="mb-4 p-2 w-full border border-cool-gray-200 rounded-md"
            onChange={(event) => {
              setSuggestedPlatform(event.target.value);
            }}
            onKeyDown={handleKeyDown}
            onKeyPress={bindEnterKeyPress(handleEnterKeyPress)}
            ref={platformSuggestionInputRef}
            type="text"
            value={suggestedPlatform}
          />
          <div className="flex justify-end">
            <Button className="mr-2" onClick={hidePlatformSuggestionForm}>
              Cancel
            </Button>
            <Button
              variant="primary"
              onClick={handleSubmit}
              disabled={suggestedPlatform === ""}
            >
              Submit
            </Button>
          </div>
        </div>
      )}
    </OnboardLayout>
  );
};

const DevelopmentCallout = ({
  platform,
}: {
  platform: Platform<any, any, any>;
}) => {
  return (
    <div className="p-8 mb-6 rounded-md bg-cool-gray-100">
      <p>
        To set up a sync you can test, we recommend connecting to{" "}
        {platform.kind === "airtable" ? "an" : "a"} {platform.displayName}{" "}
        developer account.
      </p>
      <p className="mt-6">
        <a
          className="link hover:text-black font-medium"
          href={getDevDocsLinkForPlatform(platform.kind)}
          target="_blank"
          rel="noreferrer noopener"
        >
          Check out the docs for step-by-step instructions.
        </a>
      </p>
    </div>
  );
};

const getDevDocsLinkForPlatform = (kind: ResourceKind): string => {
  if (kind === "airtable") {
    return "https://docs.sequin.io/integrations/airtable/setting-up-dev";
  }

  if (kind === "hubspot") {
    return "https://docs.sequin.io/integrations/hubspot/setting-up-dev";
  }

  if (kind === "salesforce") {
    return "https://docs.sequin.io/integrations/salesforce/setting-up-dev";
  }

  return "https://docs.sequin.io/environments";
};
