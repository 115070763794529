import React, { useEffect, useState } from "react";
import Head from "next/head";
import { Link, useSearchParams } from "react-router-dom";

import { confirmEmail, resendConfirmationToken } from "lib/api";
import { Button } from "lib/lucidez";
import { MAILTO_LINK } from "site-constants";

type TokenStatus =
  | "confirmed"
  | "expired"
  | "invalid"
  | "new-token-sent"
  | "unknown-error"
  | "loading";

export default function EmailConfirmation() {
  let [query] = useSearchParams();
  let [tokenStatus, setTokenStatus] = useState<TokenStatus>("expired");

  useEffect(() => {
    async function confirmUserEmail(email: string, token: string) {
      try {
        await confirmEmail(email, token);
        setTokenStatus("confirmed");
      } catch (error) {
        console.error("Error confirming email", error);
        if (error?.message?.includes("token expired")) {
          setTokenStatus("expired");
        } else {
          setTokenStatus("invalid");
        }
      }
    }

    if (query.has("email") && query.has("token")) {
      confirmUserEmail(query.get("email"), query.get("token"));
    }
  }, [query]);

  async function sendNewToken() {
    try {
      setTokenStatus("loading");
      await resendConfirmationToken(query.get("token"));
      setTokenStatus("new-token-sent");
    } catch (error) {
      console.error(error);
      setTokenStatus("unknown-error");
    }
  }

  switch (tokenStatus) {
    case "confirmed":
      return (
        <>
          <Head>
            <title>Email confirmed - Sequin</title>
          </Head>
          <main className="custom-container my-32 max-w-md p-8 rounded-md bg-white dark:bg-gray-900 shadow-lg">
            <h1 className="font-bold text-xl">Email confirmed!</h1>
            <div className="text-right">
              <Link to="/">
                <Button className="mt-4" variant="primary" size="lg" as="a">
                  Continue to Sequin
                </Button>
              </Link>
            </div>
          </main>
        </>
      );
    case "expired":
      return (
        <>
          <Head>
            <title>Email confirmation - Sequin</title>
          </Head>
          <main className="custom-container my-32 max-w-md p-8 rounded-md bg-white dark:bg-gray-900 shadow-lg">
            <h1 className="font-bold text-xl">
              Email confirmation token expired
            </h1>
            <p className="mt-8">
              Your email confirmation token seems to be expired.
              <br />
              We can send you a new one:
            </p>

            <div className="text-right">
              <Button
                className="mt-4"
                variant="primary"
                onClick={() => sendNewToken()}
              >
                Send new token
              </Button>
            </div>
          </main>
        </>
      );
    case "invalid":
      return (
        <>
          <Head>
            <title>Email confirmation - Sequin</title>
          </Head>
          <main className="custom-container my-32 max-w-md p-8 rounded-md bg-white dark:bg-gray-900 shadow-lg">
            <h1 className="font-bold text-xl">
              Email confirmation token invalid
            </h1>
            <p className="mt-8">
              Your confirmation token seems to be invalid, for support reach out
              to{" "}
              <a
                href={MAILTO_LINK}
                className="text-black dark:text-white underline"
              >
                founders@sequin.io
              </a>
            </p>
          </main>
        </>
      );
    case "new-token-sent":
      return (
        <>
          <Head>
            <title>Email confirmation - Sequin</title>
          </Head>
          <main className="custom-container my-32 max-w-md p-8 rounded-md bg-white dark:bg-gray-900 shadow-lg">
            <h1 className="font-bold text-xl">
              Email confirmation token invalid
            </h1>
            <p className="mt-8">
              Please check your email and follow the link to verify your email.
            </p>
          </main>
        </>
      );
    case "unknown-error":
      return (
        <>
          <Head>
            <title>Email confirmation - Sequin</title>
          </Head>
          <main className="custom-container my-32 max-w-md p-8 rounded-md bg-white dark:bg-gray-900 shadow-lg">
            <h1 className="font-bold text-xl">Token refresh failed!</h1>
            <p className="mt-8">
              Your confirmation token refresh failed, for support reach out to{" "}
              <a
                href={MAILTO_LINK}
                className="text-black dark:text-white underline"
              >
                founders@sequin.io
              </a>
            </p>
          </main>
        </>
      );
    case "loading":
    default:
      return (
        <>
          <Head>
            <title>Email confirmation - Sequin</title>
          </Head>
          <main className="custom-container my-32 max-w-md p-8 rounded-md bg-white dark:bg-gray-900 shadow-lg">
            <p className="mt-8">Confirming your profile...</p>
          </main>
        </>
      );
  }
}
