import React, { useRef, useState } from "react";
import { Popover } from "@headlessui/react";
import { TrashIcon } from "@heroicons/react/outline";
import Head from "next/head";
import { toast } from "react-toastify";
import { Link, Outlet } from "react-router-dom";
import { useCredentialInfo, useCredentials, useResources } from "lib/api/hooks";
import { Button } from "lib/lucidez";
import { useWorkable } from "lib/utils";
import { PageLoadingSpinner } from "components/core/PageLoadingSpinner";
import SvgSpinner from "components/svg/Spinner";
import {
  PlatformCredential,
  Resource,
  getPlatformForKind,
} from "lib/platforms";
import { deleteCredential } from "lib/api";
import HoverPopover from "components/core/HoverPopover";
import { PlusIcon } from "@heroicons/react/solid";

interface CredentialCardProps {
  credential: PlatformCredential;
  resources: Resource[];
  refreshCredentials: () => void;
}

function CredentialCard({
  credential,
  resources,
  refreshCredentials,
}: CredentialCardProps) {
  const deleteBtnRef = useRef<any>();
  const [isDeleting, workableDeletion] = useWorkable();

  const [credentialInfo] = useCredentialInfo(credential.id);

  const platform = getPlatformForKind(
    credential.payload.kind === "airtable_oauth"
      ? "airtable"
      : credential.payload.kind
  );

  const PlatformIcon = platform.displayIcon;

  const closeDeletePopover = () => {
    deleteBtnRef.current?.click();
  };

  const credLabel =
    credentialInfo?.label || platform.getLabelForCredential(credential);

  const readableErrors = {
    has_resources: `The credential ${credLabel} can't be deleted because it's in use by one or more syncs.`,
  };

  const handleDelete = async () => {
    const response = await workableDeletion(() =>
      deleteCredential(credential.id)
    );
    if (response.isOk()) {
      toast.success(`Successfully deleted the credential ${credLabel}.`, {
        toastId: "credential-deleted",
      });
      refreshCredentials();
      closeDeletePopover();
    } else {
      const errorMessage =
        readableErrors[response.error.error?.slug] ||
        "Unknown error while deleting credential.";
      toast.error(errorMessage, { toastId: "credential-unknown-error" });
    }
  };

  const syncsUsingThisCredentialList =
    resources.length > 0 ? (
      <div className="w-48">
        <span className="font-medium">Syncs using this credential:</span>
        <div className="mt-2 flex flex-col gap-2">
          {resources.map((resource) => (
            <Link
              to={`/syncs/${resource.permaslug}/collections`}
              key={resource.id}
              className="border border-gray-50 bg-gray-50 p-2 rounded flex flex-row hover:border-gray-200"
            >
              <span className="flex-1">{resource.name}</span>
            </Link>
          ))}
        </div>
      </div>
    ) : (
      <div>No syncs are using this credential.</div>
    );

  return (
    <div className="p-7 gap-2 bg-white shadow-md rounded flex justify-between items-center flex-col sm:flex-row">
      <div className="flex flex-1 flex-row min-w-full gap-8 items-center justify-start md:grid-cols-4-auto sm:min-w-0">
        <div className="p-3 rounded-full border border-dashed border-gray-500 max-w-fit">
          <PlatformIcon className="h-6 w-6" />
        </div>
        <div className="flex-1 flex flex-col">
          <span className="text-base font-bold">{credLabel}</span>
          <span className="text-sm text-gray-500 ph-no-capture">
            {platform.displayName}
          </span>
        </div>
        <HoverPopover
          content={syncsUsingThisCredentialList}
          containerProps={{
            placement: "bottom-end",
          }}
        >
          <div className="w-12 flex flex-col">
            <span className="text-base font-bold">{resources.length}</span>
            <span className="text-sm text-gray-500">Syncs</span>
          </div>
        </HoverPopover>
      </div>
      <div className="flex gap-2 flex-row sm:flex-col md:flex-row">
        <Popover className="relative">
          <Popover.Button
            className="transition-all border p-3 flex justify-center items-center rounded-full border-gray-200 hover:border-black dark:border-gray-600 dark:hover:border-white"
            ref={deleteBtnRef}
            disabled={isDeleting}
          >
            {isDeleting ? (
              <SvgSpinner className="h-4 animate-spin" />
            ) : (
              <TrashIcon className="h-4" />
            )}
          </Popover.Button>

          <Popover.Panel className="absolute z-10 bottom-full border border-gray-200 bg-white p-4 shadow-lg rounded right-0 transform w-64 mb-2">
            <p className="text-xs">
              Are you sure you want to delete this credential?
            </p>
            <div className="flex flex-row mt-4">
              <div className="flex-1" />
              <Button
                variant="outlined"
                size="sm"
                onClick={handleDelete}
                isLoading={isDeleting}
              >
                Yes
              </Button>
              <Button
                variant="primary"
                size="sm"
                onClick={closeDeletePopover}
                className="ml-2"
              >
                No
              </Button>
            </div>
          </Popover.Panel>
        </Popover>
      </div>
    </div>
  );
}

export function Credentials() {
  const [isShowingAddKeyNotice, setIsShowingAddKeyNotice] = useState(false);

  return (
    <>
      <Head>
        <title>Credentials - Sequin</title>
      </Head>
      <main className="pb-12">
        <div className="w-full pt-8 min-h-[40px]">
          <div className="custom-container">
            <div className="flex flex-row items-center justify-between gap-24 min-h-[40px]">
              <h1 className="flex-1 font-bold text-2xl ml-2">Credentials</h1>
              <Button
                variant="primary"
                iconLeft={<PlusIcon className="w-4 h-4" />}
                onClick={() => setIsShowingAddKeyNotice((show) => !show)}
              >
                Add Credential
              </Button>
            </div>
            {isShowingAddKeyNotice && (
              <>
                <div className="relative mt-4">
                  <svg
                    viewBox="0 0 24 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-3 w-6 text-gray-200 rotate-180 m-px absolute -translate-y-full right-[46px]"
                  >
                    <rect width="24" height="1.5" fill="white" />
                    <path
                      d="M 24 1 C 18 1 18 10 12 10 C 6 10 6 1 0 1"
                      stroke="rgba(229, 229, 229)"
                      fill="white"
                      strokeWidth="1"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>
                <div className="border py-4 pl-6 rounded flex flex-row items-stretch bg-white shadow-sm">
                  <div className="flex flex-1 flex-col justify-center h-48">
                    <h2 className="text-lg font-bold">Add new key</h2>
                    <p className="mt-2">
                      You can connect a new credential by{" "}
                      <Link to="/" className="link">
                        editing
                      </Link>{" "}
                      or{" "}
                      <Link to="/syncs/new" className="link">
                        creating a new sync
                      </Link>{" "}
                      from the Syncs list.
                    </p>
                  </div>
                  <div>
                    <img
                      src="/static/img/add-key-banner.png"
                      className="h-48 rounded-lg"
                    />
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
        <div className="custom-container mt-12">
          <div className="flex flex-col gap-3">
            <CredentialsList />
            <Outlet />
          </div>
        </div>
      </main>
    </>
  );
}

const CredentialsList = () => {
  const [credentials, refreshCredentials] = useCredentials();
  const [resources] = useResources();

  if (!credentials || !resources) {
    return <PageLoadingSpinner />;
  }

  const mappedCredentialsToResource = credentials.map((credential) => {
    const resourcesForThisCredential = resources.filter(
      (res) => res.credential.id === credential.id
    );

    return {
      credential,
      resources: resourcesForThisCredential,
    };
  });

  return (
    <>
      {mappedCredentialsToResource.map((credentialItem) => (
        <CredentialCard
          key={credentialItem.credential.id}
          credential={credentialItem.credential}
          resources={credentialItem.resources}
          refreshCredentials={refreshCredentials}
        />
      ))}
    </>
  );
};
