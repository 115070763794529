import { XCircleIcon } from "@heroicons/react/solid";
import React from "react";
import Dialog from "./Dialog";
import { motion } from "framer-motion";

export default function Drawer({
  children,
  onClose,
  title,
}: React.PropsWithChildren<{
  title?: string;
  onClose: () => void;
}>) {
  return (
    <Dialog onCloseRequest={onClose}>
      <motion.div
        initial={{
          opacity: 0,
          translateX: 48,
        }}
        animate={{
          opacity: 1,
          translateX: 0,
        }}
        className="fixed flex flex-col right-0 top-0 bottom-0 bg-white shadow-xl max-w-xl w-full transform text-left"
      >
        <Dialog.Content className="flex-1 flex flex-col">
          <div
            className={`h-20 ${
              title ? "border-b" : ""
            } flex flex-row items-center p-4 px-8`}
          >
            {title ? (
              <Dialog.Title className="text-2xl font-bold leading-6 text-gray-900 flex-1">
                {title}
              </Dialog.Title>
            ) : (
              <div className="flex-1" />
            )}
            <button onClick={onClose}>
              <XCircleIcon className="h-5 w-5" />
            </button>
          </div>
          {/*Drawer content*/}
          <div className="flex-1">{children}</div>
        </Dialog.Content>
      </motion.div>
    </Dialog>
  );
}

// for accessibility reasons, export the internal DialogTitle
Drawer.Title = Dialog.Title;
