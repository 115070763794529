import React from "react";
import { Link, Outlet, useParams } from "react-router-dom";
import classNames from "classnames";
import Head from "next/head";

import { NotFoundError } from "lib/api";
import { useResourceBySlug } from "lib/api/hooks";
import { SyncPageHeader } from "components/composite/SyncPage/SyncPageHeader";
import { FullPageSyncNotFound } from "components/composite/NotFound";

export const SyncPage = () => {
  const { permaslug } = useParams();
  const [, , error] = useResourceBySlug(permaslug as string);

  if (error instanceof NotFoundError) {
    return (
      <div className="flex flex-col flex-1 w-full">
        <FullPageSyncHeader permaslug={permaslug} />
        <FullPageSyncNotFound />
      </div>
    );
  }

  return (
    <div className="flex flex-col flex-1 w-full">
      <FullPageSyncHeader permaslug={permaslug} />
      <main className="flex flex-1 w-full">
        <Outlet />
      </main>
    </div>
  );
};

interface FullPageSyncHeaderProps {
  permaslug: string;
}

const FullPageSyncHeader = ({ permaslug }: FullPageSyncHeaderProps) => {
  const active = "font-bold border-b border-gray-600";
  return (
    <>
      <Head>
        <title>Sync settings - Sequin</title>
      </Head>
      <SyncPageHeader permaslug={permaslug} />
      <nav className="bg-white border-b pb-4 border-gray-200">
        <div className="custom-container">
          <Link
            to={`/syncs/${permaslug}/connection-instructions`}
            className={classNames("pb-4 mr-12", {
              [active]: location.pathname.includes("/connection-instructions"),
            })}
          >
            Connection instructions
          </Link>
          <Link
            to={`/syncs/${permaslug}/collections`}
            className={classNames("pb-4 mr-12", {
              [active]: location.pathname.includes("/collections"),
            })}
          >
            Collections
          </Link>
          <Link
            to={`/syncs/${permaslug}/backfills`}
            className={classNames("pb-4 mr-12", {
              [active]: location.pathname.includes("/backfills"),
            })}
          >
            Backfills
          </Link>
          <Link
            to={`/syncs/${permaslug}/mapping`}
            className={classNames("pb-4 mr-12", {
              [active]: location.pathname.includes("/mapping"),
            })}
          >
            Mapping
          </Link>
          <Link
            to={`/syncs/${permaslug}/settings`}
            className={classNames("pb-4 mr-12", {
              [active]: location.pathname.includes("/settings"),
            })}
          >
            Settings
          </Link>
          <Link
            to={`/syncs/${permaslug}/schema`}
            className={classNames("pb-4", {
              [active]: location.pathname.includes("/schema"),
            })}
          >
            Schema
          </Link>
        </div>
      </nav>
    </>
  );
};
