import React from "react";
import { toast } from "react-toastify";

import { Resource } from "lib/platforms";
import { updateResource } from "lib/api";
import { useWorkable } from "lib/utils";
import SvgSpinner from "components/svg/Spinner";

interface SyncToggleProps {
  resource: Resource;
  refreshResource: (isActive: boolean) => void;
}

export function SyncToggle({ resource, refreshResource }: SyncToggleProps) {
  const [updatingSyncActive, applyUpdatingSyncActive] = useWorkable();

  const handleSyncSwitch = async (isSyncActive: boolean) => {
    const updateResponseData = await updateResource(resource.id, {
      active: isSyncActive,
    });

    if (updateResponseData.isErr()) {
      toast.error(
        `Error enabling sync. ${updateResponseData.error.error.summary}`,
        { toastId: "sync-toggle-error-enabling" }
      );
    } else {
      await refreshResource(isSyncActive);

      toast({
        content: `Sync ${isSyncActive ? "enabled" : "disabled"} for base`,
        duration: 5,
        type: "success",
      });
    }
  };

  const toggleSyncSwitch = () => {
    return applyUpdatingSyncActive(
      async () => await handleSyncSwitch(!resource.active)
    );
  };

  return (
    <span
      className={`${
        resource.active ? "bg-black" : "bg-gray-300"
      } disabled:opacity-50 relative inline-flex items-center h-5 rounded-full
        w-8 transition-colors focus:outline-none focus:ring-offset-2 focus:ring-offset-white
      dark:ring-offset-gray-700 focus:ring-2 focus:ring-success cursor-pointer`}
      onClick={(event) => {
        event.preventDefault();
        toggleSyncSwitch();
      }}
    >
      <span
        className={`${
          resource.active ? "translate-x-[14px]" : "translate-x-0.5"
        } inline-flex items-center justify-center w-4 h-4 transform bg-white rounded-full transition-transform`}
      >
        {updatingSyncActive && <SvgSpinner className="w-3 h-3 animate-spin" />}
      </span>
    </span>
  );
}
