import React, { useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import groupBy from "lodash/groupBy";
import { ArrowRightIcon, XCircleIcon } from "@heroicons/react/solid";

import {
  useResourceCollectionStatuses,
  useResourceBackfills,
  useResourceBySlug,
  useResourceTablesSyncing,
} from "lib/api/hooks";
import { ResourceBackfill, ResourceCompletedBackfill } from "lib/api/types";
import { getPlatformForKind, Resource } from "lib/platforms";
import {
  CompletedBackfills,
  InProgressBackfills,
  NewlyCreatedBackfills,
} from "components/resources/jobs/FullPageJobStatus";
import { PageLoadingSpinner } from "components/core/PageLoadingSpinner";
import Modal from "components/core/Modal";
import { Button } from "lib/lucidez/components/Button";

interface LocationState {
  state?: {
    isBackfilling?: boolean;
    shouldShowOnboardingModal?: boolean;
  };
}

export const SyncPageBackfills = () => {
  const { permaslug } = useParams();
  const { state }: LocationState = useLocation();
  const [shouldShowOnboardingModal, setShouldShowOnboardingModal] =
    useState<boolean>(state?.shouldShowOnboardingModal ?? false);

  const [resource] = useResourceBySlug(permaslug as string, {
    waitUntilLoaded: true,
  });

  const [backfills] = useResourceBackfills(resource?.id, {
    refreshInterval: 5000,
    revalidateOnMount: true,
    initialData: null,
  });

  const [tables] = useResourceTablesSyncing(resource?.id);

  const [collectionStatuses] = useResourceCollectionStatuses(resource?.id, {
    refreshInterval: 5000,
    revalidateOnMount: true,
    initialData: null,
  });

  // Resource is required for showing information on a newly-created sync
  // If we don't have it, the whole thing is a no-go.
  if (!resource) {
    return <PageLoadingSpinner />;
  }

  if (!backfills) {
    // The page is loading
    if (!state?.isBackfilling) {
      return <PageLoadingSpinner />;
    }

    if (state?.isBackfilling) {
      return (
        <section className="pt-6 pb-12 bg-gray-100 flex-1">
          <div className="custom-container">
            <NewlyCreatedBackfills resource={resource} />
          </div>
        </section>
      );
    }
  }

  const handleCloseModal = () => {
    setShouldShowOnboardingModal(false);
  };

  const groupedJobs = groupBy(collectionStatuses, "jobKind");
  const backfillJobsCollectionStatuses = groupedJobs.backfill ?? [];

  const inProgressBackfills = backfills.filter(
    (backfill) => backfill.status !== "completed"
  ) as ResourceBackfill[];

  const completedBackfills = backfills.filter(
    (backfill) => backfill.status === "completed"
  ) as ResourceCompletedBackfill[];

  return (
    <section className="pt-6 pb-12 bg-gray-100 flex-1">
      <div className="custom-container">
        {inProgressBackfills.length > 0 && (
          <>
            <h3 className="text-sm text-black mb-4">
              In Progress{" "}
              <span className="text-gray-400">
                ({inProgressBackfills.length})
              </span>
            </h3>
            <InProgressBackfills
              backfills={inProgressBackfills}
              collectionStatuses={backfillJobsCollectionStatuses}
              tables={tables}
            />
          </>
        )}
        {completedBackfills.length > 0 && (
          <>
            <h3 className="text-sm text-black mb-4 mt-6">
              Completed{" "}
              <span className="text-gray-400">
                ({completedBackfills.length})
              </span>
            </h3>
            <CompletedBackfills
              backfills={completedBackfills}
              tables={tables}
            />
          </>
        )}
      </div>
      {shouldShowOnboardingModal && (
        <OnboardingModal onClose={handleCloseModal} resource={resource} />
      )}
    </section>
  );
};

const OnboardingModal = ({
  onClose,
  resource,
}: {
  onClose: () => void;
  resource: Resource;
}) => {
  const platform = getPlatformForKind(resource.kind);

  return (
    <Modal
      onClose={onClose}
      containerClassName="w-3/4 md:min-w-[896px] sm:min-w-[95%] custom-container py-32 px-16 max-w-4xl"
    >
      <div>
        <div className="p-4 flex justify-end">
          <button onClick={onClose}>
            <XCircleIcon className="h-5 w-5" aria-hidden="true" />
          </button>
        </div>
        <div className="flex flex-row justify-center text-left">
          <div className="p-8 pt-0">
            <h3 className="text-2xl font-bold w-full mb-6">
              It's time to build
            </h3>
            <p className="mb-6">
              Sequin makes it a breeze to integrate your API with Postgres.
            </p>
            <p className="mb-12">
              We're currently backfilling your data from {platform.displayName}{" "}
              to your Postgres database.
            </p>
            <div className="flex mb-4">
              <Link to={`/syncs/${resource.permaslug}/connection-instructions`}>
                <Button
                  id="confirm-delete-btn"
                  variant="primary"
                  className="w-full font-normal text-xs justify-center"
                  iconRight={<ArrowRightIcon className="h-4 w-4 ml-3" />}
                >
                  Set up your dev environment
                </Button>
              </Link>
            </div>
          </div>
          <div className="flex justify-end">
            <img
              className="rounded-tl-lg rounded-br-lg border-l border-t border-gray-200"
              src="/static/img/sequin_sync_screenshot.png"
              alt="Sequin syncs table"
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};
