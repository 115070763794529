import { ExclamationCircleIcon } from "@heroicons/react/outline";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { registerPendingUser } from "../../lib/api";
import { LoginStatus } from "../../lib/api/types";
import { useLoginStatus } from "../../lib/api/utilHooks";
import SvgSpinner from "../svg/Spinner";

export default function JoinOrg() {
  const { search } = useLocation();

  const navigate = useNavigate();
  const [query] = useSearchParams();

  const loginStatus = useLoginStatus();

  const [error, setError] = useState<string | null>(null);

  const executeInviteProcedure = async () => {
    const token = query.get("token");

    if (loginStatus === LoginStatus.LoggedOut) {
      navigate(`/signup${search}`, { replace: true });
    } else if (loginStatus === LoginStatus.LoggedIn) {
      const response = await registerPendingUser({ token });

      if (response.isOk()) {
        navigate("/"); // The dashboard will check if the user needs onboarding
      } else {
        if (response.error.error.summary.includes("token expired")) {
          setError(
            "Your invite token expired, please reach out to your org to resend the invite."
          );
        } else if (response.error.error.slug === "org_not_dangling") {
          setError(
            "You need to delete all your syncs before joining another organization."
          );
        } else {
          switch (response.error.error.slug) {
            case "has_dangling_users":
              setError(
                "You need to remove other users before joining another organization."
              );
              break;

            case "has_dangling_resources":
              setError(
                "You need to delete all your syncs before joining another organization."
              );
              break;

            case "has_dangling_databases":
              setError(
                "You need to delete all your databases before joining another organization."
              );
              break;

            case "has_dangling_subscription":
              setError(
                "You need to cancel your active subscription before joining another organization."
              );
              break;

            default:
              setError("Unknown error, please try again...");
              break;
          }
        }
      }
    }
  };

  useEffect(() => {
    executeInviteProcedure();
  }, [loginStatus]);

  return (
    <main className="custom-container my-32 max-w-md p-8 rounded-md bg-white dark:bg-gray-900 shadow-lg flex flex-col items-center justify-center">
      {!error ? (
        <>
          <SvgSpinner className="h-8 w-8 animate-spin" />
          <h1 className="font-bold text-xl mt-4">Moving you...</h1>
        </>
      ) : (
        <>
          <ExclamationCircleIcon className="h-8 w-8" />
          <h1 className="text-xl font-bold mt-4">Failed to accept invite</h1>
          <p className="mt-4 p-4 border rounded w-full text-xs">{error}</p>
        </>
      )}
    </main>
  );
}
