import React, { useEffect } from "react";
import Head from "next/head";
import { getLogoutUrl } from "../../lib/api";

export default function Logout() {
  useEffect(() => {
    window.location.href = getLogoutUrl();
  });

  return (
    <>
      <Head>
        <title>Logging you out - Sequin</title>
      </Head>
      <main className="custom-container my-32 max-w-md p-8 rounded-md bg-white dark:bg-gray-900 shadow-lg">
        <h1 className="font-bold text-xl">Logging out...</h1>
      </main>
    </>
  );
}
