import * as React from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}

function SvgComponent({
  title,
  titleId,
  ...props
}: React.SVGProps<SVGSVGElement> & SVGRProps) {
  return (
    <svg viewBox="0 0 24 24" fill="none" aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.034 16.92a2.845 2.845 0 0 1-2.862-2.856c0-1.638 1.104-2.982 2.886-2.982s2.832 1.44 2.832 2.952c.006 1.644-1.11 2.886-2.856 2.886Zm-4.572-5.418-.546-1.794c-.324-.942-.426-1.488-1.344-2.46-.318-.336-1.512-1.068-1.362-.906 1.512 1.632 2.286 3.612 2.586 4.164-.276-.33-.594-.63-.96-1.002-.312-.318-.636-.6-.948-.792-.204-.126-.318-.198-.528-.3-.93-.486-1.836-.78-2.184-.864-.426-.108-.84-.132-.84-.132s1.35 1.14 1.932 1.866c.576.726 1.17 1.374 1.824 1.644.654.264.882.342 1.392.42.438.054.978.156.978.156Zm4.692-1.59c-1.428 0-2.196.492-2.73 1.026l-.144.156V6.306h-1.236v7.836c0 2.412 1.854 3.912 3.99 3.912 2.346 0 4.122-1.806 4.122-4.128 0-2.16-1.854-4.014-4.002-4.014ZM24 12c0 6.63-5.37 12-12 12S0 18.63 0 12 5.37 0 12 0s12 5.37 12 12Z"
        fill="currentColor"
      />
    </svg>
  );
}

const SvgBambooHRFilled = React.memo(SvgComponent);
export default SvgBambooHRFilled;
