import React from "react";
import { format, formatDistanceToNow } from "date-fns";
import { formatInTimeZone } from "date-fns-tz";

import HoverPopover from "components/core/HoverPopover";

interface Props {
  iso8601: string;
  mode?: "relative" | "absolute" | "absolute-full-date";
}

export const FriendlyDateTime = ({ iso8601, mode = "relative" }: Props) => {
  const date = new Date(iso8601);

  const dateStr =
    mode === "relative"
      ? formatDistanceToNow(date)
      : mode == "absolute-full-date"
      ? format(date, "MM-dd-yyyy HH:mm:ss")
      : format(date, "PP");

  return (
    <HoverPopover
      containerProps={{
        placement: "top",
      }}
      content={<PopoverContent date={date} />}
    >
      <span className="transition-all link text-current cursor-pointer">
        {dateStr}
      </span>
    </HoverPopover>
  );
};

interface PopoverContentProps {
  date: Date;
}

const PopoverContent = ({ date }: PopoverContentProps) => {
  return (
    <div className="bg-white rounded w-80 text-left">
      <h3 className="text-sm border-b mb-3 pb-1">Timezone conversions</h3>
      <ul>
        <li className="flex justify-between mb-1.5">
          <div>
            <strong>
              {formatInTimeZone(
                date,
                Intl.DateTimeFormat().resolvedOptions().timeZone,
                "z"
              )}
            </strong>
            <span className="italic ml-1">Your device</span>
          </div>
          <div>
            <EvenlySpacedFormattedDate
              date={date}
              timeZone={Intl.DateTimeFormat().resolvedOptions().timeZone}
            />
          </div>
        </li>
        <li className="flex justify-between">
          <div>
            <strong>{formatInTimeZone(date, "UTC", "z")}</strong>
          </div>
          <div>
            <EvenlySpacedFormattedDate date={date} timeZone="UTC" />
          </div>
        </li>
      </ul>
    </div>
  );
};

interface EvenlySpacedFormattedDateProps {
  date: Date;
  timeZone: string;
}

/* The purpose of this component is to make sure date strings with different sizes
 * take up the same amount of space for alignment purposes.
 * The misalignment of times illustrated below is the problem this function solves:

 * Fri, May 5, 2023 2:03:33 pm
 * Fri, May 5, 2023 12:44:55 pm

 * It solves this alignment problem with minimum widths in pixels. Rems, the
 * default unit for tailinwd, are too coarse to solve the problem effectively.
 */

const EvenlySpacedFormattedDate = ({
  date,
  timeZone,
}: EvenlySpacedFormattedDateProps) => {
  const dayFormat = "EEE,";
  const dateFormat = "MMM d,";
  const yearFormat = "yyyy";
  const timeFormat = "h:mm:ss aaa";
  return (
    <>
      <span>{formatInTimeZone(date, timeZone, dayFormat)}</span>
      <span className="inline-block ml-0.5 min-w-[40px] text-right">
        {formatInTimeZone(date, timeZone, dateFormat)}
      </span>
      <span className="inline-block ml-0.5 min-w-[34px]">
        {formatInTimeZone(date, timeZone, yearFormat)}
      </span>
      <span className="inline-block min-w-[72px] text-right">
        {formatInTimeZone(date, timeZone, timeFormat)}
      </span>
    </>
  );
};
