import React from "react";
import { CommonChrome, CommonLayout } from "components/Layouts";

export default function ErrorPage({ msg }: { msg?: string }) {
  return (
    <CommonChrome>
      <CommonLayout>
        <main className="custom-container my-32">
          <h1 className="font-bold text-xl">Uh oh</h1>
          <div className="mt-8">
            Something went wrong. We've probably been notified, but feel free to
            let us know.
            <p className="font-mono mt-4">Msg: {msg}</p>
          </div>
        </main>
      </CommonLayout>
    </CommonChrome>
  );
}
