import React, { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { mutate } from "swr";
import { toast } from "react-toastify";

import { Resource, getPlatformForKind } from "lib/platforms";
import { updateResource } from "lib/api";
import { useResourceBySlug } from "lib/api/hooks";
import { Button } from "lib/lucidez/components/Button";
import { ResourceProvider } from "components/platforms/common/ResourceForm";
import { SyncAllQuirkProvider } from "components/platforms/common/forms/SelectTablesStep";
import { FullPageSelectTablesStep } from "components/platforms/common/forms/SelectTablesStep";
import { PageLoadingSpinner } from "components/core/PageLoadingSpinner";
import { ConfirmPendingChanges } from "lib/utils/ConfirmPendingChanges";
import Modal from "components/core/Modal";
import { XCircleIcon } from "@heroicons/react/solid";
import SelectAssociationsTables from "components/platforms/hubspot/SelectAssociationsTables";

export const SyncPageMapping = () => {
  const { permaslug } = useParams();
  const [resource] = useResourceBySlug(permaslug as string, {
    waitUntilLoaded: true,
  });
  if (!resource) {
    return <PageLoadingSpinner />;
  }

  return <MappingForm resource={resource} />;
};

interface MappingFormProps {
  resource: Resource;
}

const MappingForm = ({ resource }: MappingFormProps) => {
  const platform = getPlatformForKind(resource.kind);

  const formMethods = useForm<any>({
    defaultValues: platform.getInitialUpdateState(resource),
    mode: "all",
  });

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [associationsTouched, setAssociationsTouched] = useState(false);
  const [fatalErrorMessage, setFatalErrorMessage] = useState<null | string>(
    null
  );

  const isValid = formMethods.formState.isValid;
  const hasPendingChanges =
    formMethods.formState.isDirty || associationsTouched;
  const hasErrors = Object.keys(formMethods.formState.errors).length > 0;

  const isSaveButtonDisabled =
    !hasPendingChanges || isSubmitting || hasErrors || !isValid;

  const handleCloseErrorModal = () => {
    setFatalErrorMessage(null);
  };

  const onSubmit = async (data) => {
    setIsSubmitting(true);

    const forApi = platform.prepareFieldsForUpdate(data);
    const response = await updateResource(resource.id, forApi);

    if (response.isOk()) {
      mutate("/api/resources");

      formMethods.reset({}, { keepValues: true });
      toast.success("Your changes have been saved.", {
        toastId: "sync-mapping-changes-saved",
      });
    } else if (JSON.stringify(response).includes("is banned")) {
      setFatalErrorMessage(
        "This Airtable account has been banned from accessing Sequin. If you think this was an error, please contact us."
      );
    } else if (response.error.error?.summary) {
      setFatalErrorMessage(response.error.error.summary);
    } else {
      // handle server errors not related to validation
      setFatalErrorMessage("An unknown error has occurred.");
    }

    setIsSubmitting(false);
  };

  return (
    <section className="py-12 flex-1">
      <div className="custom-container">
        <ResourceProvider resource={resource}>
          <SyncAllQuirkProvider
            allowSyncAll={resource.schema.syncAllTables === true}
          >
            <FormProvider {...formMethods}>
              <form
                className="relative h-full flex flex-row w-full justify-between"
                onSubmit={(event) => {
                  event.preventDefault();
                }}
              >
                <div className="w-2/3">
                  <h2 className="text-2xl font-bold mb-6">
                    Select collections
                  </h2>
                  <FullPageSelectTablesStep
                    isCreate={false}
                    platform={platform}
                  >
                    {(tables) => (
                      <>
                        {platform.kind === "hubspot" && (
                          <div className="px-4 pb-4 border-t">
                            <SelectAssociationsTables
                              tables={tables}
                              isCreate={false}
                              onAddAssociation={() =>
                                setAssociationsTouched(true)
                              }
                            />
                          </div>
                        )}
                      </>
                    )}
                  </FullPageSelectTablesStep>
                </div>
                <div className="border-l mt-12 ml-8 pt-2 pl-8 w-1/4">
                  <Button
                    variant="primary"
                    onClick={() => {
                      formMethods.handleSubmit(onSubmit)();
                    }}
                    disabled={isSaveButtonDisabled}
                    className="w-full justify-center"
                    size="lg"
                  >
                    Save changes
                  </Button>
                  <Button
                    onClick={() => {
                      formMethods.reset();
                    }}
                    className="mt-3 w-full justify-center"
                    size="lg"
                  >
                    Cancel
                  </Button>
                </div>
              </form>
            </FormProvider>
          </SyncAllQuirkProvider>
        </ResourceProvider>
        <ConfirmPendingChanges hasPendingChanges={hasPendingChanges} />
        {fatalErrorMessage && (
          <ErrorModal
            onClose={handleCloseErrorModal}
            message={fatalErrorMessage}
          />
        )}
      </div>
    </section>
  );
};

const ErrorModal = ({
  onClose,
  message,
}: {
  onClose: () => void;
  message: string;
}) => (
  <Modal
    onClose={onClose}
    containerClassName="w-3/4 custom-container py-32 lg:px-16 max-w-2xl"
  >
    <div className="p-8">
      <div className="flex justify-end">
        <button onClick={onClose}>
          <XCircleIcon className="h-5 w-5" aria-hidden="true" />
        </button>
      </div>
      <div className="flex flex-col justify-center text-center">
        <h3 className="text-xl font-bold w-full mb-6">
          Some of your changes could not be saved
        </h3>
        <p className="mb-6">{message}</p>
        <div className="flex justify-center text-center mb-4">
          <Button
            id="confirm-delete-btn"
            variant="primary"
            onClick={onClose}
            className="w-2/5 justify-center bg-gray-900"
          >
            Continue
          </Button>
        </div>
      </div>
    </div>
  </Modal>
);
