import * as React from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}

function SvgComponent({
  title,
  titleId,
  ...props
}: React.SVGProps<SVGSVGElement> & SVGRProps) {
  return (
    <svg viewBox="0 0 175 203" fill="none" aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        d="M126.596 24.168v151.915c0 17.012 11.724 26.47 24.168 26.47 11.509 0 24.168-8.056 24.168-26.47V25.319C174.932 9.746 163.424 0 150.764 0c-12.659 0-24.168 10.753-24.168 24.168ZM63.298 101.277v74.806c0 17.012 11.724 26.47 24.168 26.47 11.509 0 24.168-8.056 24.168-26.47v-73.656c0-15.572-11.508-25.319-24.168-25.319s-24.168 10.754-24.168 24.169ZM48.337 178.385c0 13.343-10.826 24.168-24.169 24.168S0 191.728 0 178.385s10.825-24.168 24.168-24.168 24.169 10.825 24.169 24.168Z"
        fill="currentColor"
      />
    </svg>
  );
}

const SvgGoogleAnalyticsFilled = React.memo(SvgComponent);
export default SvgGoogleAnalyticsFilled;
