import * as React from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}

function SvgComponent({
  title,
  titleId,
  ...props
}: React.SVGProps<SVGSVGElement> & SVGRProps) {
  return (
    <svg viewBox="0 0 23 22" fill="none" aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        d="M18.47 0H3.84A3.672 3.672 0 0 0 .166 3.672v14.631a3.672 3.672 0 0 0 3.672 3.673h14.632a3.672 3.672 0 0 0 3.672-3.672V3.671A3.672 3.672 0 0 0 18.47 0Zm-.322 16.82c0 .642-.52 1.16-1.16 1.16H5.322c-.641 0-1.16-.518-1.16-1.16V5.156c0-.641.519-1.16 1.16-1.16h11.666c.64 0 1.16.519 1.16 1.16V16.82Zm-9.324-2.85a.665.665 0 0 1-.665-.667V8.646c0-.37.296-.67.665-.67h4.664c.367 0 .665.299.665.67V13.3a.666.666 0 0 1-.665.668H8.824v.002Z"
        fill="currentColor"
      />
    </svg>
  );
}

const SvgSquareUpFilled = React.memo(SvgComponent);
export default SvgSquareUpFilled;
