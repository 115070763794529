import * as React from "react";
interface SVGRProps {
  title?: string;
  titleId?: string;
}

function SvgComponent({
  title,
  titleId,
  ...props
}: React.SVGProps<SVGSVGElement> & SVGRProps) {
  return (
    <svg viewBox="0 0 40 40" fill="none" aria-labelledby={titleId} {...props}>
      {title ? <title id={titleId}>{title}</title> : null}
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 20c0 9.9 7.2 18.1 16.7 19.8l.118-.097-.018-.003V25.6h-5V20h5v-4.4c0-5 3.2-7.8 7.8-7.8 1.4 0 3 .2 4.4.4v5.1h-2.6c-2.4 0-3 1.2-3 2.8V20h5.3l-.9 5.6h-4.4v14.1l-.183.032.083.068C32.8 38.1 40 29.9 40 20 40 9 31 0 20 0S0 9 0 20Z"
        fill="currentColor"
      />
    </svg>
  );
}

const SvgFacebookFilled = React.memo(SvgComponent);
export default SvgFacebookFilled;
