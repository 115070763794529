import { GlobalState } from "little-state-machine";

import { ResourceKind } from "lib/platforms";

export interface ApplicationState extends GlobalState {
  onboardState: OnboardState;
}

export interface OnboardState {
  currentFormData: any;
  currentStep: number;
  jobId: number | null;
  platformKind: ResourceKind | null;
}

export const globalState: ApplicationState = {
  onboardState: {
    currentFormData: {},
    currentStep: 0,
    jobId: null,
    platformKind: null,
  },
};

export const updateCurrentStep = (
  globalState: ApplicationState,
  currentStep: number
): GlobalState => {
  return {
    ...globalState,
    onboardState: {
      ...globalState.onboardState,
      currentStep,
    },
  };
};

export const updateFormData = (
  globalState: ApplicationState,
  payload
): GlobalState => {
  return {
    ...globalState,
    onboardState: {
      ...globalState.onboardState,
      currentFormData: payload,
    },
  };
};

export const updateJobId = (
  globalState: ApplicationState,
  jobId: string
): GlobalState => {
  return {
    ...globalState,
    onboardState: {
      ...globalState.onboardState,
      jobId,
    },
  };
};

export const updatePlatformKind = (
  globalState: ApplicationState,
  platformKind: ResourceKind | null
): ApplicationState => {
  return {
    ...globalState,
    onboardState: {
      ...globalState.onboardState,
      platformKind,
    },
  };
};
